import React from 'react';
import { Box } from '@mui/material';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import Tabs from '../../../../core-components/theme/components/Tabs/Tabs';
import CustomUserGroupTreeItem from '../../../userGroup/component/CustomUserGroupTree';
import { getUserItemId, getUserItemlabel } from '../../helpers/customUserTreeHelpers';

interface PermissionListTabsProps {
  filterQuery: string;
  filterItems: any[];
  value: number;
  handleChange: (newValue: number) => void;
}

const PermissionListTabs: React.FC<PermissionListTabsProps> = ({
  filterQuery,
  filterItems,
  value,
  handleChange,
}) => {

  const [assets, system] = filterItems;
  const tabs = [
    {
      label: 'Asset Permissions',
      Component: (
        <CustomTreeComponent
          CustomTreeItem={CustomUserGroupTreeItem}
          show={true}
          heading="Name"
          filter={filterQuery}
          text={`Search result for ${filterQuery}`}
          items={assets}
          getItemId={getUserItemId}
          getItemlabel={getUserItemlabel}
        />
      ),
    },
    {
      label: 'Administrative Permissions',
      Component: (
        <CustomTreeComponent
          show={true}
          heading="Name"
          CustomTreeItem={CustomUserGroupTreeItem}
          filter={filterQuery}
          text={`Search result for ${filterQuery}`}
          items={system}
          getItemId={getUserItemId}
          getItemlabel={getUserItemlabel}
        />
      ),
    },
  ];

  return (
    <Box>
      <Tabs value={value} handleChange={handleChange} tabs={tabs} aria-label="permission-tabs" />
    </Box>
  );
};

export default PermissionListTabs;
