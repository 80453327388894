const styles = {
  dialogTitle: {
    fontSize: '30px',
    fontWeight: 400,
  },
  addUserButton: {
    backgroundColor: 'white',
    color: 'grey',
    fontSize: '20px',
    boxShadow: 'none',
    border: '1px solid lightgrey',
    '&:hover': {
      backgroundColor: 'white',
      color: 'grey',
      borderColor: 'black',
      boxShadow: 'none',
    },
  },
  tableHeadCell: { fontWeight: '550' },
  cancelButton: {
    width: '8.75rem',
    border: `1px solid #A0A0A0`,
    borderRadius: '4px',
    padding: '10px 40px',
    backgroundColor: 'white',
    color: '#A0A0A0',
    boxShadow: 'none',
    '&:hover': { background: 'none', boxShadow: 'none' },
  },
  saveButton: {
    width: '8.75rem',
    padding: '10px 50px',
    borderRadius: '4px',
    backgroundColor: '#2F6A9E',
    color: 'white',
    boxShadow: 'none',
    '&:hover': { boxShadow: 'none' },
  },
  formControl: { width: '70%', position: 'relative' },
  formControlPermission: { width: '30%' },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 10,
    maxHeight: '20vh',
    width: '100%',
    overflowY: 'auto',
    backgroundColor: 'white',
    border: '1px solid lightgrey',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  dropdownItem: {
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
};

export default styles;
