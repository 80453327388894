import { useState } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  useMediaQuery,
  Theme,
  Stack,
} from '@mui/material';
import { toast } from 'react-toastify';
import DialogBox from '../core-components/theme/components/DialogBox/DialogBox';
import { createViewAsUserToken, getViewAsUserList } from '../services/newUsers';
import { useQuery, useMutation } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/UserStore';
import Button from '../core-components/theme/components/Button/Button';
import { Neutrals, Primary } from '../core-components/theme/color-palette/colors';

interface IViewAsProps {
  open: boolean;
  handleClose: Function;
}
interface IUser {
  _id: string;
  name: string;
}
function ViewAs({ open, handleClose }: IViewAsProps) {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { setToken, setSideBar, setUserId, setLandingPage } = useUserStore();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const {
    data: users,
    isLoading,
    isError,
    error,
  } = useQuery<IUser[], Error>({
    queryKey: ['viewAsUserList'],
    queryFn: getViewAsUserList,
    staleTime: 60000, // Cache for 1 minute
    retry: 2, // Retry fetching twice on error
    // onError: (err: any) => {
    //   console.error('Error fetching user list:', err);
    // },
  });

  // Mutation for creating a View As User token
  const mutation = useMutation({
    mutationFn: (viewAsUserId: string) => createViewAsUserToken(viewAsUserId),
    onSuccess: data => {
      toast.success('View As User Token created successfully');
      handleClose(); // Close dialog on success
      console.log(data, 'data');

      const originalToken = localStorage.getItem('token');
      if (originalToken) {
        localStorage.setItem('originalToken', originalToken);
        setToken(originalToken);
      }
      localStorage.setItem('token', data.token);

      const originalSideBar = localStorage.getItem('sideBar');
      if (originalSideBar) {
        localStorage.setItem('originalSideBar', originalSideBar);
        setSideBar(JSON.parse(originalSideBar));
      }
      localStorage.setItem('sideBar', JSON.stringify(data?.layoutInfo?.sideBar));

      const originalUserId = localStorage.getItem('userId');
      if (originalUserId) {
        localStorage.setItem('originalUserId', originalUserId);
        setUserId(originalUserId);
      }
      localStorage.setItem('userId', selectedUserId!);

      const originalLandingPage = localStorage.getItem('landingPage');
      if (originalLandingPage) {
        localStorage.setItem('originalLandingPage', originalLandingPage);
        setLandingPage(data.layoutInfo.landingPage);
      }
      localStorage.setItem('landingPage', data?.layoutInfo?.landingPage);

      localStorage.setItem('isViewing', 'true');
      navigate(`${data?.layoutInfo?.landingPage}`);
    },

    onError: (err: any) => {
      toast.error(err?.message || 'Error creating View As User Token');
      console.error('Error creating View As User Token:', err);
    },
  });

  const handleSubmit = () => {
    if (selectedUserId) {
      mutation.mutate(selectedUserId); // Trigger mutation with selected userId
    } else {
      toast.error('Please select a user first.');
    }
  };


  return (
    <Box>
      <DialogBox open={open} title={`View As`} handleClose={handleClose}>
        {isLoading ? (
          <CircularProgress />
        ) : isError ? (
          <p>Error loading users: {error?.message}</p>
        ) : (
          <Autocomplete
            options={users || []}
            getOptionLabel={(user: IUser) => user.name}
            onChange={(event, newValue: IUser | null) => {
              setSelectedUserId(newValue ? newValue._id : null);
            }}
            renderInput={params => <TextField {...params} label="Select User" />}
          />
        )}

        <Stack direction="row" mt={4} columnGap={1} justifyContent="space-between">
          <Button
            variant="contained"
            sx={{
              border: `1px solid ${Neutrals[500]}`,
              borderRadius: '4px',
              padding: isMobile ? '10px 40px' : '10px 70px',
              backgroundColor: 'white',
              color: `${Neutrals[500]}`,
              flex: isMobile ? 1 : 0,
              minWidth: '50%',
              boxShadow: 'none',
              ':hover': { background: 'none', boxShadow: 'none' },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <Typography variant="body" color="Neutrals.500" lineHeight="24px">
              CANCEL
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            sx={{
              minWidth: '50%',

              padding: isMobile ? '10px 40px' : '10px 70px',
              flex: isMobile ? 1 : 0,
              borderRadius: '4px',
              backgroundColor: `${Primary[500]}`,
              boxShadow: 'none',
              ':hover': { boxShadow: 'none' },
            }}
          >
            <Typography variant="body" lineHeight="24px" color="white">
              SUBMIT
            </Typography>
          </Button>
        </Stack>
      </DialogBox>
    </Box>
  );
}

export default ViewAs;
