//@ts-nocheck
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LayoutComponent from '../components/LayoutComponent';
import axiosInstance from '../constants/axiosInstance';
import { Box, Button, Paper, TextField, Checkbox, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/lab/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { FixedTaskInterface, Equipment } from '../Interfaces/fixedTask';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';
import { FONT_NAME } from '../core-components/theme/theme';

export default function FixedTasksConfig() {
  const [loading, setLoading] = useState<boolean>(true);
  const [fixedTasks, setFixedTasks] = useState<FixedTaskInterface[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [escaltionOptions, setEscalationOptions] = useState<string[]>([]);
  const [assignRole, setAssignRole] = useState<string[]>([]);
  const navigate = useNavigate();
  const weeks = ['1', '2', '3', '4', '5'];
  const [equipments, setEquipments] = useState<Equipment[] | null>(null);

  const { id } = useParams();
  useEffect(() => {
    fetchRoles();
    fetchEscalations();

    axiosInstance()
      .get(`/tasks/getEquipments?id=${id}`)
      .then(data => {
        console.log('equiments:', data);
        setEquipments(data.data.equipments);
        console.log('equimts:', data.data.equipments);
      });
    axiosInstance()
      .get(`/tasks/fetchFixedTasks?id=${id}`)
      .then(({ data }) => {
        console.log(data);
        setFixedTasks(data.body);
        setLoading(false);
      });
    //1//2
  }, []);

  const fetchRoles = () => {
    axiosInstance()
      .get(`/users/roleforTasks?id=${id}`)
      .then(({ data }) => {
        console.timeLog(data);
        setAssignRole(data.workforce);
      });
  };
  const fetchEscalations = () => {
    axiosInstance()
      .get(`/tasks/getEscalationLevels?id=${id}`)
      .then(({ data }) => {
        setEscalationOptions(Object.keys(data?.body?.escalation));
        setLoading(false);
      });
  };
  const Days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const addNewTask = () => {
    let newTask = {
      toUser: 'Operator Group',
      recurringDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      weeks: ['1', '2', '3', '4', '5'],
      recurringTimes: '08:00:00',
      dueTimeFromPosting: '02:00:00',
      escalations: {
        'Level 1': {
          time: '10:00:00',
          group: 'Escalation Level 1',
        },
        'Level 2': {
          time: '12:00:00',
          group: 'Escalation Level 2',
        },
        'Level 3': {
          time: '14:00:00',
          group: 'Escalation Level 3',
        },
      },
      heading: {
        eng: 'Enter Heading',
        hindi: 'शीर्षक दर्ज करें',
      },
      description: {
        eng: 'Enter Description',
        hind: 'विवरण दर्ज करें',
      },
      subTasks: [
        {
          name: 'Enter Subtask',
          namehindi: 'सबटास्क दर्ज करें',
        },
      ],
    };

    let oldTasks = fixedTasks;
    oldTasks.push(newTask);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const addnewSubtask = (indexofTask: number) => {
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['subTasks'].push({
      name: 'Enter Subtask',
      namehindi: 'सबटास्क दर्ज करें',
    });
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleChecked = (indexofTask: number, value: boolean) => {
    console.log('value :', value);
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['checked'] = value;
    console.log(oldTasks);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleCheckedCritical = (indexofTask: number, value: boolean) => {
    console.log(value);
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['critical'] = value;
    console.log(oldTasks);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const delteSubTask = (indexofTask: number, indexofSubTask: number) => {
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['subTasks'].splice(indexofSubTask, 1);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const UpdateTask = (index: number, section: string, valuefor: string, value: any) => {
    let oldTasks = fixedTasks;
    oldTasks[index][section][valuefor] = value;
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleSubmit = () => {
    axiosInstance()
      .post(`/tasks/updateFixedTasks?id=${id}`, fixedTasks)
      .then(() => {
        navigate(`/alertTasksConfig/${id}`);
      });
  };

  const UpdateSubTask = (taskindex: number, subTaskIndex: number, key: string, value: string) => {
    let oldTasks = fixedTasks;
    oldTasks[taskindex]['subTasks'][subTaskIndex][key] = value;
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const UpdateEscaltionLevel = (taskindex: number, escalation: string, key: string, value: any) => {
    let oldTasks = fixedTasks;
    oldTasks[taskindex]['escalations'][escalation][key] = value;
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const updateDirectValue = (taskindex: number, key: string, value: any) => {
    let oldTasks = fixedTasks;
    oldTasks[taskindex][key] = value;
    console.log('oldTasks[taskindex][key]  : ', oldTasks[taskindex][key]);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  return (
    <LayoutComponent>
      <Box m={1} p={1}>
        <div
          style={{
            fontFamily: `${FONT_NAME}`,
            fontSize: 30,
            alignItems: 'center',
            verticalAlign: 'middle',
          }}
        >
          Fixed Tasks Configuration
          <span style={{ float: 'right' }}>
            <Button onClick={addNewTask} style={{ background: '#519BD0' }}>
              <AddIcon />
              Add New Fixed Task
            </Button>
          </span>
        </div>
      </Box>

      {loading ? null : (
        <>
          {fixedTasks.map((task: FixedTaskInterface, indexTask: number) => {
            return (
              <Box sx={{ paddingTop: '10px' }}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                      <Checkbox
                        checked={task.checked}
                        onChange={e => {
                          handleChecked(indexTask, e.target.checked);
                        }}
                      />
                      {task.heading.eng}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box m={1} style={{ width: '100%' }}>
                      <Paper>
                        <Box m={1} p={1} style={{ display: 'flex' }}>
                          <div style={{ marginLeft: 'auto' }}>
                            <Checkbox
                              checked={task.critical != null ? task.critical : false}
                              onChange={e => {
                                handleCheckedCritical(indexTask, e.target.checked);
                              }}
                            />
                            critical
                          </div>
                        </Box>
                        <Box m={1} p={1}>
                          <Stack
                            direction="column"
                            gap={2}
                            style={{ font: `${FONT_NAME}`, fontSize: 20 }}
                          >
                            <div>Heading</div>
                            <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                              <TextField
                                fullWidth
                                required
                                value={task.heading.eng}
                                label="Heading English"
                                onChange={e => {
                                  UpdateTask(indexTask, 'heading', 'eng', e.target.value);
                                }}
                              />
                            </div>

                            <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                              <TextField
                                fullWidth
                                required
                                value={task.heading.hindi}
                                label="Heading Hindi"
                                onChange={e => {
                                  UpdateTask(indexTask, 'heading', 'hindi', e.target.value);
                                }}
                              />
                            </div>
                          </Stack>
                        </Box>
                        <Box m={1} p={1}>
                          {task.equipments
                            ? console.log(
                                'task.equipments',
                                task.equipments,
                                'equipments',
                                equipments
                              )
                            : console.log('none')}
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Equipment</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={task.equipments ? task.equipments : {}}
                              label="Equipment"
                              renderValue={(value: Equipment) =>
                                value.nickName != '' ? value.nickName : value.id
                              }
                              onChange={e =>
                                updateDirectValue(indexTask, 'equipments', e.target.value)
                              }
                            >
                              {equipments !== null &&
                                equipments.map(equipment => {
                                  return (
                                    <MenuItem value={equipment}>
                                      {equipment.nickName != '' ? equipment.nickName : equipment.id}
                                    </MenuItem>
                                  );
                                  // return ( <MenuItem value={equipment}>{equipment.id}</MenuItem>)
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box m={1} p={1}>
                          <Stack
                            direction="column"
                            gap={2}
                            style={{ font: `${FONT_NAME}`, fontSize: 20 }}
                          >
                            <div>Description</div>
                            <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                              <TextField
                                fullWidth
                                required
                                value={task.description.eng}
                                label="Description English"
                                onChange={e => {
                                  UpdateTask(indexTask, 'description', 'eng', e.target.value);
                                }}
                              />
                            </div>

                            <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                              <TextField
                                fullWidth
                                required
                                value={task.description.hind}
                                label="Description Hindi"
                                onChange={e => {
                                  UpdateTask(indexTask, 'description', 'hind', e.target.value);
                                }}
                              />
                            </div>
                          </Stack>
                        </Box>
                        <Box m={1} p={1}>
                          <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                            SubTasks
                            <span style={{ float: 'right' }}>
                              <Button
                                onClick={() => addnewSubtask(indexTask)}
                                style={{ background: '#519BD0' }}
                              >
                                <AddIcon />
                                Add New SubTask
                              </Button>
                            </span>
                          </div>
                        </Box>
                        {task['subTasks'].map((subTask: any, subTaskIndex: number) => {
                          return (
                            <Box m={1} p={1}>
                              <div
                                style={{
                                  font: `${FONT_NAME}`,
                                  fontSize: 16,
                                }}
                              >
                                SubTask {subTaskIndex + 1}
                                <span style={{ float: 'right' }}>
                                  <Button
                                    onClick={() => delteSubTask(indexTask, subTaskIndex)}
                                    style={{ background: '#519BD0' }}
                                  >
                                    Delete SubTask
                                  </Button>
                                </span>
                              </div>
                              <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                                <TextField
                                  fullWidth
                                  required
                                  value={subTask.name}
                                  label="Description English"
                                  onChange={e => {
                                    UpdateSubTask(indexTask, subTaskIndex, 'name', e.target.value);
                                  }}
                                />
                              </div>

                              <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                                <TextField
                                  fullWidth
                                  required
                                  value={subTask.namehindi}
                                  label="Description Hindi"
                                  onChange={e => {
                                    UpdateSubTask(
                                      indexTask,
                                      subTaskIndex,
                                      'namehindi',
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </Box>
                          );
                        })}
                        <Box m={1} p={1}>
                          <Stack
                            direction="column"
                            gap={2}
                            style={{ font: `${FONT_NAME}`, fontSize: 20 }}
                          >
                            <div>Escalation Levels</div>
                            {Object.keys(task['escalations']).map(escalationLevel => {
                              return (
                                <>
                                  <Stack
                                    direction="column"
                                    gap={1}
                                    border={1}
                                    p={1}
                                    style={{
                                      font: `${FONT_NAME}`,
                                      fontSize: 16,
                                    }}
                                  >
                                    {escalationLevel}
                                    <Autocomplete
                                      id="tags-standard"
                                      options={escaltionOptions}
                                      value={task['escalations'][escalationLevel]['group']}
                                      onChange={(event, newValue) => {
                                        UpdateEscaltionLevel(
                                          indexTask,
                                          escalationLevel,
                                          'group',
                                          newValue
                                        );
                                      }}
                                      renderInput={params => (
                                        <TextField
                                          required
                                          {...params}
                                          variant="standard"
                                          label="Escalation Group"
                                        />
                                      )}
                                    />

                                    <TextField
                                      fullWidth
                                      required
                                      value={task['escalations'][escalationLevel]['time']}
                                      label="Escalation Time"
                                      onChange={e => {
                                        UpdateEscaltionLevel(
                                          indexTask,
                                          escalationLevel,
                                          'time',
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </>
                              );
                            })}
                          </Stack>
                          <Box m={1} p={1}>
                            <Stack
                              direction="column"
                              gap={2}
                              style={{ font: `${FONT_NAME}`, fontSize: 20 }}
                            >
                              <Autocomplete
                                multiple
                                id="tags-standard"
                                options={Days}
                                value={task['recurringDays']}
                                onChange={(event, newValue) => {
                                  updateDirectValue(indexTask, 'recurringDays', newValue);
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Recurring Days"
                                  />
                                )}
                              />

                              <Autocomplete
                                multiple
                                id="tags-standard"
                                options={weeks}
                                value={task['weeks']}
                                onChange={(event, newValue) => {
                                  updateDirectValue(indexTask, 'weeks', newValue);
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Recurring Weeks"
                                  />
                                )}
                              />

                              <TextField
                                fullWidth
                                required
                                value={task['recurringTimes']}
                                label="Recurring Time"
                                onChange={e => {
                                  updateDirectValue(indexTask, 'recurringTimes', e.target.value);
                                }}
                              />
                            </Stack>
                          </Box>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task['dueTimeFromPosting']}
                              label="OverDue Time"
                              onChange={e => {
                                updateDirectValue(indexTask, 'dueTimeFromPosting', e.target.value);
                              }}
                            />
                          </Box>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task['videoUrl']}
                              label="Video Url"
                              onChange={e => {
                                updateDirectValue(indexTask, 'videoUrl', e.target.value);
                              }}
                            />
                          </Box>
                          <Box m={1} p={1}>
                            <Autocomplete
                              id="tags-standard"
                              options={assignRole}
                              value={task['toUser']}
                              onChange={(event, newValue) => {
                                updateDirectValue(indexTask, 'toUser', newValue);
                              }}
                              renderInput={params => (
                                <TextField
                                  required
                                  {...params}
                                  variant="standard"
                                  label="For Role"
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}

          <Box m={1} p={1}>
            <Button onClick={handleSubmit} variant="contained" style={{ color: 'white' }}>
              Next
            </Button>
          </Box>
          <Box
            m={4}
            p={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BottomNavigationComponent plantId={id!} currentPage="Fixed Tasks" />
          </Box>
        </>
      )}
    </LayoutComponent>
  );
}
