import axiosInstance from '../constants/axiosInstance';

const getAllPermissionList = async () => {
  try {
    const { data } = await axiosInstance().post(`/permission/getUserPermissionsTree`);
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getPermissionByTag = async ({ permissionTag }: { permissionTag: string }) => {
  try {
    if (permissionTag === null) {
      const { data } = await axiosInstance().get(`/newUsers/getWorkForce?forWho=${permissionTag}`);
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export { getAllPermissionList, getPermissionByTag };
