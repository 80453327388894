import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LayoutComponent from "../components/LayoutComponent";
import SensorsDataGrid from "../components/sensors/SensorsDataGrid";
import { fetchSensorsList } from "../services/sensors";
import { SENSOR_CATEGORIES } from "../constants/constants";
import { SensorStyles } from "../components/sensors/sensors";
import { ToastContainer, toast } from "react-toastify";
import { FONT_NAME } from "../core-components/theme/theme";

export default function Sensors() {
  type queryParam = {
    plantId: string;
  };
  const { plantId = "" } = useParams<queryParam>();
  const [loading, setLoading] = useState(true);
  const [isSensorDataUpdated, setIsSensorDataUpdated] = useState(false);
  const [sensorsData, setSensorsData] = useState<any>({});
  const [updatedSensorData, setUpdatedSensorData] = useState<any>({});
  const [processing, setProcessing] = useState(false);
  const [showProcessingResult, setShowProcessingResult] = useState(false);

  function getSensorType(dataInputType: string) {
    if (
      dataInputType === "Virtual" ||
      dataInputType === "Virtual Sensors" ||
      dataInputType === "Virtual Sensor"
    ) {
      return "Virtual Sensors";
    } else if (dataInputType === "manual") {
      return "Data Input Sensors";
    } else if (dataInputType === "Real Sensors") {
      return "Real Sensors";
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (Object.keys(sensorsData).length === 0) {
      fetchSensorsList(plantId).then((data) => {
        setSensorsData(data);
        setLoading(false);
      });
    } else if (Object.keys(updatedSensorData).length > 0) {
      let sensorType = getSensorType(updatedSensorData?.dataInputType);
      if (sensorType === "") {
        toast.error("Invalid Sensor Type contact admin");
      } else {
        fetchSensorsList(plantId).then((data) => {
          setSensorsData(data);
          setLoading(false);
        });

        setProcessing(false);
        setShowProcessingResult(true);
        setUpdatedSensorData({});
      }
    } else {
    }
  }, [plantId, sensorsData, updatedSensorData]);

  return (
    <LayoutComponent plantId={plantId}>
      <Box m={1} p={1}>
        <div
          style={{
            fontFamily: `${FONT_NAME}`,
            fontSize: 30,
            alignItems: "center",
            verticalAlign: "middle",
          }}
        >
          Sensors
        </div>
      </Box>
      <Box m={1} p={1}>
        {loading && <CircularProgress />}
        {!loading &&
          sensorsData[SENSOR_CATEGORIES[0]]["data"]?.length > 0 &&
          SENSOR_CATEGORIES.map((category, key) => (
            <Accordion
              key={`sensor-category-${key}`}
              defaultExpanded={key === 0 ? true : false}
              sx={{ marginBottom: "30px", boxShadow: "2px 2px #e8eaef" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`category-${key}`}
                sx={{ width: "100%" }}
              >
                <div style={SensorStyles.AccordianSummary}>
                  <Typography mr={5}>{category}</Typography>
                  <span style={SensorStyles.SensorStatus}>
                    <span style={SensorStyles.ActiveSensor}>
                      Active : {sensorsData[category]["activeCount"]}
                    </span>
                    <span style={SensorStyles.InActiveSensor}>
                      Inactive : {sensorsData[category]["inActiveCount"]}
                    </span>
                    <span style={SensorStyles.DeletedSensor}>
                      Deleted : {sensorsData[category]["deletedCount"]}
                    </span>
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <SensorsDataGrid
                  setLoading={setLoading}
                  sensorsData={sensorsData[category]["data"]}
                  isSensorDataUpdated={isSensorDataUpdated}
                  setIsSensorDataUpdated={setIsSensorDataUpdated}
                  setUpdatedSensorData={setUpdatedSensorData}
                  processing={processing}
                  setProcessing={setProcessing}
                  showProcessingResult={showProcessingResult}
                  setShowProcessingResult={setShowProcessingResult}
                  activeCount={sensorsData[category]["activeCount"]}
                  inActiveCount={sensorsData[category]["inActiveCount"]}
                  deletedCount={sensorsData[category]["deletedCount"]}
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <ToastContainer />
    </LayoutComponent>
  );
}
