import { useState } from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { FONT_NAME } from "../../core-components/theme/theme";

export default function CustomSwipableDrawer(props: any) {
  const [open, setOpen] = useState(false);

  const { width, buttonName, background } = props;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box sx={{ display: "block" }}>
        <Button
          variant="contained"
          sx={{
            // display: { xs: 'block', sm: 'none' },
            width: "200px",
            height: "38px",
            textTransform: "none",
            position: "fixed",
            right: -82,
            top: "50%",
            transform: "rotate(90deg)",
            fontSize: "16px",
            background: "#193458",
            color: "#eee",
            borderRadius: "0px 0px 14px 14px",
            textAlign: "center",
            fontFamily: `${FONT_NAME}`,
          }}
          onClick={toggleDrawer(true)}
        >
          <p> {buttonName} </p>
        </Button>
      </Box>

      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ "& .MuiDrawer-paper": { width: width, background: background } }}
      >
        <Box
          style={{
            width: "100%",
            background: background,
            marginTop: "60px",
            height: "inherit",
            // overflowY : "scroll"
          }}
        >
          {props.children}
        </Box>
      </Drawer>
    </>
  );
}
