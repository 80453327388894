import React, { useState, useEffect, useRef } from 'react';
import DialogBox from '../../core-components/theme/components/DialogBox/DialogBox';
import Table from '../../core-components/theme/components/Table/Table';
import styles from '../../styles/pages/ShareDashboardDialog';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  ThemeProvider,
  Autocomplete,
  Paper,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import dpTheme from '../../styles/theme/dpTheme';
import CustomIconButton from '../../core-components/theme/components/Button/Iconbutton';

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  listOfUsers: { userId: string; name: string; email: string; action: string }[];
  availablePermissions: { name: string; value: string }[];
  assignedPermissions: {
    sharedToUserDetails: { userId: string; email: string };
    permissionType: string;
    permissionId: string;
  }[];
  onSave: (sharedUsersData: any, defaultDashboardPage: any) => void;
}

const CustomPaper = (props: any) => (
  <Paper
    {...props}
    style={{
      margin: 0,
      padding: 0,
      backgroundColor: 'white', // Change the background color as needed
      boxShadow: 'none', // Remove the shadow if needed
    }}
  >
    {props.children}
  </Paper>
);

const ShareDialog: React.FC<ShareDialogProps> = ({
  open = false,
  onClose,
  listOfUsers = [],
  availablePermissions = [],
  assignedPermissions = [],
  onSave,
}) => {
  const [user, setUser] = useState('');
  const [selectedUser, setSelectedUser] = useState<{
    userId: string;
    name: string;
    email: string;
  } | null>(null);
  const [permission, setPermission] = useState(availablePermissions[0]?.name || '');
  const [sharedUsers, setSharedUsers] = useState<
    { user: string; permission: string; userId: string; permissionId?: string; action?: string }[]
  >([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (user) {
    } else {
    }
  }, [user, listOfUsers, sharedUsers]);

  useEffect(() => {
    if (assignedPermissions && availablePermissions) {
      const initialPermissions = assignedPermissions.map(
        ({ sharedToUserDetails, permissionType, permissionId }) => {
          const permissionName =
            availablePermissions.find(({ value }) => value === permissionType)?.name ||
            permissionType;
          return {
            user: sharedToUserDetails?.email || '',
            permission: permissionName || '',
            userId: sharedToUserDetails?.userId || '',
            permissionId: permissionId || '',
          };
        }
      );
      setSharedUsers(initialPermissions || []);
    }
  }, [assignedPermissions, availablePermissions]);

  const handleAddUser = () => {
    if (selectedUser) {
      setSharedUsers([
        ...sharedUsers,
        { user: selectedUser.email, permission, userId: selectedUser.userId },
      ]);
      setUser('');
      setSelectedUser(null);
      setPermission(availablePermissions[0]?.name || '');
    }
  };

  const handleDeleteUser = (userToDelete: any) => {
    let updatedUsers = sharedUsers.map(user =>
      user.userId === userToDelete.userId ? { ...user, action: 'delete' } : user
    );

    setSharedUsers(updatedUsers); // Update state with the modified list
  };

  const handleUserSelect = (selected: { userId: string; name: string; email: string }) => {
    setSelectedUser(selected || null);
    setUser(selected?.name || '');
  };

  const handleSave = () => {
    const usersPermissions = sharedUsers.map(user => {
      const permissionData = availablePermissions.find(perm => perm?.name === user?.permission);
      return {
        userId: user?.userId || '',
        permissionType: permissionData?.value || user?.permission,
        action: user?.action || 'save',
        permissionId: user?.permissionId || '',
      };
    });

    const dataToSend = { usersPermissions };

    onSave(dataToSend, null);
    onClose();
  };

  const filteredUsersData: any =
    listOfUsers.filter(
      ({ email }) =>
        !sharedUsers.some(sharedUser => sharedUser.user === email && sharedUser.action !== 'delete')
    ) || [];

  const tableRows =
    sharedUsers
      .filter(user => user.action !== 'delete') // Filter out users marked for deletion
      .map((user, index) => ({
        ...user,
        actions: index,
      })) || [];

  const tableColumns = [
    { field: 'user', headerName: 'User' },
    { field: 'permission', headerName: 'Permission' },
    {
      field: 'actions',
      headerName: 'Delete',
      renderCell: (_: any, row: any) => (
        <CustomIconButton icon={DeleteOutlinedIcon} onClick={() => handleDeleteUser(row)} />
      ),
    },
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <ThemeProvider theme={dpTheme}>
      <DialogBox open={open} onClose={onClose} maxWidth="md" title="Share Dashboard" fullWidth>
        <Box sx={{ display: 'flex', gap: 3, mb: 3, mt: 3 }}>
          <FormControl variant="outlined" sx={styles.formControl}>
            <Autocomplete
              freeSolo
              id="user-autocomplete"
              disableClearable
              options={filteredUsersData} // Limit to 5 suggestions
              getOptionLabel={(option: any) => `${option.name} (${option.email})`}
              onChange={(event, selectedOption) => {
                if (selectedOption) {
                  handleUserSelect(selectedOption); // Pass the full object to handleUserSelect
                }
              }}
              PaperComponent={CustomPaper}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="User"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      type: 'search',
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <>
                  <li {...props} style={{ padding: '4px 8px', fontSize: '14px' }}>
                    {option.name}
                    <br />
                    {option.email}
                  </li>
                  <hr
                    style={{ margin: '4px 0', border: 'none', borderTop: '1px solid lightgray' }}
                  />
                </>
              )}
            />
          </FormControl>
          <FormControl variant="outlined" sx={styles.formControlPermission}>
            <InputLabel>Permission</InputLabel>
            <Select
              value={permission || 'View'}
              onChange={event => setPermission(event?.target?.value as string)}
              label="Permission"
              fullWidth
            >
              {availablePermissions?.map(perm => (
                <MenuItem key={perm?.value} value={perm?.name || ''}>
                  {perm?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={handleAddUser}
            variant="contained"
            sx={{
              fontSize: '1.5rem',
              color: 'grey',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            +
          </Button>
        </Box>
        <Table columns={tableColumns} rows={tableRows} />
        <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" sx={styles.cancelButton} onClick={onClose}>
            CANCEL
          </Button>
          <Button variant="contained" sx={styles.saveButton} onClick={handleSave}>
            SHARE
          </Button>
        </Box>
      </DialogBox>
    </ThemeProvider>
  );
};

export default ShareDialog;
