import { Box, Button, Dialog, Divider, Grid, Stack, Typography } from '@mui/material';
import GraphLineIconColored from '../../assets/icons/GraphLineIconColored.svg';
import GraphLineIcon from '../../assets/icons/GraphLineIcon.svg';
import SludgeVolumeIcon from '../../assets/icons/SludgeVolumeIcon.svg';
import SludgeVolumeIconColored from '../../assets/icons/SludgeVolumeIconColored.svg';
import SludgeIcon from '../../assets/icons/SludgeIcon.svg';
import NoSludgeIcon from '../../assets/icons/NoSludgeIcon.svg';

import OpenNewTab from '../../assets/icons/ExpandIcon.svg';
import DownloadIcon from '../../assets/icons/DownloadCSV.svg';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import minIcon30 from '../../assets/icons/30minIcon.svg';
import minIcon60 from '../../assets/icons/60minIcon.svg';
import minIcon90 from '../../assets/icons/90minIcon.svg';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ToolTip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { useEffect, useRef, useState } from 'react';
// import convertISOToLocalTime from '../../globalComponents/dateAndTime/ConvertISOIntoLocalTime';
import convertISOToLocalTimeWithOffset from '../../globalComponents/dateAndTime/convertISOToLocalTimeWithOffset';
import useIsTablet from '../../utilities/customHooks/useIsTablet';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import axios from 'axios';
import LineChartFlocDetector from './FlocDetectorCharts/LineChartFlocDetector';
import StackedBarChartFlocDetector from './FlocDetectorCharts/StackBarChartFlocDetector';
import ClockIcon from '@mui/icons-material/WatchLater';
import flocDetectorDefaultSamplesData from '../../constants/flocDetectorDefaultSamplesData';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  calculateNextDate,
  calculatePreviousDate,
  countWords,
  downloadCSV,
  downloadCSVForBarGraph,
  formatTimeRange,
  getDayMonthYear,
  handleOnClickIssueIcon,
  handleOnClickMinutesIcon,
  processData,
} from './Helper';
import ImageDialogueBox from './FlocDetectorCommentDialogueBox/ImageDialogueBox';
import CommentDialogueBox from './FlocDetectorCommentDialogueBox/CommentDialogueBox';
import useIsDesktop from '../../utilities/customHooks/useIsDesktop';
import FactoryIcon from '@mui/icons-material/Factory';
import { SecondaryButton } from '../../styles/global/components/dpButtons';
import linkIcon from '../../constants/Images/linkIcon.svg';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import flocVisualizationData from '../../constants/lineflocDetectorDefaultSamplesData';
import { FONT_NAME } from '../../core-components/theme/theme';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);
ChartJS.unregister(zoomPlugin);

// ChartJS.unregister(zoomPlugin);
const styles: any = {
  hiddenComponent: {
    visibility: 'hidden',
  },
  visibleComponent: {
    visibility: 'visible',
  },
  displayNoneComponent: {
    display: 'none',
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    padding: '15px',
    color: '#193458',
    fontFace: `${FONT_NAME}`,
    fontWeight: '500',
    overflow: 'hidden',
    fontSize: 'clamp(16px,1.1vw,25px)',
  },
};

const FlocDetectorVisualizationChart = (props: any) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    // selectedTypeGraph,
    // setSelectedTypeGraph,
    // expandedViewPopup,
    // setMakingSampleDefault,
    // widgetStructure,
    widgetData,
    // setExpandedViewPopup,
    // isLoading,
    // headerType,
    // enableZoom,
    // viewEveryNthTick,
    // ticksColor,
    showLinkIcon,
    linkOnWidget,
    // setSelectedChart,
    handleEditClick,
    showClock,
    customTimeRangeSettings,
    timeFrame,
    handleClockClick,
    // customGranularity,
    // selectChart,
    // isBottomBarOpen,
    // setIsBottomBarOpen,
    // setPreviousSelectedChart,
    // add530HoursInTimeFrame,
    // controller,
    // widgetTimeFrame,
    // refresh,
  } = props;

  console.log('customTimeRangeSettings::', customTimeRangeSettings);

  const userId = localStorage.getItem('userId');
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const [selectedTypeGraph, setSelectedTypeGraph] = useState<any>('Sludge Settling Pattern');
  const flocData: any = flocVisualizationData.flocData[0];
  const barGraphData = flocDetectorDefaultSamplesData;

  const [addComment, setAddComment] = useState({
    title: '',
    comment: '',
  });

  const [allComments, setAllComments] = useState<any>([
    'this is formal comment ',
    'this comment can help in better communication',
  ]);
  const [imageDetails, setImageDetails] = useState<any>(null);
  const [imgId, setImgId] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentShiftIndex, setCurrentShiftIndex] = useState(0);
  const [currentSampleIndex, setCurrentSampleIndex] = useState(0);
  let currentStartDate: React.MutableRefObject<string> = useRef(timeFrame.startDate.toISOString());
  let currentEndDate: React.MutableRefObject<string> = useRef(timeFrame.endDate.toISOString());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openComment = Boolean(anchorEl);

  const [anchorElComment, setAnchorElComment] = useState<null | HTMLElement>(null);
  const openCommentDialogue = Boolean(anchorElComment);
  const [commentDialogueHeaderText, setCommentDialogueHeaderText] = useState(null);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorComment, setErrorComment] = useState(false);

  const imageCache: { [url: string]: HTMLImageElement } = {};
  const [issueIcon, setIssueIcon] = useState<{ [key: string]: boolean }>({});
  const [hiddenLabels, setHiddenLabels] = useState<{ [key: string]: boolean }>({});

  const handleSelectedGraphType = (value: string) => {
    setSelectedTypeGraph(value);
  };

  const fetchFlocImgData = async (imageId: string) => {
    try {
      setImageDetails(
        'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail'
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
      }
    }
  };

  // Updated handlePrevious Function
  const handlePrevious = async () => {
    let prevShiftIndex = currentShiftIndex;
    let prevSampleIndex = currentSampleIndex - 1;

    // If at the beginning of the first sample of the first shift
    if (prevSampleIndex < 0) {
      if (prevShiftIndex === 0) {
        // Move to the last shift and its last sample
        prevShiftIndex = flocData.length - 1;
        prevSampleIndex = flocData[prevShiftIndex]?.samples.length - 1 || 0;
      } else {
        // Move to the previous shift and set sample index to its last sample
        prevShiftIndex -= 1;
        prevSampleIndex = flocData[prevShiftIndex]?.samples.length - 1 || 0;
      }
    }

    // Update state variables
    setCurrentShiftIndex(prevShiftIndex);
    setCurrentSampleIndex(prevSampleIndex);
  };

  // Updated handleNext Function
  const handleNext = async () => {
    let nextShiftIndex = currentShiftIndex;
    let nextSampleIndex = currentSampleIndex + 1;

    // If at the end of the last sample of the last shift
    if (nextSampleIndex >= flocData[nextShiftIndex]?.samples.length) {
      nextShiftIndex += 1;
      nextSampleIndex = 0;

      if (nextShiftIndex >= flocData.length) {
        // If no more shifts available, reset to the first shift and first sample
        nextShiftIndex = 0;
        nextSampleIndex = 0;
      }
    }

    // Update state variables
    setCurrentShiftIndex(nextShiftIndex);
    setCurrentSampleIndex(nextSampleIndex);
  };

  const handleClickOpen = (imageId: any) => {
    setImgId(imageId);
    setOpen(true);
    fetchFlocImgData(imageId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseComment = (event: React.MouseEvent<HTMLElement>, value: any) => {
    if (value === 'backdropClick') {
      setAnchorEl(null);
      return;
    }
    setCommentDialogueHeaderText(value);
    setAnchorEl(null);
    setAnchorElComment(event.currentTarget);
    setOpen(false);
  };

  const handleCloseCommentDialogue = () => {
    setAnchorElComment(null);
    setOpen(true);
    setAddComment({ title: '', comment: '' });
    setErrorTitle(false);
    setErrorComment(false);
  };

  const handleCloseCommentDialogueOutside = () => {
    setAnchorElComment(null);
    setOpen(false);
    setAddComment({ title: '', comment: '' });
    setErrorTitle(false);
    setErrorComment(false);
  };

  const handleOnChangeComment = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setAddComment({ ...addComment, [name]: value });
  };

  const handleAddComment = () => {
    const { title, comment } = addComment;

    // Validation
    let valid = true;
    if (title.length < 4 || title.length > 20) {
      setErrorTitle(true);
      valid = false;
    } else {
      setErrorTitle(false);
    }

    if (countWords(comment) < 5) {
      setErrorComment(true);
      valid = false;
    } else {
      setErrorComment(false);
    }

    if (!valid) {
      return;
    }

    setAllComments([...allComments, addComment]);
    setAddComment({ title: '', comment: '' });
    handleCloseCommentDialogue();
  };

  const [visibleIcons, setVisibleIcons] = useState<any>({
    issueIcon: false,
    falseDetection: false,
    noSludge: false,
  });

  useEffect(() => {
    currentStartDate.current =
      showClock === 'on'
        ? customTimeRangeSettings.startTime.toISOString()
        : timeFrame.startDate.toISOString();
    currentEndDate.current =
      showClock === 'on'
        ? customTimeRangeSettings.endTime.toISOString()
        : timeFrame.endDate.toISOString();
    console.log('these are the custom time range settings :', customTimeRangeSettings);
  }, [
    timeFrame.startDate,
    timeFrame.endDate,
    customTimeRangeSettings.startTime,
    customTimeRangeSettings.endTime,
  ]);

  const [openExpandedView, setOpenExpandedView] = useState(false);
  const [resetZoom, setResetZoom] = useState(false);

  const handleClickOpenExpandedView = () => {
    setOpenExpandedView(true);
    ChartJS.register(zoomPlugin);
  };

  const handleCloseExpandedView = () => {
    setOpenExpandedView(false);
    ChartJS.unregister(zoomPlugin);
  };

  // if (flocData.length === 0)
  //   return <Typography>No data available for floc detector</Typography>

  return (
    <>
      <Grid
        container
        md={12}
        lg={12}
        border="1px solid #0000003B"
        borderRadius="16px"
        pl="24px"
        mt="30px"
        pt="20px"
        pr="20px"
        pb="20px"
        display="flex"
        flexDirection="column"
        width="94.2vw"
        height={'inherit'}
        sx={{ backgroundColor: '#ffffff' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isMobile ? (
          <>
            <Grid
              container
              alignItems="flex-start"
              gap={3} // Equidistant gapping between all container items
              justifyContent="space-between"
              flexWrap="nowrap"
            >
              <Grid item display="flex" gap={3} alignItems="flex-start">
                <Grid item display="flex" flexDirection="column" gap={2}>
                  {' '}
                  {/* Added gap here */}
                  <Grid item display="flex" alignItems="center" gap={2}>
                    {' '}
                    {/* Gap for Typography and ToolTip */}
                    <Typography variant="h4" color="#1A3356" fontWeight="600" fontSize="18px">
                      {widgetData.widgetNickName}
                    </Typography>
                    <ToolTip sx={styles.showIconStyle} title={widgetData.widgetDescription}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: '#80858CD9',
                          marginLeft: '10px', // Space between icon and text
                          ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                        }}
                      />
                    </ToolTip>
                  </Grid>
                  {isDesktop && (
                    <Grid item display="flex" gap={1}>
                      {' '}
                      {/* Added gap between clock icons */}
                      {showClock === 'on' && (
                        <Stack direction="row" spacing={1} mb={1}>
                          <ClockIcon
                            sx={{
                              width: '18px',
                              height: '18px',
                              cursor: 'pointer',
                              color: '#5cb85c',
                            }}
                            onClick={() => handleClockClick('disabled')}
                          />
                          <span style={{ fontSize: '13px', color: '#7D8188' }}>
                            {formatTimeRange(customTimeRangeSettings)}
                          </span>
                        </Stack>
                      )}
                      {showClock === 'disabled' && (
                        <Stack direction="row" spacing={1} mb={1}>
                          <ClockIcon
                            sx={{
                              width: '18px',
                              height: '18px',
                              cursor: 'pointer',
                            }}
                            color="disabled"
                            onClick={() => handleClockClick('on')}
                          />
                          <span style={{ fontSize: '13px', color: '#7D8188' }}>
                            {formatTimeRange(customTimeRangeSettings)}
                          </span>
                        </Stack>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid item display="flex" alignItems="center" gap={3} mt={-1}>
                  {' '}
                  {/* Gap for icons */}
                  <ToolTip
                    title="Sludge Value"
                    slotProps={{
                      popper: {
                        sx: {
                          [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                              marginTop: '0px',
                            },
                        },
                      },
                    }}
                  >
                    <img
                      src={
                        selectedTypeGraph === 'Sludge Value'
                          ? SludgeVolumeIconColored
                          : SludgeVolumeIcon
                      }
                      onClick={() => handleSelectedGraphType('Sludge Value')}
                      style={{ cursor: 'pointer' }}
                    />
                  </ToolTip>
                  <ToolTip
                    title="Sludge Settling Pattern"
                    slotProps={{
                      popper: {
                        sx: {
                          [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                              marginTop: '0px',
                            },
                        },
                      },
                    }}
                  >
                    <img
                      src={
                        selectedTypeGraph === 'Sludge Settling Pattern'
                          ? GraphLineIconColored
                          : GraphLineIcon
                      }
                      onClick={() => handleSelectedGraphType('Sludge Settling Pattern')}
                      style={{ cursor: 'pointer' }}
                    />
                  </ToolTip>
                </Grid>
              </Grid>

              {flocData.length > 0 && selectedTypeGraph === 'Sludge Settling Pattern' && (
                <Grid
                  item
                  display="flex"
                  alignItems="start"
                  mr={isTablet ? 0 : 5} // This conditionally adds margin-right based on device type
                  gap={2}
                  flexDirection={isTablet ? 'column' : 'row'}
                  mt={-1}
                  sx={{
                    marginRight: '400px !important', // This ensures margin-right is overridden if needed
                  }}
                >
                  <Grid item display="flex" alignItems="center" gap={1}>
                    <KeyboardArrowLeftIcon
                      sx={{ color: '#0000008F', cursor: 'pointer' }}
                      onClick={handlePrevious}
                    />
                    <Grid item display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon fontSize="small" sx={{ color: '#1A3356' }} />
                      <Grid item>
                        <Typography color="#5C6066" fontWeight="500" fontSize="12px">
                          {getDayMonthYear(flocData[currentShiftIndex].startTime)}
                        </Typography>
                        <Typography color="#999" fontSize="10px">
                          {flocData[currentShiftIndex].shiftName} (
                          {convertISOToLocalTimeWithOffset(flocData[currentShiftIndex].startTime)} -{' '}
                          {convertISOToLocalTimeWithOffset(flocData[currentShiftIndex].endTime)})
                          |&nbsp;
                          {currentSampleIndex + 1} / {flocData[currentShiftIndex].samples?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    <KeyboardArrowRightIcon
                      sx={{ color: '#0000008F', cursor: 'pointer' }}
                      onClick={handleNext}
                    />
                  </Grid>
                  {widgetData.showAllSample && isDesktop && (
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#3271A9',
                        border: '1px solid #3271A9',
                        marginLeft: '8px',
                        fontSize: '10px',
                        fontWeight: '500',
                        marginRight: '177px !important', // Added margin-right for the button as well
                      }}
                      disabled={flocData[currentShiftIndex].samples[currentSampleIndex]?.default}
                    >
                      MAKE THIS DEFAULT
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>

            {flocData.length > 0 &&
              selectedTypeGraph === 'Sludge Settling Pattern' &&
              widgetData.showAllSample &&
              isTablet && (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      color: '#3271A9',
                      border: '1px solid #3271A9',
                      marginLeft: '8px',
                      fontSize: '10px',
                      fontWeight: '500',
                    }}
                    disabled={flocData[currentShiftIndex]?.samples[currentSampleIndex]?.default}
                  >
                    DEFAULT
                  </Button>
                </>
              )}
            {selectedTypeGraph === 'Sludge Value' && (
              <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={3}>
                <Grid item display="flex" gap={3} style={{ marginBottom: '30px' }}>
                  <Grid
                    item
                    display="flex"
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'falseDetectionIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={issueIcon.falseDetectionIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      False Detection
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'floatingSludgeIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={SludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={issueIcon.floatingSludgeIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      Floating Sludge
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'noSludgeIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={NoSludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={issueIcon.noSludgeIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      No Sludge
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  gap={3}
                  style={{ marginBottom: '30px' }}
                >
                  <ToolTip title="Download CSV">
                    <img
                      src={DownloadIcon}
                      onClick={() => {
                        if (selectedTypeGraph === 'Sludge Settling Pattern') {
                          downloadCSV(
                            flocData[currentShiftIndex]?.shiftName,
                            getDayMonthYear(flocData[currentShiftIndex]?.startTime),
                            processData(flocData[currentShiftIndex]?.samples[currentSampleIndex])
                          );
                        } else if (selectedTypeGraph === 'Sludge Value') {
                          downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                        }
                      }}
                      style={{
                        cursor: 'pointer',
                        ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                      }}
                    />
                  </ToolTip>
                  <ToolTip title="Expanded View">
                    <img
                      src={OpenNewTab}
                      style={{
                        cursor: 'pointer',
                        ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                      }}
                      onClick={handleClickOpenExpandedView}
                    />
                  </ToolTip>

                  <ToolTip title="Edit Widget">
                    <ModeEditOutlineIcon
                      fontSize="small"
                      sx={{
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                        color: '#80858CD9',
                        ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                      }}
                      onClick={() => {
                        handleEditClick('flocDetector');
                      }}
                    />
                  </ToolTip>
                </Grid>
              </Grid>
            )}
            {selectedTypeGraph === 'Sludge Settling Pattern' && (
              <Grid
                item
                display="flex"
                justifyContent={isTablet ? 'space-between' : 'flex-end'}
                alignItems="center"
                gap={3}
                mt={isDesktop ? -3 : 0}
              >
                {isTablet && (
                  <Grid item display="flex" gap={1}>
                    {showClock == 'on' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            color: '#5cb85c	',
                          }}
                          onClick={() => handleClockClick('disabled')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(customTimeRangeSettings)}
                        </span>
                      </Stack>
                    )}
                    {showClock === 'disabled' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                          }}
                          color="disabled"
                          onClick={() => handleClockClick('on')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(customTimeRangeSettings)}
                        </span>
                      </Stack>
                    )}
                  </Grid>
                )}
                <Grid display="flex" item gap={3} mt={1}>
                  <Grid
                    item
                    display="flex"
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'falseDetection',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.falseDetection ? { textDecoration: 'line-through' } : {}}
                    >
                      False Detection
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'issueIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={SludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.issueIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      Floating Sludge
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'noSludge',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={NoSludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.noSludge ? { textDecoration: 'line-through' } : {}}
                    >
                      No Sludge
                    </Typography>
                  </Grid>
                  {/* {selectedTypeGraph === 'Sludge Settling Pattern' && ( */}
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    gap={3}
                    mt={isTablet ? '5px' : '0px'}
                  >
                    <ToolTip title="Download CSV">
                      <img
                        src={DownloadIcon}
                        onClick={() => {
                          if (flocData.length > 0) {
                            // Only trigger download if flocData is not empty
                            if (selectedTypeGraph === 'Sludge Settling Pattern') {
                              downloadCSV(
                                flocData[currentShiftIndex]?.shiftName,
                                getDayMonthYear(flocData[currentShiftIndex]?.startTime),
                                processData(
                                  flocData[currentShiftIndex]?.samples[currentSampleIndex]
                                )
                              );
                            } else if (selectedTypeGraph === 'Sludge Value') {
                              downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                            }
                          }
                        }}
                        style={{
                          cursor: flocData.length === 0 ? 'not-allowed' : 'pointer',
                          ...(isHovered && flocData.length > 0
                            ? styles.visibleComponent
                            : styles.hiddenComponent),
                          opacity: flocData.length === 0 ? 0.5 : 1, // Reduce opacity to indicate disabled state
                        }}
                      />
                    </ToolTip>

                    <ToolTip title="Expanded View">
                      <img
                        src={OpenNewTab}
                        style={{
                          cursor: flocData.length === 0 ? 'not-allowed' : 'pointer',
                          ...(isHovered && flocData.length > 0
                            ? styles.visibleComponent
                            : styles.hiddenComponent),
                          opacity: flocData.length === 0 ? 0.5 : 1, // Reduce opacity to indicate disabled state
                        }}
                        onClick={() => {
                          if (flocData.length > 0) {
                            handleClickOpenExpandedView();
                          }
                        }}
                      />
                    </ToolTip>

                    <ToolTip title="Edit Widget">
                      <ModeEditOutlineIcon
                        fontSize="small"
                        sx={{
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer',
                          color: '#80858CD9',
                          ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                        }}
                        onClick={() => {
                          handleEditClick('flocDetector');
                        }}
                      />
                    </ToolTip>
                  </Grid>
                  {/* )} */}
                </Grid>
              </Grid>
            )}
            {selectedTypeGraph === 'Sludge Value' && (
              <Grid
                item
                display="flex"
                justifyContent={isTablet ? 'space-between' : 'flex-end'}
                alignItems="center"
                gap={3}
                mt={isDesktop ? -3 : 0}
              >
                {isTablet && (
                  <Grid item display="flex" gap={1}>
                    {showClock == 'on' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            color: '#5cb85c	',
                          }}
                          onClick={() => handleClockClick('disabled')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(customTimeRangeSettings)}
                        </span>
                      </Stack>
                    )}
                    {showClock === 'disabled' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                          }}
                          color="disabled"
                          onClick={() => handleClockClick('on')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(customTimeRangeSettings)}
                        </span>
                      </Stack>
                    )}
                  </Grid>
                )}
                <Grid item display="flex" gap={3}>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickMinutesIcon('30 minutes', setHiddenLabels);
                    }}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <img
                      src={minIcon30}
                      style={{
                        height: '14px',
                        width: '14px',
                      }}
                    />
                    <Typography
                      color="#5C6066"
                      fontSize="12px"
                      whiteSpace="nowrap"
                      style={
                        hiddenLabels['30 minutes']
                          ? {
                              textDecoration: 'line-through',
                            }
                          : {}
                      }
                    >
                      30 Min
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickMinutesIcon('60 minutes', setHiddenLabels);
                    }}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={minIcon60} style={{ height: '14px', width: '14px' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="12px"
                      whiteSpace="nowrap"
                      style={hiddenLabels['60 minutes'] ? { textDecoration: 'line-through' } : {}}
                    >
                      60 Min
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickMinutesIcon('90 minutes', setHiddenLabels);
                    }}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={minIcon90} style={{ height: '14px', width: '14px' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="12px"
                      whiteSpace="nowrap"
                      style={hiddenLabels['90 minutes'] ? { textDecoration: 'line-through' } : {}}
                    >
                      90 Min
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid container alignItems="flex-start" direction="column">
              <Grid item display="flex" gap={3} justifyContent="space-between" width="100%">
                <Grid item alignItems="center" gap={2} display="flex">
                  <Typography variant="h4" color="#1A3356" fontWeight="600" fontSize="18px">
                    {widgetData.widgetNickName}
                  </Typography>
                  <ToolTip sx={styles.showIconStyle} title={widgetData.widgetDescription}>
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: '#80858CD9',
                        alignSelf: 'center',
                        marginLeft: '10px !important',
                        ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                      }}
                    />
                  </ToolTip>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  gap={3}
                  sx={isHovered ? styles.visibleComponent : styles.hiddenComponent}
                >
                  <ToolTip title="Download CSV">
                    <img
                      src={DownloadIcon}
                      onClick={() => {
                        if (selectedTypeGraph === 'Sludge Settling Pattern') {
                          downloadCSV(
                            flocData[currentShiftIndex]?.shiftName,
                            getDayMonthYear(flocData[currentShiftIndex]?.startTime),
                            processData(flocData[currentShiftIndex]?.samples[currentSampleIndex])
                          );
                        } else if (selectedTypeGraph === 'Sludge Value') {
                          downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </ToolTip>
                  <ToolTip title="Expanded View">
                    <img
                      src={OpenNewTab}
                      style={{ cursor: 'pointer' }}
                      onClick={handleClickOpenExpandedView}
                    />
                  </ToolTip>
                  <ToolTip title="Edit Widget">
                    <ModeEditOutlineIcon
                      fontSize="small"
                      sx={{
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                        color: '#80858CD9',
                      }}
                      onClick={() => {
                        handleEditClick('flocDetector');
                      }}
                    />
                  </ToolTip>
                </Grid>
              </Grid>
              <Grid item display="flex" gap={1} mb={1} mt={1}>
                <Grid item display="flex" gap={1}>
                  {showClock == 'on' && (
                    <Stack direction="row" spacing={1} mb={1}>
                      <ClockIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: '#5cb85c	',
                        }}
                        onClick={() => handleClockClick('disabled')}
                      />
                      <span style={{ fontSize: '13px', color: '#7D8188' }}>
                        {formatTimeRange(customTimeRangeSettings)}
                      </span>
                    </Stack>
                  )}
                  {showClock === 'disabled' && (
                    <Stack direction="row" spacing={1} mb={1}>
                      <ClockIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                        }}
                        color="disabled"
                        onClick={() => handleClockClick('on')}
                      />
                      <span style={{ fontSize: '13px', color: '#7D8188' }}>
                        {formatTimeRange(customTimeRangeSettings)}
                      </span>
                    </Stack>
                  )}
                </Grid>
              </Grid>
              {flocData.length > 0 && selectedTypeGraph === 'Sludge Settling Pattern' && (
                <Grid
                  item
                  display="flex"
                  alignItems="start"
                  // gap={2}
                  flexDirection={isTablet ? 'column' : 'row'}
                  mb={2}
                  mt={1}
                >
                  <Grid item display="flex" alignItems="center">
                    <KeyboardArrowLeftIcon
                      sx={{ color: '#0000008F', cursor: 'pointer' }}
                      onClick={handlePrevious}
                    />
                    <Grid item display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon
                        fontSize="small"
                        sx={{ color: '#1A3356', height: '12px', width: '12px' }}
                      />
                      <Grid item>
                        <Typography color="#5C6066" fontWeight="500" fontSize="12px">
                          {getDayMonthYear(flocData[currentShiftIndex].startTime)}
                        </Typography>
                        <Typography color="#999" fontSize="10px">
                          {flocData[currentShiftIndex].shiftName} (
                          {convertISOToLocalTimeWithOffset(flocData[currentShiftIndex].startTime)} -{' '}
                          {convertISOToLocalTimeWithOffset(flocData[currentShiftIndex].endTime)})
                          |&nbsp;
                          {currentSampleIndex + 1} / {flocData[currentShiftIndex].samples?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    <KeyboardArrowRightIcon
                      sx={{ color: '#0000008F', cursor: 'pointer' }}
                      onClick={handleNext}
                    />
                  </Grid>
                </Grid>
              )}

              {selectedTypeGraph === 'Sludge Settling Pattern' && widgetData.showAllSample && (
                <Button
                  variant="outlined"
                  sx={{
                    color: '#3271A9',
                    border: '1px solid #3271A9',
                    fontSize: '10px',
                    fontWeight: '500',
                    marginBottom: '10px',
                    marginTop: '3px',
                  }}
                  disabled={flocData[currentShiftIndex]?.samples[currentSampleIndex]?.default}
                >
                  MAKE THIS DEFAULT
                </Button>
              )}
              <Grid item display="flex" alignItems="center" gap={3}>
                <ToolTip
                  title="Sludge Value"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: '0px',
                          },
                      },
                    },
                  }}
                >
                  <img
                    src={
                      selectedTypeGraph === 'Sludge Value'
                        ? SludgeVolumeIconColored
                        : SludgeVolumeIcon
                    }
                    onClick={() => handleSelectedGraphType('Sludge Value')}
                    style={{ cursor: 'pointer' }}
                  />
                </ToolTip>
                <ToolTip
                  title="Sludge Settling Pattern"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: '0px',
                          },
                      },
                    },
                  }}
                >
                  <img
                    src={
                      selectedTypeGraph === 'Sludge Settling Pattern'
                        ? GraphLineIconColored
                        : GraphLineIcon
                    }
                    onClick={() => handleSelectedGraphType('Sludge Settling Pattern')}
                    style={{ cursor: 'pointer' }}
                  />
                </ToolTip>
                {/* <img src={SludgeVolumeGroupIcon} /> */}
              </Grid>
              {selectedTypeGraph === 'Sludge Value' && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={3}
                  mt={1}
                >
                  <Grid item display="flex" gap={3}>
                    <Grid
                      item
                      display="flex"
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'falseDetectionIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={
                          issueIcon.falseDetectionIcon ? { textDecoration: 'line-through' } : {}
                        }
                      >
                        False Detection
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'floatingSludgeIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <img src={SludgeIcon} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={
                          issueIcon.floatingSludgeIcon ? { textDecoration: 'line-through' } : {}
                        }
                      >
                        Floating Sludge
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'noSludgeIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <img src={NoSludgeIcon} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={issueIcon.noSludgeIcon ? { textDecoration: 'line-through' } : {}}
                      >
                        No Sludge
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedTypeGraph === 'Sludge Value' && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  mt={1}
                  mb={2}
                >
                  <Grid item display="flex" gap={3}>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickMinutesIcon('30 minutes', setHiddenLabels);
                      }}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img src={minIcon30} style={{ width: '12px', height: '12px' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={hiddenLabels['30 minutes'] ? { textDecoration: 'line-through' } : {}}
                      >
                        30 Min
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickMinutesIcon('60 minutes', setHiddenLabels);
                      }}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img src={minIcon60} style={{ width: '12px', height: '12px' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={hiddenLabels['60 minutes'] ? { textDecoration: 'line-through' } : {}}
                      >
                        60 Min
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickMinutesIcon('90 minutes', setHiddenLabels);
                      }}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img src={minIcon90} style={{ width: '12px', height: '12px' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={hiddenLabels['90 minutes'] ? { textDecoration: 'line-through' } : {}}
                      >
                        90 Min
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedTypeGraph === 'Sludge Settling Pattern' && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={3}
                  mt={3}
                >
                  <Grid
                    item
                    display="flex"
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'falseDetection',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.falseDetection ? { textDecoration: 'line-through' } : {}}
                    >
                      False Detection
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'issueIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={SludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.issueIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      Floating Sludge
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'noSludge',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={NoSludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.noSludge ? { textDecoration: 'line-through' } : {}}
                    >
                      No Sludge
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid container mt="1.5rem" ml={-2} sx={{ textAlign: 'center', border: '10px' }}>
          {selectedTypeGraph === 'Sludge Settling Pattern' && flocData.length === 0 && (
            <Typography sx={{ textAlign: 'center' }}>
              No data available for floc detector for this Date
            </Typography>
          )}

          {selectedTypeGraph === 'Sludge Settling Pattern' && flocData.length > 0 && (
            <LineChartFlocDetector
              imageCache={imageCache}
              flocData={flocData}
              currentShiftIndex={currentShiftIndex}
              currentSampleIndex={currentSampleIndex}
              handleClickOpen={handleClickOpen}
              visibleIcons={visibleIcons}
              setVisibleIcons={setVisibleIcons}
            />
          )}
          {selectedTypeGraph === 'Sludge Value' && (
            <StackedBarChartFlocDetector
              handleClickOpen={handleClickOpen}
              issueIcon={issueIcon}
              hiddenLabels={hiddenLabels}
              options={{
                plugins: {
                  zoom: {
                    pan: {
                      enabled: false, // Disable panning
                    },
                    zoom: {
                      wheel: {
                        enabled: false, // Disable zooming
                      },
                      pinch: {
                        enabled: false, // Disable pinch zooming
                      },
                    },
                  },
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <ImageDialogueBox
        open={open}
        handleClose={handleClose}
        imageDetails={imageDetails}
        handleClick={handleClick}
        anchorEl={anchorEl}
        openComment={openComment}
        handleCloseComment={handleCloseComment}
        allComments={allComments}
      />
      <CommentDialogueBox
        openCommentDialogue={openCommentDialogue}
        handleCloseCommentDialogueOutside={handleCloseCommentDialogueOutside}
        commentDialogueHeaderText={commentDialogueHeaderText}
        handleCloseCommentDialogue={handleCloseCommentDialogue}
        handleOnChangeComment={handleOnChangeComment}
        addComment={addComment}
        errorTitle={errorTitle}
        setErrorTitle={setErrorTitle}
        errorComment={errorComment}
        setErrorComment={setErrorComment}
        handleAddComment={handleAddComment}
      />
      <ExpandedViewFlocDetectorWidget
        openExpandedView={openExpandedView}
        setOpenExpandedView={setOpenExpandedView}
        handleClickOpenExpandedView={handleClickOpenExpandedView}
        handleCloseExpandedView={handleCloseExpandedView}
        widgetData={widgetData}
        resetZoom={resetZoom}
        setResetZoom={setResetZoom}
        showLinkIcon={showLinkIcon}
        linkOnWidget={linkOnWidget}
        selectedTypeGraph={selectedTypeGraph}
        imageCache={imageCache}
        flocData={flocData}
        barGraphData={barGraphData}
        currentShiftIndex={currentShiftIndex}
        currentSampleIndex={currentSampleIndex}
        handleClickOpen={handleClickOpen}
        visibleIcons={visibleIcons}
        setVisibleIcons={setVisibleIcons}
        issueIcon={issueIcon}
        hiddenLabels={hiddenLabels}
      />
    </>
  );
};

const ExpandedViewFlocDetectorWidget = (props: any) => {
  const {
    openExpandedView,
    handleCloseExpandedView,
    widgetData,
    resetZoom,
    setResetZoom,
    showLinkIcon,
    linkOnWidget,
    barGraphData,
    selectedTypeGraph,
    imageCache,
    flocData,
    currentShiftIndex,
    currentSampleIndex,
    handleClickOpen,
    visibleIcons,
    setVisibleIcons,
    issueIcon,
    hiddenLabels,
  } = props;
  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget;
  };
  return (
    <>
      <Dialog
        open={openExpandedView}
        onClose={handleCloseExpandedView}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '.MuiDialog-paper': {
            maxWidth: 'none',
            // padding: "0.5rem",
            borderRadius: '16px',
            height: selectedTypeGraph === 'Sludge Settling Pattern' ? '60vh' : 'auto',
            width: selectedTypeGraph === 'Sludge Settling Pattern' ? '180vh' : 'auto',
            overflow: 'hidden', // Prevent scrollbars
            display: 'flex', // Use flexbox to align content
            flexDirection: 'column', // Stack children vertically
          },

          '.MuiDialog-paper > *': {
            flex: '1 1 auto', // Allow children to grow and shrink to fit
            overflow: 'hidden', // Ensure child content does not overflow
            minHeight: '0', // Allow child elements to collapse correctly
          },
        }}
      >
        <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
          <Stack direction="row" alignItems="center">
            <ToolTip title={widgetData.widgetNickName || widgetData.widgetName}>
              <Typography variant="h6" sx={styles.textStyle}>
                {widgetData.widgetNickName || widgetData.widgetName}
              </Typography>
            </ToolTip>
            {widgetData?.plantName && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    borderRightWidth: 2,
                    color: '#7D8188',
                  }}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
                  >
                    {widgetData.plantName}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {
              <SecondaryButton
                sx={{ fontSize: '12px' }}
                onClick={() => {
                  // if (widgetData.graphType) lineChartRef?.current?.resetZoom();
                  // else barChartRef?.current?.resetZoom();
                  setResetZoom(!resetZoom);
                }}
              >
                Reset Zoom
              </SecondaryButton>
            }
            {showLinkIcon && (
              <ToolTip title="Click to know more" style={{ padding: '0' }}>
                <img
                  src={linkIcon}
                  style={{ width: '14px', height: '14px' }}
                  onClick={() => {
                    clickedOnlinkIcon();
                  }}
                ></img>
              </ToolTip>
            )}
            <ToolTip title={widgetData.widgetDescription}>
              <InfoOutlinedIcon fontSize="small" style={{ color: '#98A6B8' }} />
            </ToolTip>
            <ToolTip title="Close window">
              <CloseFullscreenIcon sx={{ fontSize: '16px' }} onClick={handleCloseExpandedView} />
            </ToolTip>
          </Stack>
        </Box>
        {selectedTypeGraph === 'Sludge Value' && (
          <StackedBarChartFlocDetector
            handleClickOpen={handleClickOpen}
            issueIcon={issueIcon}
            hiddenLabels={hiddenLabels}
            // barGraphData={barGraphData}
            options={{
              responsive: true,
              plugins: {
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: 'xy',
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true,
                  min: 0, // Set minimum value for y-axis
                  max: 2000, // Set maximum value for y-axis
                  ticks: {
                    callback: function (value) {
                      return `${value} ml`;
                    },
                  },
                },
                x: {
                  stacked: true,
                  min: 'original', // Preserve original min value for x-axis
                  max: 'original', // Preserve original max value for x-axis
                  ticks: {
                    autoSkip: false,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        )}
        {selectedTypeGraph === 'Sludge Settling Pattern' && (
          <LineChartFlocDetector
            imageCache={imageCache}
            flocData={flocData}
            currentShiftIndex={currentShiftIndex}
            currentSampleIndex={currentSampleIndex}
            handleClickOpen={handleClickOpen}
            visibleIcons={visibleIcons}
            setVisibleIcons={setVisibleIcons}
            options={{
              responsive: true,
              plugins: {
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: 'xy',
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true,
                  min: 0, // Set minimum value for y-axis
                  max: 2000, // Set maximum value for y-axis
                  ticks: {
                    callback: function (value: any) {
                      return `${value} ml`;
                    },
                  },
                },
                x: {
                  stacked: true,
                  min: 'original', // Preserve original min value for x-axis
                  max: 'original', // Preserve original max value for x-axis
                  ticks: {
                    autoSkip: false,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default FlocDetectorVisualizationChart;
