import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
// import expandMoreIcon from "../../../constants/Images/expandMoreIcon.svg";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Skeleton from '@mui/material/Skeleton';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  widgetDataInterface,
  widgetStructureInterface,
} from '../../../Interfaces/widgetInterfaces';
import { handleDownloadCSV } from '../../../utilities/helpers';
import ChartIcon from '../../../assets/icons/ChartIcon.svg';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import linkIcon from '../../../constants/Images/linkIcon.svg';
import ExpandIcon from '../../../assets/icons/ExpandIcon.svg';
import DownloadIcon from '../../../assets/icons/DownloadCSV.svg';
import ClockIcon from '@mui/icons-material/WatchLater';
import FactoryIcon from '@mui/icons-material/Factory';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { FONT_NAME } from '../../../core-components/theme/theme';

const styles = {
  graphWidgetLayout: {
    width: 'inherit',
    // maxWidth : "700px",
    height: 'inherit',
    overflowY: 'hidden',
    background: 'white',
    display: 'grid',
    gridTemplateRows: '50px 1fr',
    borderRadius: '16px',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '12px',
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    padding: '5px',
    color: '#193458',
    fontFace: `${FONT_NAME}`,
    fontWeight: '500',
    overflow: 'hidden',
    fontSize: 'clamp(16px,1.1vw,25px)',
    height: '30px',
  },
  tableViewClient: {
    overflowY: 'scroll',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  showIconStyle: {
    visibility: 'visible',
  },
  hideIconStyle: {
    visibility: 'hidden',
  },
  truncateTextStyle: {
    display: 'inline-block',
    maxWidth: '120px', // Adjust this value to your desired width
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  textStyle: {
    fontSize: '1rem',
    cursor: 'pointer',
  },
  rowView: {
    display: 'flex',
    flexDirection: 'row',
    height: 'inherit',
    width: 'inherit',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '5px',
    background: '#f5f5f5',
  },

  accordionView: {
    // justifyContent: 'space-between',
    // padding: '10px',
    height: 'inherit',
    width: 'inherit',
    borderRadius: '5px',
    background: '#f5f5f5',
    border: 'none !important',
    borderTop: '#fff',
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.MuiAccordion-root:after': {
      display: 'none',
    },
  },
};

interface genWidgetHeaderPropInterface {
  setExpandedViewPopup: Dispatch<SetStateAction<boolean>>;
  widgetData: widgetStructureInterface | widgetDataInterface;
  showLinkIcon: boolean | undefined;
  linkOnWidget: string | undefined;
}

// interface genTableCpropInterface {
//   widgetData: widgetDataInterface | null;
//   widgetStructure: widgetStructureInterface;
//   setExpandedViewPopup: Dispatch<SetStateAction<boolean>>;
//   isLoading: boolean;
//   headerType: "normal" | "expanded";
//   showLinkIcon?: boolean;
//   linkOnWidget?: string;
// }

export default function TableCWidget(props: any) {
  const {
    // widgetStructure,
    widgetData,
    setExpandedViewPopup,
    isLoading,
    headerType,
    showLinkIcon,
    linkOnWidget,
    setSelectedChart,
    // type,
    handleEditClick,
    selectChart,
    showClock,
    setShowClock,
    customTimeRangeSettings,
    handleClockClick,
    customGranularity,
    isBottomBarOpen,
    setIsBottomBarOpen,
    setPreviousSelectedChart,
  } = props;

  const [showIcon, setShowIcon] = useState(false);
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Box p={1.5} sx={{ ...styles.graphWidgetLayout }}>
        {headerType === 'normal' ? (
          <GeneralisedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            setPreviousSelectedChart={setPreviousSelectedChart}
          />
        ) : (
          <GeneralisedExpandedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        )}
        <Box
          style={{
            display: 'grid',
            placeItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Skeleton variant="rectangular" style={{ width: 'inherit', height: 'inherit' }} />
        </Box>
      </Box>
    );
  } else if (widgetData)
    return (
      <Box
        p={1.5}
        sx={{ ...styles.graphWidgetLayout }}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        {headerType === 'normal' ? (
          <GeneralisedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            setSelectedChart={setSelectedChart}
            selectChart={selectChart}
            showIcon={showIcon}
            handleEditClick={handleEditClick}
            showClock={showClock}
            setShowClock={setShowClock}
            customTimeRangeSettings={customTimeRangeSettings}
            handleClockClick={handleClockClick}
            customGranularity={customGranularity}
            isBottomBarOpen={isBottomBarOpen}
            setIsBottomBarOpen={setIsBottomBarOpen}
            setPreviousSelectedChart={setPreviousSelectedChart}
          />
        ) : (
          <GeneralisedExpandedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        )}
        <Box sx={{ ...styles.tableViewClient }}>
          {widgetData?.metricsData?.datasets?.map((data: any) => {
            if (data.accordionData)
              return (
                <Accordion disableGutters sx={styles.accordionView} elevation={0}>
                  <AccordionSummary>
                    <TableRowSummary data={data} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="column">
                      <Stack direction="column" mt={1} gap={1}>
                        {data.accordionData.listData?.map((row: any, rowIndex: number) => {
                          return (
                            <Box
                              sx={{
                                fontSize: 'clamp(13px,0.7vw,19px)',
                                fontWeight: 500,
                              }}
                            >
                              {`${rowIndex + 1}. ${row.text}` || 'No Data'}
                            </Box>
                          );
                        })}
                      </Stack>
                      {data.accordionData?.link && (
                        <Box
                          sx={{
                            alignSelf: 'center',
                            fontSize: 'clamp(13px,0.7vw,19px)',
                            background: '#88B5DC',
                            textDecoration: 'none',
                            borderRadius: '10px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                          px={2}
                          py={1}
                          mt={3}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            navigate(`${data.accordionData.link}`);
                          }}
                        >
                          {data.accordionData.listData?.length > 1 ? 'Show More' : 'Open Tickets'}
                        </Box>
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              );
            else return <TableRow data={data} />;
          })}
        </Box>
      </Box>
    );
  else return null;
}

const TooltipIcon = ({ title, src, style, onClick }: any) => (
  <Tooltip title={title}>
    <img src={src} style={{ ...style, cursor: 'pointer' }} onClick={onClick} />
  </Tooltip>
);

const MenuOption = ({ label, onClick }: any) => <MenuItem onClick={onClick}>{label}</MenuItem>;

function GeneralisedWidgetHeader(props: any) {
  const {
    setExpandedViewPopup,
    widgetData,
    showLinkIcon,
    linkOnWidget,
    setSelectedChart,
    showIcon,
    handleEditClick,
    showClock,
    selectChart,
    customGranularity,
    customTimeRangeSettings,
    handleClockClick,
    setIsBottomBarOpen,
    setPreviousSelectedChart,
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const navigateToLink = () => (window.location.href = linkOnWidget!);
  const closeMenu = () => setAnchorEl(null);

  const selectChartFunc = (chartType: any) => {
    if (setSelectedChart) {
      setPreviousSelectedChart(selectChart);
      setSelectedChart(chartType);
    }
    closeMenu();
  };

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const formatTimeRange = () => {
    if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
      const startTime = new Date(customTimeRangeSettings.startTime);
      const endTime = new Date(customTimeRangeSettings.endTime);
      return `${formatDateTime(startTime)} - ${formatDateTime(endTime)} | ${capitalizeFirstLetter(
        customGranularity?.unit || ''
      )}`;
    }
    return '';
  };

  const formatDateTime = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
    const timeString = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${dateString} ${timeString}`;
  };

  return (
    <Stack>
      <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
        <Stack direction="row" spacing={0.6}>
          <Stack direction="row" alignItems="center">
            <Tooltip title={widgetData.widgetNickName || widgetData.widgetName}>
              <Typography variant="h6" sx={styles.textStyle}>
                {widgetData.widgetNickName || widgetData.widgetName}
              </Typography>
            </Tooltip>
            {!showIcon && widgetData?.plantName && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginLeft: '6px',
                    marginRight: '6px',
                    borderRightWidth: 1.8,
                    color: '#7D8188',
                  }}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
                  >
                    {widgetData.plantName}
                  </Typography>
                </Stack>
              </>
            )}
            {showIcon && widgetData?.plantName && (
              <>
                <Tooltip sx={styles.showIconStyle} title={widgetData.plantName}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                      marginLeft: '8px !important',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Stack>
          {showIcon && (
            <>
              <Tooltip sx={styles.showIconStyle} title={widgetData.widgetDescription}>
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{
                    width: '18px',
                    height: '18px',
                    cursor: 'pointer',
                    color: '#80858CD9',
                    alignSelf: 'center',
                  }}
                />
              </Tooltip>
            </>
          )}
        </Stack>
        {showIcon && (
          <Stack
            direction="row"
            spacing={2}
            sx={showIcon ? styles.showIconStyle : styles.hideIconStyle}
          >
            <TooltipIcon
              title="Download CSV"
              src={DownloadIcon}
              style={{ width: '14px', height: '14px' }}
              onClick={() => handleDownloadCSV(widgetData?.metricsData)}
            />
            {widgetData.showHeaderSettings &&
            <TooltipIcon
              title="Expanded View"
              src={ExpandIcon}
              style={{ width: '14px', height: '14px' }}
              onClick={() => setExpandedViewPopup(true)}
            />
}
            {widgetData.showHeaderSettings && (
              <TooltipIcon
                title="Choose chart"
                src={ChartIcon}
                style={{ width: '14px', height: '14px' }}
                onClick={(event: any) => {
                  setIsBottomBarOpen(false);
                  setAnchorEl(event.currentTarget);
                }}
              />
            )}

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
              {selectChart === 'tableC' ? null : (
                <MenuOption label="Table" onClick={() => selectChartFunc('tableC')} />
              )}
              {selectChart === 'bar' ? null : (
                <MenuOption label="Bar Graph" onClick={() => selectChartFunc('bar')} />
              )}
              {selectChart === 'line' ? null : (
                <MenuOption label="Line Graph" onClick={() => selectChartFunc('line')} />
              )}
            </Menu>

            {showLinkIcon && (
              <TooltipIcon
                title="Click to know more"
                src={linkIcon}
                style={{ width: '14px', height: '14px' }}
                onClick={navigateToLink}
              />
            )}

            {widgetData.showHeaderSettings && (
              <Tooltip title="Edit Widget">
                <ModeEditOutlineIcon
                  fontSize="small"
                  sx={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    color: '#80858CD9',
                  }}
                  onClick={handleEditClick}
                />
              </Tooltip>
            )}
          </Stack>
        )}
      </Box>

      {showClock == 'on' && widgetData.showHeaderSettings && (
        <Stack direction="row" spacing={1} mb={1}>
          <ClockIcon
            sx={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
              color: '#5cb85c	',
              marginBottom: '8px',
            }}
            onClick={() => handleClockClick('disabled')}
          />
          <span style={{ fontSize: '13px', color: '#7D8188' }}>{formatTimeRange()}</span>
        </Stack>
      )}
      {showClock === 'disabled' && widgetData.showHeaderSettings && (
        <Stack direction="row" spacing={1} mb={1}>
          <ClockIcon
            sx={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
            }}
            color="disabled"
            onClick={() => handleClockClick('on')}
          />
          <span style={{ fontSize: '13px', color: '#7D8188' }}>{formatTimeRange()}</span>
        </Stack>
      )}
    </Stack>
  );
}

function GeneralisedExpandedWidgetHeader(props: genWidgetHeaderPropInterface) {
  const { setExpandedViewPopup, widgetData, showLinkIcon, linkOnWidget } = props;

  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget!;
  };
  return (
    <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
      <Stack direction="row" alignItems="center">
        <Tooltip title={widgetData.widgetNickName || widgetData.widgetName}>
          <Typography variant="h6" sx={styles.textStyle}>
            {widgetData.widgetNickName || widgetData.widgetName}
          </Typography>
        </Tooltip>
        {widgetData?.plantName && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginLeft: '8px',
                marginRight: '8px',
                borderRightWidth: 2,
                color: '#7D8188',
              }}
            />
            <Stack direction="row" alignItems="center" gap={1}>
              <FactoryIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: '#80858CD9',
                  alignSelf: 'center',
                }}
              />
              <Typography
                variant="h6"
                style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
              >
                {widgetData.plantName}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {showLinkIcon && (
          <Tooltip title="Click to know more" style={{ padding: '0' }}>
            <img
              src={linkIcon}
              style={{ width: '18px', height: '18px' }}
              onClick={() => {
                clickedOnlinkIcon();
              }}
            ></img>
          </Tooltip>
        )}
        <Tooltip title={widgetData.widgetDescription}>
          <InfoOutlinedIcon fontSize="small" style={{ color: '#98A6B8' }} />
        </Tooltip>
        <Tooltip title="Close window">
          <CloseFullscreenIcon
            sx={{ fontSize: '16px' }}
            onClick={() => {
              setExpandedViewPopup(false);
            }}
          />
        </Tooltip>
      </Stack>
    </Box>
  );
}

function TableRow(props: any) {
  const { data } = props;
  return (
    <Box sx={styles.rowView}>
      <span
        style={{
          fontSize: 'clamp(13px,0.7vw,19px)',
        }}
      >
        {data.label}
      </span>
      {data.data[0] != null ? (
        <span style={{ fontSize: 'clamp(13px,0.7vw,19px)' }}>{data.data[0]}</span>
      ) : (
        <span style={{ fontSize: 'clamp(13px,0.7vw,19px)' }}>{'No Data Available'}</span>
      )}
    </Box>
  );
}

function TableRowSummary(props: any) {
  const { data } = props;
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
      <Stack direction="row" gap={1} alignItems="center">
        <span
          style={{
            fontSize: 'clamp(13px,0.7vw,19px)',
          }}
        >
          {data.label}
        </span>
        <ExpandMoreIcon sx={{ fontSize: 'clamp(17px,1vw,25px)', color: '#999' }} />
      </Stack>
      {data.data[0] != null ? (
        <span style={{ fontSize: 'clamp(13px,0.7vw,19px)' }}>{data.data[0]}</span>
      ) : (
        <span style={{ fontSize: 'clamp(13px,0.7vw,19px)' }}>{'No Data Available'}</span>
      )}
    </Stack>
  );
}
