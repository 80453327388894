import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Avatar,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  ThemeProvider,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import dpTheme from '../styles/theme/dpTheme';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LayoutComponent from '../components/LayoutComponent';
import {
  getUserDetails,
  updatePassword,
  updateUserDetails,
  updateProfilePicture,
} from '../services/profile';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { CancelOutlined } from '@mui/icons-material';
import localStorageStore from '../store/localStorageStore';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { removeCachedLoginEntry } from '../utilities/cachedLoginEntries.utils';
import { FONT_NAME } from '../core-components/theme/theme';

// const checkboxStyle = {
//   color: "rgba(153, 153, 153, 0.3)",
//   marginLeft: "16px",
// };

const ResponsiveLayout = () => {
  const [avatarSize, setAvatarSize] = useState({
    width: 180,
    height: 180,
    font: '72px',
  });
  let navigate = useNavigate();
  const [username, setUsername] = useState('Digital Paani');
  const [changePasswordState, setChangePasswordState] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [loadImage, setLoadImage] = useState(false);
  const [updateUser, setUpateUser] = useState(true);

  const handleClickShowOldPassword = () => setShowOldPassword(show => !show);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const resizedImage = await resizeImage(file);
      if (resizedImage) {
        uploadImage(resizedImage);
      }
    }
  };

  const resizeImage = (file: File): Promise<Blob | null> => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const scale = 500 / img.width;
        canvas.width = 500;
        canvas.height = img.height * scale;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob);
          } else {
            resolve(null);
          }
        }, file.type);
      };
    });
  };

  const uploadImage = async (resizedImage: Blob) => {
    const formData = new FormData();
    formData.append('upload', resizedImage, 'profilePic');
    try {
      await updateProfilePicture(formData)
        .then((data: any) => {
          if (data.status === 200) {
            toast.success('Profile picture updated successfully');
            setUpateUser(!updateUser);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (error) {
      console.error('There was an error uploading the image:', error);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setLoadImage(true);
      }
    });

    const avatar = document.getElementById('lazy-avatar');
    if (avatar) {
      observer.observe(avatar);
    }

    return () => {
      if (avatar) {
        observer.unobserve(avatar);
      }
    };
  }, []);

  const handleMouseDownOldPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword(show => !show);

  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  interface ChangePasswordForm {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }

  let changePasswordFormSchema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup.string().required('New password is required'),
    confirmPassword: yup.string().required('Please confirm new password'),
  });

  const changePassworfForm = useForm<ChangePasswordForm>({
    resolver: yupResolver(changePasswordFormSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = changePassworfForm;
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const onSubmit = (formData: ChangePasswordForm) => {
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    } else if (formData.oldPassword === formData.newPassword) {
      toast.error('New password cannot be same as old password');
      return;
    } else {
      setChangePasswordLoading(true);
      updatePassword(formData.oldPassword, formData.newPassword, formData.confirmPassword).then(
        (data: any) => {
          setChangePasswordLoading(false);
          if (data) {
            if (data.success) {
              toast.success('Password updated successfully');
              setChangePasswordState(false);
              if (!keepLoggedIn) {
                removeCachedLoginEntry(userData?.id);
                localStorageStore.logout();
                navigate('/LoginV2');
              }
            } else {
              toast.error(data.message);
            }
          } else {
            toast.error('Something went wrong while updating password');
          }
        }
      );
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleWidthChange = () => {
    if (windowWidth <= 600) {
      handleAvatarSize(true);
    } else {
      handleAvatarSize(false);
    }
  };
  useEffect(() => {
    handleWidthChange();
  }, [windowWidth]);

  useEffect(() => {
    getUserDetails().then((data: any) => {
      if (data) {
        setUserData(data.data.user);
        if (data.data.user.name) {
          setUsername(data.data.user.name);
        }
      } else {
        toast.error('Something went wrong while fetching user details. Please contact admin !');
      }
    });
  }, [updateUser]);
  const changePasswordClick = () => {
    setChangePasswordState(!changePasswordState);
  };

  const handleAvatarSize = (isMobile: boolean) => {
    setAvatarSize(
      isMobile
        ? { width: 120, height: 120, font: '48px' }
        : { width: 180, height: 180, font: '72px' }
    );
  };

  const handleLogout = () => {
    localStorageStore.logout();
    navigate('/LoginV2');
  };

  async function handleDataUpdate() {
    if (validateEmail(userData.email) && validatePhoneNumber(userData.number)) {
      updateUserDetails(userData.email, userData.language, userData.name, userData.number).then(
        (data: any) => {
          if (data.success) {
            toast.success('User details updated successfully');
            let unifiedDashboardPage = localStorage.getItem('landingPage');
            navigate(`${unifiedDashboardPage}`);
          } else {
            toast.error(data.message);
          }
        }
      );
    }
  }
  function validateEmail(email: any) {
    var re = /\S+@\S+\.\S+/;
    if (re.test(email)) {
      return true;
    } else {
      toast.error('Please enter a valid email address');
      return false;
    }
  }
  function validatePhoneNumber(number: string) {
    if (number.length === 10) {
      let isnum = /^\d+$/.test(number);
      if (isnum) {
        return true;
      } else {
        toast.error('Please enter a valid phone number');
        return false;
      }
    } else {
      toast.error('Please enter a valid phone number');
      return false;
    }
  }

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <Grid
          style={{
            marginTop: '15vh',
          }}
          container
          spacing={2}
        >
          {/* Column 1 */}
          <Grid
            item
            xs={12}
            sm={changePasswordState ? 4 : 6}
            style={{
              borderRight: '1px solid #E0E0E0',
              // paddingRight: "16px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  id="lazy-avatar"
                  style={{
                    width: avatarSize.width,
                    height: avatarSize.height,
                    fontSize: avatarSize.font,
                    background: '#183650',
                    fontFamily: `${FONT_NAME}`,
                    fontWeight: 400,
                  }}
                  src={loadImage ? userData.profilePic : undefined}
                >
                  {username
                    .split(' ')
                    .map(name => name[0])
                    .join('')}
                </Avatar>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{
                    color: '#183650',
                    borderColor: '#183650',
                    marginTop: '30px',
                    marginBottom: '30px',
                  }}
                  onClick={handleButtonClick}
                >
                  Change Picture
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          <Grid
            item
            xs={12}
            sm={changePasswordState ? 4 : 6}
            style={{
              borderRight: changePasswordState ? '1px solid #E0E0E0' : 'none',
              // paddingLeft: "16px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <TextField
                  label="Name"
                  style={{ width: '280px' }}
                  variant="outlined"
                  value={userData.name}
                  onChange={e => {
                    setUserData({ ...userData, name: e.target.value });
                  }}
                  InputProps={{
                    endAdornment: (
                      <EditIcon
                        style={{
                          height: '18px',
                          width: '18px',
                          color: '#999999',
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{ shrink: !!userData.name }}
                />
              </Grid>
              {userData.defaultPage ? (
                <Grid item>
                  <FormControl variant="outlined" style={{ width: '280px', marginTop: '20px' }}>
                    <InputLabel>Change Dashboard</InputLabel>
                    <Select label="Change Dashboard">
                      <MenuItem value={1}>Dashboard 1</MenuItem>
                      <MenuItem value={2}>Dashboard 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <></>
              )}

              <Grid item>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  disabled
                  value={userData.number}
                  style={{ width: '280px', marginTop: '20px' }}
                  InputProps={{
                    endAdornment: (
                      <EditIcon
                        style={{
                          height: '18px',
                          width: '18px',
                          color: '#999999',
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{ shrink: !!userData.number }}
                  onChange={e => {
                    setUserData({ ...userData, number: e.target.value });
                  }}
                />
                {/* <FormControlLabel
                  control={<Checkbox style={checkboxStyle} />}
                  style={{
                    marginLeft: "16px",
                    color: "rgba(153, 153, 153, 0.3)",
                    marginTop: "23px",
                  }}
                  label="2FA"
                  labelPlacement="end"
                /> */}
              </Grid>
              <Grid item>
                <TextField
                  label="Email Id"
                  style={{ width: '280px', marginTop: '20px' }}
                  variant="outlined"
                  disabled
                  value={userData.email}
                  InputProps={{
                    endAdornment: (
                      <EditIcon
                        style={{
                          height: '18px',
                          width: '18px',
                          color: '#999999',
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{ shrink: !!userData.email }}
                  onChange={e => {
                    setUserData({ ...userData, email: e.target.value });
                  }}
                />
              </Grid>
              <Grid item>
                <FormControl variant="outlined" style={{ width: '280px', marginTop: '20px' }}>
                  <InputLabel>Select Language</InputLabel>
                  <Select
                    label="Select Language"
                    value={userData && userData.language ? userData.language : 'English'}
                    onChange={e => {
                      setUserData({ ...userData, language: e.target.value });
                    }}
                  >
                    <MenuItem value={'English'}>English</MenuItem>
                    <MenuItem value={'Hindi'}>Hindi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    width: '280px',
                    marginTop: '20px',
                    height: '37px',
                    color: '#183650',
                    borderColor: '#183650',
                  }}
                  variant="outlined"
                  onClick={changePasswordClick}
                  endIcon={
                    changePasswordState ? <CancelOutlined /> : <ArrowCircleRightOutlinedIcon />
                  }
                >
                  {changePasswordState ? 'Cancel' : 'Change Password'}
                </Button>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  disabled={changePasswordState}
                  style={{ width: '280px', marginTop: '20px' }}
                  variant="contained"
                  onClick={() => {
                    handleDataUpdate();
                  }}
                >
                  Save
                </Button>
                <Typography
                  variant="body2"
                  style={{
                    color: '#3DA3F4',
                    marginTop: '10px',
                    marginBottom: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={handleLogout}
                >
                  <u>Log Out</u>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Column 3 */}
          {changePasswordState ? (
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                // paddingLeft: "16px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container direction="column" alignItems="center" spacing={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid item>
                    <>
                      <FormControl sx={{ width: '280px' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Old password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showOldPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                                edge="end"
                              >
                                {showOldPassword ? (
                                  <VisibilityOff
                                    style={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#999999',
                                    }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#999999',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          // value={oldPassword}
                          error={errors.oldPassword ? true : false}
                          {...register('oldPassword')}
                        />
                        {errors.oldPassword && (
                          <FormHelperText error>{errors.oldPassword.message}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                  </Grid>
                  <Grid item>
                    <>
                      <FormControl sx={{ width: '280px', marginTop: '20px' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showNewPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownNewPassword}
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <VisibilityOff
                                    style={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#999999',
                                    }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#999999',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          // value={newPassword}
                          error={errors.newPassword ? true : false}
                          {...register('newPassword')}
                        />
                        {errors.newPassword && (
                          <FormHelperText error>{errors.newPassword.message}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                  </Grid>
                  <Grid item>
                    <>
                      <FormControl sx={{ width: '280px', marginTop: '20px' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirm password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showConfirmPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff
                                    style={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#999999',
                                    }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#999999',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          // value={confirmPassword}
                          error={errors.confirmPassword ? true : false}
                          {...register('confirmPassword')}
                        />
                        {errors.confirmPassword && (
                          <FormHelperText error>{errors.confirmPassword.message}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={keepLoggedIn}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setKeepLoggedIn(e.target.checked);
                          }}
                        />
                      }
                      style={{
                        marginLeft: '16px',
                        // color: "rgba(153, 153, 153, 0.3)",
                        marginTop: '23px',
                      }}
                      label="Keep me logged in"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      style={{ width: '280px', marginTop: '20px' }}
                      variant="contained"
                    >
                      Confirm{'  '}
                      {changePasswordLoading ? (
                        <CircularProgress size={20} sx={{ color: 'inherit', marginLeft: '10px' }} />
                      ) : null}
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </LayoutComponent>
    </ThemeProvider>
  );
};

export default ResponsiveLayout;
