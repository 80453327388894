import { createTheme } from '@mui/material/styles';
import { FONT_NAME } from '../../core-components/theme/theme';
interface PaletteColor {
  main: string;
  light?: string;
  dark?: string;
  contrastText?: string;
}

interface CustomPalette {
  primary: PaletteColor;
  secondary: PaletteColor;
  error: PaletteColor;
  info: PaletteColor;
  secondaryInfo: PaletteColor;
  success: PaletteColor;
  primaryText: PaletteColor;
  secondaryText: PaletteColor;
  primaryTitle: PaletteColor;
  disabled: PaletteColor;
  icons: PaletteColor;
}

const customPalette: CustomPalette = {
  primary: {
    main: '#36A2C7',
    light: '#36a2c7c2',
    dark: '#0093c5',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#193458',
    light: '#36A2C7',
  },
  error: {
    main: '#DE350B',
    dark: '#bf2600',
    light: '#FFEBE6',
  },
  info: {
    main: '#f27f0c',
    light: '#f7ad19',
  },
  secondaryInfo: {
    main: '#0BA4D4',
  },
  success: {
    main: '#ABF5D1',
    dark: '#086639',
  },
  primaryText: {
    main: '#000000',
  },
  secondaryText: {
    main: '#ffffff',
  },
  primaryTitle: {
    main: '#000000',
  },
  disabled: {
    main: '#dddddd',
    dark: '#999999',
  },
  icons: {
    main: '#000000',
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const typography = {
  fontFamily: `${FONT_NAME}`,
  h1: {
    fontWeight: 600,
    fontSize: '35px',
    lineHeight: 1.2,
    letterSpacing: '0.8px',
  },
  h2: {
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: 1.2,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: 1.2,
    letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 1.2,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 1.2,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.2,
    letterSpacing: '-0.05px',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.3px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
  },
  body1: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '-0.05px',
  },
  body2: {
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: '-0.05px',
  },
  caption: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },
};

const spacing = [0, 4, 8, 16, 32, 64];

const dpTheme: any = createTheme({
  // @ts-ignore
  palette: customPalette,
  breakpoints,
  typography,
  spacing,
});

export default dpTheme;
