//@ts-nocheck
import {
  CircularProgress,
  FormControlLabel,
  Menu,
  Switch,
  Tooltip,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TabPanel from "./TabPanel";
import NotificationCard from "./NotificationCard";
import { StyledTabs } from "./StyledTabs";
import { StyledBadge } from "./StyledBadge";
import { StyledTab } from "./StyledTab";
import { notificationInterface } from "../../Interfaces/notificationInterfaces";
import {
  clearAllUnreadNotifications,
  getNotifications,
  getCriticalNotifications,
  getNormalNotifications,
  markTheNotificationAsRead,
} from "../../services/notification";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { FONT_NAME } from "../../core-components/theme/theme";

export default function NotificationPopup(props: {
  isMenu2Open: boolean;
  handleMenu2Close: Function;
  plantId: string;
  notificationCount: {
    all: number;
    critical: number;
    normal: number;
  };
  fetchUnreadNotificationCount: Function;
}) {
  const {
    isMenu2Open,
    handleMenu2Close,
    plantId,
    notificationCount,
    fetchUnreadNotificationCount,
  } = props;
  const notificationsMenuId = "account-notifications";
  const [notifications, setNotifications] = useState<notificationInterface[]>(
    []
  );
  const [criticalNotifications, setCriticalNotifications] = useState<
    notificationInterface[]
  >([]);
  const [normalNotifications, setNormalNotifications] = useState<
    notificationInterface[]
  >([]);
  const [value, setValue] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [unread, setUnread] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [allListEmpty, setAllListEmpty] = useState(false);
  const [criticalListEmpty, setCriticalListEmpty] = useState(false);
  const [normalListEmpty, setNormalListEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCriticalLoading, setIsCriticalLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [criticalHasNextPage, setCriticalHasNextPage] = useState(false);
  const [normalHasNextPage, setNormalHasNextPage] = useState(false);
  const [isNormalLoading, setIsNormalLoading] = useState(false);

  useEffect(() => {
    loadMoreAllNotifications();
    loadMoreCriticalNotifications();
    loadMoreNormalNotifications();
  }, []);

  const loadMoreCriticalNotifications = async () => {
    setIsCriticalLoading(true);
    let criticalNotificationsList = await fetchCriticalNotification(
      plantId,
      criticalNotifications.length,
      criticalNotifications.length + 25
    );
    if (criticalNotificationsList && criticalNotificationsList.length > 0) {
      if (criticalNotifications.length !== 0) {
        setCriticalNotifications([
          ...criticalNotifications,
          ...criticalNotificationsList,
        ]);
      } else {
        setCriticalNotifications([...criticalNotificationsList]);
      }
    }
    if (criticalNotificationsList && criticalNotificationsList.length > 0) {
      setCriticalHasNextPage(true);
    } else {
      setCriticalHasNextPage(false);
    }
    setIsCriticalLoading(false);
  };

  const loadMoreNormalNotifications = async () => {
    setIsNormalLoading(true);
    let normalNotificationsList = await fetchNormalNotification(
      plantId,
      normalNotifications.length,
      normalNotifications.length + 25
    );
    if (normalNotificationsList && normalNotificationsList.length > 0) {
      if (normalNotifications.length !== 0) {
        setNormalNotifications([
          ...normalNotifications,
          ...normalNotificationsList,
        ]);
      } else {
        setNormalNotifications([...normalNotificationsList]);
      }
    }
    if (normalNotificationsList && normalNotificationsList.length > 0) {
      setNormalHasNextPage(true);
    } else {
      setNormalHasNextPage(false);
    }
    setIsNormalLoading(false);
  };

  const fetchNormalNotification = async (plantId, startIndex, endIndex) => {
    const fetchedNormalNotifications = await getNormalNotifications(
      plantId!,
      startIndex,
      endIndex
    );
    return fetchedNormalNotifications;
  };

  const fetchCriticalNotification = async (plantId, startIndex, endIndex) => {
    const fetchedCriticalNotifications = await getCriticalNotifications(
      plantId!,
      startIndex,
      endIndex
    );
    return fetchedCriticalNotifications;
  };

  const loadMoreAllNotifications = async () => {
    setLoading(true);
    let notificationsList = await fetchNotification(
      plantId!,
      notifications.length,
      notifications.length + 25
    );
    if (notificationsList && notificationsList.length > 0) {
      if (notifications.length !== 0) {
        setNotifications([...notifications, ...notificationsList]);
      } else {
        setNotifications([...notificationsList]);
      }
    }
    if (notificationsList && notificationsList.length > 0) {
      setHasNextPage(true);
    } else {
      setHasNextPage(false);
    }
    setLoading(false);
  };
  const fetchNotification = async (plantId, startIndex, endIndex) => {
    const fetchedNotifications = await getNotifications(
      plantId!,
      startIndex,
      endIndex
    );
    return fetchedNotifications;
  };

  const [sentryRef1, { rootRef1 }] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMoreAllNotifications,
    // disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });
  const [sentryRef2, { rootRef2 }] = useInfiniteScroll({
    loading: isCriticalLoading,
    hasNextPage: criticalHasNextPage,
    onLoadMore: loadMoreCriticalNotifications,
    // disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });
  const [sentryRef3, { rootRef3 }] = useInfiniteScroll({
    loading: isNormalLoading,
    hasNextPage: normalHasNextPage,
    onLoadMore: loadMoreNormalNotifications,
    // disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (notifications.length === 0) {
      setAllListEmpty(true);
    } else {
      setAllListEmpty(false);
    }
    if (criticalNotifications.length === 0) {
      setCriticalListEmpty(true);
    } else {
      setCriticalListEmpty(false);
    }
    if (normalNotifications.length === 0) {
      setNormalListEmpty(true);
    } else {
      setNormalListEmpty(false);
    }
    setIsLoading(false);
  }, [criticalNotifications, normalNotifications, notifications]);

  const handleChangeUnread = () => {
    setUnread(!unread);
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const markNoticatifactionasRead = async (
    plantId: String,
    id: String,
    to: String
  ) => {
    if (id.toString().length > 7) {
      markTheNotificationAsRead(plantId, id).then(({ data }) => {
        // if (to !== "stay on same page") history.push(to);
      });

      let tempNotifications = [...notifications];
      tempNotifications.forEach((notification) => {
        if (notification._id === id) notification.read = true;
      });
      setNotifications(tempNotifications);
      await fetchUnreadNotificationCount();
    }
  };
  const markAllUnreadNotificationRead = async () => {
    await clearAllUnreadNotifications(plantId!);

    let tempNotifications = [...notifications];
    tempNotifications.forEach((notification) => {
      if (!notification.isCritical) {
        notification.read = true;
      }
    });
    setNotifications(tempNotifications);
    await fetchUnreadNotificationCount();
  };

  const styles: { [key: string]: React.CSSProperties } = {
    parentMenu: {
      width: "100%",
      height: "75%",
    },
    notificatnHeaderAndTabLayoutContainer: {
      height: "150px",
      background: "#fff",
    },
    notificationHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      background: "#fff",
      marginBottom: "5px",
      marginTop: "-25px",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    heading: {
      textAlign: "left",
      fontFamily: `${FONT_NAME}`,
      color: "black",
      letterSpacing: "0px",
      fontWeight: "bold",
      fontSize: "22px",
      marginLeft: "28px",
      marginTop: "40px",
      display: "inline-block",
    },
    markNotificationAsReadText: {
      color: "black",
      textDecoration: "underline",
      fontSize: "13px",
      fontFamily: `${FONT_NAME}`,
      letterSpacing: "0px",
      marginTop: "40px",
      marginRight: "28px",
      display: "inline",
      whiteSpace: "pre-wrap",
    },
    tabLayoutContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#fff",
      borderBottom: "1px solid #ccc",
      paddingBottom: "5px",
    },
    notificationPopupBody: {
      overflowY: "scroll",
      maxHeight: `calc(75vh - 150px)`,
    },
    notificationPopupFooter: {
      display: "block",
      textAlign: "center",
    },
    bottomMostNotificationTextContainer: {
      marginTop: "40px",
      textAlign: "center",
      marginBottom: "100px",
      marginRight: "28px",
      display: "inline",
      whiteSpace: "pre-wrap",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#000",
      fontFamily: `${FONT_NAME}`,
      letterSpacing: "0px",
    },
    bottomMostNotificationText: {
      margin: "50px",
      paddingBottom: "40px",
      paddingTop: "20px",
      display: "block",
    },
  };

  let markNotificationReadStringArr = ["Mark notifications as read"];
  return (
    <Menu
      style={styles.parentMenu}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={notificationsMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenu2Open}
      onClose={() => {
        handleMenu2Close();
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          borderRadius: "15px",
          marginTop: "50px",
          margin: "0 !important",
          padding: "0 !important",
          width: "450px",
        },
      }}
    >
      <div style={styles.notificatnHeaderAndTabLayoutContainer}>
        <div style={styles.notificationHeader}>
          <p style={styles.heading}>Notifications</p>

          <Tooltip title="This will only mark all the normal unread-notifications as read.">
            <p style={styles.markNotificationAsReadText}>
              {markNotificationReadStringArr.map((word, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      markAllUnreadNotificationRead();
                    }}
                  >
                    {word}
                  </span>
                );
              })}
            </p>
          </Tooltip>
        </div>
        <div style={styles.tabLayoutContainer}>
          <StyledTabs
            value={value}
            onChange={handleChangeTab}
            aria-label="styled tabs example"
          >
            <StyledTab
              label={
                <div className="badge-wrapper">
                  <span>All</span>
                  <StyledBadge
                    classes={{ badge: "badge-content" }}
                    badgeContent={
                      notificationCount && notificationCount["all"]
                        ? notificationCount["all"].toString()
                        : "0"
                    }
                    isselected={(value === 0).toString()}
                  />
                </div>
              }
              value={0}
              onClick={() => setValue(0)}
            />

            <StyledTab
              label={
                <div className="badge-wrapper">
                  <span>Critical</span>
                  <StyledBadge
                    classes={{ badge: "badge-content" }}
                    badgeContent={
                      notificationCount && notificationCount["critical"]
                        ? notificationCount["critical"].toString()
                        : "0"
                    }
                    isselected={(value === 1).toString()}
                  />
                </div>
              }
              value={1}
              onClick={() => setValue(1)}
            />

            <StyledTab
              label={
                <div className="badge-wrapper">
                  <span>Normal</span>
                  <StyledBadge
                    classes={{ badge: "badge-content" }}
                    badgeContent={
                      notificationCount && notificationCount["normal"]
                        ? notificationCount["normal"].toString()
                        : "0"
                    }
                    isselected={(value === 2).toString()}
                  />
                </div>
              }
              value={2}
              onClick={() => setValue(2)}
            />
          </StyledTabs>

          <FormControlLabel
            sx={{ fontSize: "10px !important" }}
            control={
              <Switch
                // checked={unread}
                onChange={handleChangeUnread}
                name="unread-switch"
                color="primary"
              />
            }
            label={<Typography sx={{ fontSize: "10px" }}>Unread</Typography>}
          />
        </div>
      </div>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        sx={{ height: "80%" }}
      >
        <div ref={rootRef1} style={styles.notificationPopupBody}>
          {notifications?.map((notification, index) => {
            return unread ? (
              notification.read ? null : (
                <NotificationCard
                  read={notification.read}
                  title={notification.title}
                  message={notification.message}
                  to={notification.to}
                  timestamp={notification.time}
                  isCritical={notification.isCritical}
                  plantId={plantId}
                  notificationId={notification._id}
                  markNoticatifactionasRead={markNoticatifactionasRead}
                />
              )
            ) : (
              <NotificationCard
                read={notification.read}
                title={notification.title}
                message={notification.message}
                to={notification.to}
                timestamp={notification.time}
                isCritical={notification.isCritical}
                plantId={plantId}
                notificationId={notification._id}
                markNoticatifactionasRead={markNoticatifactionasRead}
              />
            );
          })}

          {(loading || hasNextPage) && (
            <Box ref={sentryRef1} sx={{ textAlign: "center", margin: "100px" }}>
              <CircularProgress size="30px" color="inherit" />
            </Box>
          )}
          {!(loading || hasNextPage) && (
            <Box
              ref={sentryRef1}
              sx={styles.bottomMostNotificationTextContainer}
            >
              <span style={styles.bottomMostNotificationText}>
                {"No More Notifications"}
              </span>
            </Box>
          )}
        </div>
      </TabPanel>

      <TabPanel value={value} index={1} dir={theme.direction}>
        <div ref={rootRef2} style={styles.notificationPopupBody}>
          {criticalNotifications.map((notification, index) => {
            return unread ? (
              notification.read ? null : (
                <NotificationCard
                  read={notification.read}
                  title={notification.title}
                  message={notification.message}
                  to={notification.to}
                  timestamp={notification.time}
                  isCritical={notification.isCritical}
                  plantId={plantId}
                  notificationId={notification._id}
                  markNoticatifactionasRead={markNoticatifactionasRead}
                />
              )
            ) : (
              <NotificationCard
                read={notification.read}
                title={notification.title}
                message={notification.message}
                to={notification.to}
                timestamp={notification.time}
                isCritical={notification.isCritical}
                plantId={plantId}
                notificationId={notification._id}
                markNoticatifactionasRead={markNoticatifactionasRead}
              />
            );
          })}
          {(isCriticalLoading || criticalHasNextPage) && (
            <Box ref={sentryRef2} sx={{ textAlign: "center", margin: "100px" }}>
              <CircularProgress size="30px" color="inherit" />
            </Box>
          )}
          {!(isCriticalLoading || criticalHasNextPage) && (
            <Box
              ref={sentryRef2}
              sx={styles.bottomMostNotificationTextContainer}
            >
              <span style={styles.bottomMostNotificationText}>
                {"No More Critical Notifications"}
              </span>
            </Box>
          )}
        </div>
      </TabPanel>

      <TabPanel value={value} index={2} dir={theme.direction}>
        <div ref={rootRef3} style={styles.notificationPopupBody}>
          {normalNotifications.map((notification, index) => {
            return unread ? (
              notification.read ? null : (
                <NotificationCard
                  read={notification.read}
                  title={notification.title}
                  message={notification.message}
                  to={notification.to}
                  timestamp={notification.time}
                  isCritical={notification.isCritical}
                  plantId={plantId}
                  notificationId={notification._id}
                  markNoticatifactionasRead={markNoticatifactionasRead}
                />
              )
            ) : (
              <NotificationCard
                read={notification.read}
                title={notification.title}
                message={notification.message}
                to={notification.to}
                timestamp={notification.time}
                isCritical={notification.isCritical}
                plantId={plantId}
                notificationId={notification._id}
                markNoticatifactionasRead={markNoticatifactionasRead}
              />
            );
          })}
          {(isNormalLoading || normalHasNextPage) && (
            <Box ref={sentryRef3} sx={{ textAlign: "center", margin: "100px" }}>
              <CircularProgress size="30px" color="inherit" />
            </Box>
          )}
          {!(isNormalLoading || normalHasNextPage) && (
            <Box
              ref={sentryRef3}
              sx={styles.bottomMostNotificationTextContainer}
            >
              <span style={styles.bottomMostNotificationText}>
                {"No More Normal Notifications"}
              </span>
            </Box>
          )}
        </div>
      </TabPanel>
    </Menu>
  );
}
