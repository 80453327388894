import { create } from 'zustand';

type UnifiedDashboardStore = {
  pageMode: string;
  selectedUnifiedPageId: string | null;
  selectedDndPageId: string | null;
  allowDrag: boolean;
  setPageMode: (pageMode: string) => void;
  setSelectedUnifiedPageId: (selectedPageId: string | null) => void;
  setSelectedDndPageId: (selectedPageId: string | null) => void;
  setAllowDrag: (allowDrag: boolean) => void;
  resetUnifiedDashboardStore: () => void;
};

export const useUnifiedDashboardStore: any = create<UnifiedDashboardStore>(set => ({
  pageMode: 'view',
  allowDrag: true,
  selectedUnifiedPageId: null,
  selectedDndPageId: null,
  setPageMode: pageMode => set({ pageMode }),
  setSelectedUnifiedPageId: selectedUnifiedPageId => set({ selectedUnifiedPageId }),
  setSelectedDndPageId: selectedDndPageId => set({ selectedDndPageId }),
  setAllowDrag: allowDrag => set({ allowDrag }),
  resetUnifiedDashboardStore: () => set({ pageMode: 'view' }),
}));
