import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import LayoutComponent from '../components/LayoutComponent';
import { Box, Button, ThemeProvider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
  getEquipmentData,
  // fetchAlertTasks,
  getFullSensorListInCategories,
  getPlantConfigVarsForPlantById,
  getBoolSensorTagListForPlant,
  getAllUserListForPlantId,
  getRolesForTasks,
  getEscalationLevels,
  fetchAlertForPlant,
  fetchAlertTasks,
} from '../services/alertTaskConfig';
import { VarList, Equipment, User } from '../Interfaces/alertTask';
import { SensorList } from '../Interfaces/sensors';
import dpTheme from '../styles/theme/dpTheme';
import AlertsDataGrid from '../components/alertTaskConfig/AlertsDataGrid';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';
import { PrimaryRoundedButton } from '../styles/global/components/dpButtons';
import AddAlertDialog from '../components/alertTaskConfig/AddAlertDialogue';
import { FONT_NAME } from '../core-components/theme/theme';

export default function AlertTaskConfig() {
  type pageParams = {
    id: string | undefined;
  };

  const { id } = useParams<pageParams>();

  const [loading, setLoading] = useState(true);
  // const [fixedTasks, setFixedTasks] = useState<FixedTasks[]>([]);
  const [sensorList, setSensorList] = useState<SensorList>({
    'Real Sensors': [],
    'Virtual Sensors': [],
  });
  const [configVarList, setConfigVarList] = useState<VarList | null>(null);
  const [userList, setUserList] = useState<User[]>([]);
  const [escaltionOptions, setEscalationOptions] = useState<string[]>([]);
  const [assignRole, setAssignRole] = useState<string[]>([]);
  const [masterAlertTasks, setMasterAlertTasks] = useState<any>([]);
  const navigate = useNavigate();
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [boolSensorTagList, setBoolSensorTagList] = useState<string[]>([]);
  const [alertsForPlant, setAlertsForPlant] = useState<any>([]);

  const [processing, setProcessing] = useState(false);
  const [showProcessingResult, setShowProcessingResult] = useState(false);

  const [showAddAlertModal, setShowAddAlertModal] = useState(false);
  const [gotoNextPage, setGotoNextPage] = useState(false);
  const [requestNextPage, setRequestNextPage] = useState(false);
  const [refresh, setRefresh] = useState(false);

  function refreshPage() {
    setRefresh(!refresh);
  }

  useEffect(() => {
    getRolesForTasks()
      .then(data => {
        setAssignRole(data);
      })
      .catch(error => {
        console.error('Error fetching roles for task data: ', error);
      });

    getEscalationLevels(id)
      .then(data => {
        const escalationGroups = Object.keys(data);
        setEscalationOptions(escalationGroups);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching escalation levels data: ', error);
      });

    getEquipmentData(id)
      .then(data => {
        setEquipments(data);
      })
      .catch(error => {
        console.error('Error fetching equipment data: ', error);
      });

    fetchAlertTasks()
      .then(data => {
        // setFixedTasks(data);
        setMasterAlertTasks(data.data.masterData.General);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching alert tasks: ', error);
      });

    fetchAlertForPlant(id)
      .then(data => {
        setAlertsForPlant(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching alert tasks: ', error);
      });

    getFullSensorListInCategories(id)
      .then(message => {
        setSensorList(message);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching sensor list: ', error);
      });

    getPlantConfigVarsForPlantById(id)
      .then(data => {
        setConfigVarList(data);
      })
      .catch(error => {
        console.error('Error fetching plant config vars: ', error);
      });

    getBoolSensorTagListForPlant(id)
      .then(data => {
        setBoolSensorTagList(data);
      })
      .catch(error => {
        console.error('Error fetching bool sensor tag list: ', error);
      });

    getAllUserListForPlantId(id)
      .then(message => {
        setUserList(message);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching user list: ', error);
      });
  }, [refresh]);
  function addAlert(alert: any) {
    let newAlerts = [...alertsForPlant, alert];
    setAlertsForPlant(newAlerts);
    setShowAddAlertModal(false);
  }
  function closeMethod() {
    setShowAddAlertModal(false);
  }

  const addNewTask = () => {
    setShowAddAlertModal(true);
    // let newTask = {
    //   toUser: "Operator Group",
    //   condition: [],
    //   resolutionConditions: [],
    //   type: "Mixed",
    //   dueTimeFromPosting: "02:00:00",
    //   autoCalling: false,
    //   escalations: {
    //     "Level 1": {
    //       time: "10:00:00",
    //       group: "Escalation Level 1",
    //     },
    //     "Level 2": {
    //       time: "12:00:00",
    //       group: "Escalation Level 2",
    //     },
    //     "Level 3": {
    //       time: "14:00:00",
    //       group: "Escalation Level 3",
    //     },
    //   },
    //   heading: {
    //     eng: "Enter Heading",
    //     hindi: "शीर्षक दर्ज करें",
    //   },
    //   description: {
    //     eng: "Enter Description",
    //     hindi: "विवरण दर्ज करें",
    //   },
    //   subTasks: [
    //     {
    //       name: "Enter Subtask",
    //       namehindi: "सबटास्क दर्ज करें",
    //     },
    //   ],
    // };

    // let oldTasks = fixedTasks;
    // oldTasks.push(newTask);
    // setFixedTasks(oldTasks);
    // setRefresh(!refresh);
  };

  const handleSubmit = async () => {
    // console.log("handle submit called");
    // setRequestNextPage(true);
    // if (gotoNextPage) {
    navigate(`/manualAlertsConfig/${id}`);
    // }
  };

  function move() {
    navigate(`/manualAlertsConfig/${id}`);
  }

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={id}>
        <Box m={1} p={1}>
          <div
            style={{
              fontFamily: `${FONT_NAME}`,
              fontSize: 30,
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
          >
            Alert Tasks Configuration
            <span style={{ float: 'right' }}>
              <Button variant="contained" onClick={addNewTask}>
                <AddIcon />
                Add New Alert Task
              </Button>
            </span>
          </div>
        </Box>
        <>
          <AlertsDataGrid
            alertsData={alertsForPlant}
            setAlertsForPlant={setAlertsForPlant}
            processing={processing}
            setProcessing={setProcessing}
            showProcessingResult={showProcessingResult}
            setShowProcessingResult={setShowProcessingResult}
            plantSensorList={sensorList}
            configVarList={configVarList}
            boolSensorTagList={boolSensorTagList}
            equipments={equipments}
            userList={userList}
            assignRole={assignRole}
            escaltionOptions={escaltionOptions}
            requestNextPage={requestNextPage}
            showAddAlertModal={showAddAlertModal}
            setRequestNextPage={setRequestNextPage}
            setGotoNextPage={setGotoNextPage}
            setShowAddAlertModal={setShowAddAlertModal}
            refresh={refreshPage}
            alertType="AlertTasks"
          />
          <AddAlertDialog
            closeMethod={closeMethod}
            showAddAlertDialogue={showAddAlertModal}
            addAlert={addAlert}
            masterAlertList={masterAlertTasks}
            plantId={id}
            alertType="AlertTasks"
          />
          {gotoNextPage ? move() : <></>}
        </>

        {loading ? null : (
          <>
            <Box m={1} p={1}>
              <PrimaryRoundedButton id="next_btn" onClick={handleSubmit}>
                Next
              </PrimaryRoundedButton>
            </Box>
            <Box
              m={4}
              p={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <BottomNavigationComponent plantId={id!} currentPage="Alert Tasks" />
            </Box>
          </>
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
}
