import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import {
  sankey as d3Sankey,
  SankeyNode,
  SankeyLink,
  sankeyLinkHorizontal,
  sankeyCenter,
} from 'd3-sankey';
import { getSankeyWidgetData } from '../../services/dashboardPage';
import { Box, Typography, Stack } from '@mui/material';
import GeneralisedPopupToolBar from '../../core-components/theme/components/HeaderComponents/GeneralisedPopupToolBar';
import LinkIcon from '../../core-components/assets/icons/linkIcon.svg';
import DownloadIcon from '@mui/icons-material/Download';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ExpandedSankeyWidget from './ExpandedSankeyWidget';
import { convertFlowDataToCSV, downloadCSV } from '../../utils/dashboard.utils';
import lodash from 'lodash';
import { WIDGET_THEMES } from '../../constants/widgetConfig.constants';
import ClockIcon from '@mui/icons-material/WatchLater';
import ClockOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { calculateTimeFrame } from '../../utilities/helpers';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import CustomDialog from '../../globalComponents/dialogs/CustomDialog';
import DateAndTime from '../../globalComponents/dateAndTime/DateAndTime';
import { TIME_RANGE_MAPPINGS } from '../../constants/constants';

// import CustomDialog from '../../globalComponents/dialogs/CustomDialog';
// import DateAndTime from '../../globalComponents/dateAndTime/DateAndTime';

// Define the types for the nodes and links
interface Node {
  name: string;
}

interface Link {
  source: number;
  target: number;
  value: number;
}

// interface SankeyData {
//   nodes: Array<SankeyNode<Node, Link>>;
//   links: Array<SankeyLink<Node, Link>>;
// }

const styles = {
  iconStyle: { width: '16px', height: '16px', color: '#ACB3B9' },
};

const fontSize = 12; // Base font size
const rectPadding = 4; // Padding inside the rect
const rectHeight = fontSize * 2.5; // Height of the rect, based on font size
const rectBorderRadius = 5; // Border radius for the rect

// const background = WIDGET_THEMES['default']?.backgroundColor;
// const fontColor = WIDGET_THEMES['default']?.fontColor;
// const lightBackground = WIDGET_THEMES['default']?.lightBackgroundColor;

const SankeyWidget = (props: any) => {
  const {
    widgetId,
    refresh,
    timeFrame,
    widgetStructure,
    enableToolBar = true,
    setParent,
    setIsBottomBarOpen,
    isBottomBarOpen,
    changedWidgets,
    setChangedWidgets,
  } = props;
  const [widgetData, setWidgetData] = useState<any>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [showPopupToolBar, setShowPopupToolBar] = useState(false);
  const [openExpandedSankey, setOpenExpandedSankey] = useState(false);
  const [containerHeight, setContainerHeight] = useState(150);
  const [showClock, setShowClock] = useState<string>('off');
  const [widgetTimeFrame, setWidgetTimeFrame] = useState(timeFrame);
  const [openDialog, setOpenDialog] = useState(false);
  //@ts-ignore
  const [sendParentInGetWidgeDataApi, setSendParentInGetWidgetDataApi] = useState('dashboard');
  const [widgetDateAndTime, setWidgetDateAndTime] = useState(null);
  const [customTimeRangeSettings, setCustomTimeRangeSettings] = useState<any>(null);
  const [customGranularity, setCustomGranularity] = useState(null);
  const [background, setBackbground] = useState(WIDGET_THEMES['OceanGreen'].backgroundColor);
  const [fontColor, setFontColor] = useState(WIDGET_THEMES['OceanGreen'].fontColor);
  const [lightBackground, setLightBackground] = useState(
    WIDGET_THEMES['OceanGreen'].lightBackground
  );

  // const [openDateAndTimeDialog, setOpenDateTimeDialog] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const TOOLTIP_OFFSETX = 5;
  const TOOLTIP_OFFSETY = -5;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditClick = () => {
    setParent('widget');
    setOpenDialog(true);
    setSendParentInGetWidgetDataApi('widget');
    if (isBottomBarOpen) {
      setIsBottomBarOpen(false);
    }
  };

  const add530HoursInTimeFrame = (timeFrame: any | null) => {
    if (!timeFrame)
      return {
        startDate: new Date(),
        endDate: new Date(),
      };
    const newTimeFrame = {
      startDate: new Date(timeFrame?.startDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
      endDate: new Date(timeFrame?.endDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
    };

    return {
      ...newTimeFrame,
    };
  };

  const handleShowClock = (widgetData: any) => {
    // console.log('showclock : ', showClock, widgetData?.advancedWidgetSettings);
    if (showClock === 'off' && widgetData?.advancedWidgetSettings?.applyCustomSettings) {
      let newTimeFrame = add530HoursInTimeFrame(
        calculateTimeFrame(widgetData.customTimeRangeSettings)
      );
      setWidgetTimeFrame(newTimeFrame);
      setShowClock('on');

      const { startDate: startTime, endDate: endTime }: any = calculateTimeFrame(
        widgetData.customTimeRangeSettings
      );

      let customTimeRangeSettings = {
        ...widgetData.customTimeRangeSettings,
        startTime,
        endTime,
      };
      setCustomTimeRangeSettings(customTimeRangeSettings);
    }
  };

  const setWidgetDateAndTimeFunction = (data: any) => {
    const widgetDateAndTime: any = {
      widgetId,
      factoryGranularitySettings: data?.factoryGranularitySettings,
      factoryTimeRangeSettings: data?.factoryTimeRangeSettings,
      customGranularitySettings: data?.customGranularitySettings,
      customTimeRangeSettings: data?.customTimeRangeSettings,
      advancedWidgetSettings: data?.advancedWidgetSettings,
    };

    // console.log('selected range 2 : ', widgetDateAndTime);

    setWidgetDateAndTime(widgetDateAndTime);
  };

  const drawSankey = (data: any) => {
    const svg = d3.select(svgRef.current);
    const { width, height } = svg.node()?.getBoundingClientRect() || {
      width: 960,
      height: 500,
    };

    const sankey = d3Sankey<Node, Link>()
      .nodeWidth(15)
      .nodePadding(10)
      .extent([
        [1, 1],
        [width - 1, height - 5],
      ])
      .nodeAlign(sankeyCenter);

    const { nodes, links } = sankey(data);

    svg.selectAll('*').remove(); // Clear previous SVG elements

    const color = d3.scaleOrdinal(d3.schemeCategory10);
    // console.log("testing")
    // Append node rects
    svg
      .append('g')
      .selectAll<SVGRectElement, SankeyNode<Node, Link>>('rect')
      .data(nodes)
      .enter()
      .append('rect')
      .attr('x', d => d.x0!)
      .attr('y', d => d.y0!)
      .attr('height', d => d.y1! - d.y0!)
      .attr('width', sankey.nodeWidth())
      .attr('fill', d => color(d.name));
    // .attr('stroke', '#000');

    // Append links
    svg
      .append('g')
      .selectAll<SVGPathElement, SankeyLink<Node, Link>>('path')
      .data(links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', sankeyLinkHorizontal())
      .attr('stroke-width', d => Math.max(0, d.width!))
      .attr('stroke', (d, i) => color(i.toString()))
      .attr('stroke-opacity', 0.3)
      .attr('fill', 'none')
      .on('mouseover', (event, d: any) => {
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'block';
          tooltipRef.current.style.left = `${event.offsetX + TOOLTIP_OFFSETX}px`;
          tooltipRef.current.style.top = `${event.offsetY + TOOLTIP_OFFSETY}px`;
          tooltipRef.current.innerHTML = `${d.source.name} → ${d.target.name} : ${d.value}`;
        }
      })
      .on('mousemove', event => {
        if (tooltipRef.current) {
          tooltipRef.current.style.left = `${event.offsetX + TOOLTIP_OFFSETX}px`;
          tooltipRef.current.style.top = `${event.offsetY + TOOLTIP_OFFSETY}px`;
        }
      })
      .on('mouseout', () => {
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'none';
        }
      });

    // Append node labels

    svg
      .append('g')
      .selectAll('g')
      .data(nodes)
      .enter()
      .append('g')
      .each(function (d) {
        // Append a white background rect for the text
        d3.select(this)
          .append('rect')
          .attr('x', (d: any) =>
            d.x0! < width / 2 ? d.x0! + 18 : d.x0! - 13 - d.name.length * fontSize * 0.6
          ) // Adjust x position for rect
          .attr('y', (d: any) => (d.y1! + d.y0!) / 2 - rectHeight / 2) // Center the rect vertically around the text
          .attr('width', (d: any) => d.name.length * fontSize * 0.6 + 2 * rectPadding) // Adjust width based on text length
          .attr('height', rectHeight) // Use the dynamic rect height
          .attr('fill', 'white')
          .attr('fill-opacity', 0.7) // Set transparency
          .attr('rx', rectBorderRadius) // Border radius
          .attr('ry', rectBorderRadius) // Border radius
          .attr('padding', rectPadding); // Padding inside the rect

        // Append the text on top of the rect
        d3.select(this)
          .append('text')
          .attr('x', (d: any) => (d.x0! < width / 2 ? d.x0! + 24 : d.x0! - 7))
          .attr('y', (d: any) => (d.y1! + d.y0!) / 2 - fontSize / 2) // Adjust to center the text vertically
          .attr('dy', '0.35em')
          .attr('font-size', fontSize) // Use dynamic font size
          .attr('text-anchor', (d: any) => (d.x0! < width / 2 ? 'start' : 'end'))
          .attr('fill', '#000')
          .text((d: any) => `${d.name}`);

        // Append the value below the text
        d3.select(this)
          .append('text')
          .attr('x', (d: any) =>
            d.x0! < width / 2
              ? d.x0! + 22 + d.name.length * fontSize * 0.14
              : d.x0! - d.name.length * fontSize * 0.25
          )
          .attr('y', (d: any) => (d.y1! + d.y0!) / 2 + fontSize * 0.5) // Adjust the y position to be below the node name
          .attr('dy', '0.35em')
          .attr('font-size', fontSize)
          .attr('font-weight', 600)
          .attr('text-anchor', (d: any) => (d.x0! < width / 2 ? 'start' : 'end'))
          .attr('fill', '#000')
          .text((d: any) => `${d.value}`); // Assuming 'value' is the key for the value in your data
      });
  };

  const fetchData = async () => {
    let newTimeFrame = add530HoursInTimeFrame(timeFrame);
    let response = await getSankeyWidgetData(
      widgetId,
      showClock == 'on' ? widgetTimeFrame : newTimeFrame,
      widgetStructure
    );
    setWidgetData(response);
    handleShowClock(response);
    setWidgetDateAndTimeFunction(response);

    drawSankey(lodash.cloneDeep(response?.flowData));
  };

  useEffect(() => {
    // fetchData();

    // Observe changes in the size of the parent container
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          fetchData(); // Re-run the initialization code to update the SVG dimensions

          if (containerRef.current) {
            //@ts-ignore
            const newHeight = Math.floor(containerRef.current.offsetHeight);

            if (newHeight !== containerHeight) {
              setContainerHeight(containerHeight);
            }
          }
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [refresh, showClock, timeFrame, widgetTimeFrame]);

  useEffect(() => {
    let theme = widgetData?.widgetLook?.theme;
    setBackbground(
      WIDGET_THEMES[theme]?.backgroundColor || WIDGET_THEMES['default']?.backgroundColor
    );
    setFontColor(WIDGET_THEMES[theme]?.fontColor || WIDGET_THEMES['default']?.fontColor);
    setLightBackground(
      WIDGET_THEMES[theme]?.lightBackgroundColor || WIDGET_THEMES['default']?.lightBackgroundColor
    );
    // console.log('theme : ', theme, WIDGET_THEMES[theme]);
  }, [widgetData]);

  const handleDownloadCSV = () => {
    let csvData = convertFlowDataToCSV(widgetData.flowData);
    downloadCSV(csvData, 'FlowData.csv');
  };

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const formatDateTime = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
    const timeString = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${dateString} ${timeString}`;
  };

  const formatTimeRange = () => {
    if (
      showClock == 'on' &&
      customTimeRangeSettings?.startTime &&
      customTimeRangeSettings?.endTime
    ) {
      const startTime = new Date(customTimeRangeSettings.startTime);
      const endTime = new Date(customTimeRangeSettings.endTime);
      return `${formatDateTime(startTime)} - ${formatDateTime(endTime)} | ${capitalizeFirstLetter(
        'hours'
      )}`;
    } else {
      return `${formatDateTime(timeFrame.startDate)} - ${formatDateTime(timeFrame.endDate)} | ${capitalizeFirstLetter(
        'hours'
      )}`;
    }
  };

  const handleClockClick = (value: any) => {
    setShowClock(value);
    setSendParentInGetWidgetDataApi('clockWidget');
    handleWidgetUpdate(value);
    setParent('widget');
  };

  const handleWidgetUpdate = (showClock: string) => {
    const updatedWidget = {
      dashboardId: widgetData.dashboardPageId,
      widgetId: widgetId || '',
      selectedGranularity: showClock == 'on' ? customGranularity : timeFrame.granularity,
      selectedChart: {
        widgetType: 'SankeyWidget',
        graphType: 'SankeyWidget',
      },
      selectedTimeRange:
        showClock == 'on' && customTimeRangeSettings
          ? customTimeRangeSettings
          : // : null
            (function () {
              let mapping = TIME_RANGE_MAPPINGS[timeFrame?.timeRangeLabel];
              let unit = mapping?.unit;
              let unitMultiplier = mapping?.unitMultiplier;
              return {
                unit: unit,
                unitMultiplier: unitMultiplier,
                timeRangeType: timeFrame.timeRangeType,
                startTime: timeFrame.startDate,
                endTime: timeFrame.endDate,
              };
            })(),
      applyCustomSettings: showClock == 'on' ? true : false,
    };

    updateChangedWidgets(updatedWidget, showClock);
  };

  const updateChangedWidgets = (updatedWidget: any, showClock: string) => {
    const tempChangedWidgets = [...changedWidgets];
    const existingWidgetIndex = tempChangedWidgets.findIndex(
      widget => widget.widgetId === updatedWidget.widgetId
    );
    let showBottomBar: any = false;
    let clone1;
    let clone2;
    if (existingWidgetIndex !== -1 && showClock == 'on') {
      // console.log('existing widget 1');
      clone1 = lodash.cloneDeep(tempChangedWidgets[existingWidgetIndex]);
      clone2 = lodash.cloneDeep(updatedWidget);
      if (clone1 && clone2) {
        if (
          clone1.selectedTimeRange?.timeRangeType == 'relative' &&
          clone2.selectedTimeRange?.timeRangeType == 'relative'
        ) {
          delete clone1.selectedTimeRange.startTime;
          delete clone1.selectedTimeRange.endTime;
          delete clone2.selectedTimeRange.startTime;
          delete clone2.selectedTimeRange.endTime;
        } else {
          clone1.selectedTimeRange.startTime = new Date(
            clone1.selectedTimeRange.startTime
          ).toISOString();
          clone1.selectedTimeRange.endTime = new Date(
            clone1.selectedTimeRange.endTime
          ).toISOString();
          clone2.selectedTimeRange.startTime = new Date(
            clone2.selectedTimeRange.startTime
          ).toISOString();
          clone2.selectedTimeRange.endTime = new Date(
            clone2.selectedTimeRange.endTime
          ).toISOString();
        }
      }
      showBottomBar = !lodash.isEqual(clone1, clone2);
    } else if (showClock == 'disabled') {
      // console.log('existing widget 2');
      showBottomBar =
        tempChangedWidgets[existingWidgetIndex]?.applyCustomSettings !=
        updatedWidget?.applyCustomSettings;
    }

    // if (showBottomBar) {
    //   console.log("parent widgetId ", widgetId);
    //   console.log("parent tempChangediwdges[exis]", clone1);
    //   console.log("parent updatedWidget", clone2);
    //   console.log(
    //     "parent lodashequal,showBottomBa,showClock",
    //     lodash.isEqual(clone1, clone2),
    //     showBottomBar,
    //     showClock
    //   );
    // }

    if (existingWidgetIndex !== -1) {
      let newUpdatedWidget = {
        ...tempChangedWidgets[existingWidgetIndex],
        ...updatedWidget,
      };
      tempChangedWidgets.splice(existingWidgetIndex, 1);
      tempChangedWidgets.push(newUpdatedWidget);
    } else {
      tempChangedWidgets.push(updatedWidget);
    }

    setChangedWidgets(tempChangedWidgets);
    // if (!lodash.isEqual(tempChangedWidgets, changedWidgets))
    if (showBottomBar) {
      setParent('widget');
      setIsBottomBarOpen(true);
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        background: '#fff',
        // border: `1px solid ${NeutralsColor['100']}`,
        border: `1px solid ${background}`,
        overflow: 'hidden',
      }}
      borderRadius={4}
      onMouseEnter={(event: any) => {
        if (enableToolBar) {
          event.stopPropagation();
          setShowPopupToolBar(true);
        }
      }}
      onMouseLeave={(event: any) => {
        if (enableToolBar) {
          event.stopPropagation();
          setShowPopupToolBar(false);
        }
      }}
    >
      <GeneralisedPopupToolBar
        open={showPopupToolBar}
        enableToolBar={enableToolBar}
        iconConfigs={[
          {
            icon: <DownloadIcon sx={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              handleDownloadCSV();
            },
          },
          {
            icon: <CallMadeIcon sx={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              setOpenExpandedSankey(true);
            },
          },
          {
            icon: <img src={LinkIcon} style={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              // navigateToLink();
            },
          },
          {
            icon: <ModeEditOutlineIcon sx={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              handleEditClick();
            },
          },
        ]}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box p={2} mb={1}>
          <Typography
            variant="h6"
            sx={{
              color: fontColor,
              fontSize: 'clamp(16px,1.1vw,20px) !important',
            }}
          >
            {widgetData?.widgetName}
          </Typography>
        </Box>
        <svg ref={svgRef} width="100%" height="calc(100% - clamp(16px,1.1vw,20px) - 60px)" />
        <div
          ref={tooltipRef}
          style={{
            position: 'absolute',
            background: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            padding: '5px',
            borderRadius: '3px',
            pointerEvents: 'none',
            top: '50%',
            left: '50%',
            minWidth: '100px',
            zIndex: 10000000000,
            fontSize: '10px',
            display: 'none',
          }}
        ></div>
        <Box
          sx={{
            height: '35px',
            borderTop: `1px solid ${background}`,
            background: lightBackground,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
          <Box sx={{ width: '90%' }} p={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Box sx={{ display: 'grid', placeItems: 'center' }}>
                {showClock == 'on' && (
                  <ClockIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: fontColor,
                    }}
                    onClick={() => handleClockClick('disabled')}
                  />
                )}
                {showClock === 'disabled' && (
                  <ClockOutlinedIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#ACB3B9',
                    }}
                    onClick={() => handleClockClick('on')}
                  />
                )}
              </Box>
              <Box
                sx={{
                  color: fontColor,
                  fontSize: (containerHeight / 100) * 8,
                  // lineHeight: (containerWidth / 100) * 3,
                  lineHeight: '15px',
                }}
              >
                {formatTimeRange()}
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '10%',
              borderLeft: `1px solid ${background}`,
              height: '100%',
              display: 'grid',
              placeItems: 'center',
              color: '#fff',
              background: background,
              fontWeight: 500,
            }}
          >
            {widgetData?.commonInfo?.widgetUnit && widgetData?.commonInfo?.widgetUnit != 'none'
              ? widgetData?.commonInfo?.widgetUnit
              : '-'}
          </Box>
        </Box>
      </Box>
      {/* <CustomDialog
        open={openDateAndTimeDialog}
        onClose={() => {
          setOpenDateTimeDialog(false);
        }}
      >
        <DateAndTime
          parent="widget"
          onClose={() => {
            setOpenDateTimeDialog(false);
          }}
          widgetDateAndTime={widgetDateAndTime}
          setWidgetDateAndTime={setWidgetDateAndTime}
          changedWidgets={changedWidgets}
          setChangedWidgets={setChangedWidgets}
          setIsBottomBarOpen={setIsBottomBarOpen}
          setCustomTimeRangeSettings={setCustomTimeRangeSettings}
          setWidgetTimeFrame={setWidgetTimeFrame}
          showClock={showClock}
          setShowClock={setShowClock}
          timeFrame={timeFrame}
          setCustomGranularity={setCustomGranularity}
          setParent={setParent}
          selectChart={selectChart || widgetData?.graphType}
          setSelectedChart={setSelectedChart}
          dashboardId={widgetData?.dashboardPageId}
        />
      </CustomDialog> */}
      <ExpandedSankeyWidget
        open={openExpandedSankey}
        handleClose={() => {
          setOpenExpandedSankey(false);
        }}
        widgetId={widgetId}
        widgetStructure={null}
        timeFrame={timeFrame}
        refresh={refresh}
      />
      <CustomDialog open={openDialog} onClose={handleCloseDialog}>
        <DateAndTime
          parent="widget"
          onClose={handleCloseDialog}
          widgetDateAndTime={widgetDateAndTime}
          setWidgetDateAndTime={setWidgetDateAndTime}
          changedWidgets={changedWidgets || []}
          setChangedWidgets={setChangedWidgets}
          setIsBottomBarOpen={setIsBottomBarOpen}
          setCustomTimeRangeSettings={setCustomTimeRangeSettings}
          setWidgetTimeFrame={setWidgetTimeFrame}
          showClock={showClock}
          setShowClock={setShowClock}
          timeFrame={timeFrame}
          setCustomGranularity={setCustomGranularity}
          setParent={setParent}
          selectChart={widgetData?.widgetType}
          setSelectedChart={(abc: string) => {}}
          dashboardId={widgetData?.dashboardPageId}
        />
      </CustomDialog>
    </Box>
  );
};

export default SankeyWidget;
