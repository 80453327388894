import { useState, useRef } from 'react';
import { Box, Stack, TextField, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StoreIcon from '@mui/icons-material/Store';

const styles = {
  infoIcon: {
    fontSize: 18,
    color: '#999',
  },
  sensorListContainer: {
    height: '200px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    background: 'white',
  },
  sensorItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  searchTextField: {
    background: 'white',
    '& fieldset': { border: 'none' },
  },
};

interface propsInterface {
  sensorList: any[];
  keywordFilterList: string[];
  widgetMetrics: any;
  refreshPage: Boolean;
  setRefreshPage: Function;
  refreshStandAloneWidget: Boolean;
  setRefreshStandAloneWidget: Function;
  widgetPlantIds: string[];

  setAreMultipleSensorSelected: Function;
  allowMultipleSensor: boolean;
}

function MetricSearchBar(props: propsInterface) {
  // Sample list of sensors
  const {
    sensorList,
    keywordFilterList,
    widgetMetrics,
    refreshPage,
    setRefreshPage,
    refreshStandAloneWidget,
    setRefreshStandAloneWidget,
    widgetPlantIds,
    setAreMultipleSensorSelected,
    allowMultipleSensor = true,
  } = props;
  let keywordFilteredSensorList: any[] = sensorList;
  if (keywordFilterList?.length > 0)
    keywordFilteredSensorList = sensorList?.filter((sens: any) => {
      //  console.log("sensorId and tag : ",sens._id.toString() + " " ,sens.sensorTag)
      //@ts-ignore
      return keywordFilterList.some((keyword: any) => {
        if (sens.sensorTag?.includes(keyword)) return true;
      });
    });

  if (widgetPlantIds.length > 0)
    keywordFilteredSensorList = sensorList.filter((sens: any) => {
      //  console.log("sensorId and tag : ",sens._id.toString() + " " ,sens.sensorTag)
      //@ts-ignore
      return widgetPlantIds.some((plantId: any) => {
        if (sens.plantId?.toString() === plantId || sens.storeId || sens.workspaceId) return true;
      });
    });
  // State to manage the search query
  const [searchQuery, setSearchQuery] = useState('');
  let widgetMetricsSensorIds: any[] = [];

  // State to store the selected sensors
  // const [selectedsensors, setSelectedsensors] = useState<any[]>([]);
  const [showSensorlist, setShowSensorList] = useState<Boolean>(false);
  const metricSearchBarResultsDivRef = useRef(null);
  useOutsideClickDetector(metricSearchBarResultsDivRef, () => {
    setShowSensorList(false);
  });

  /// function to update widgetMetricsSensorIds
  const updateWidgetmetricsSensorIds = () => {
    widgetMetricsSensorIds = [];
    let widgetMetricKeys = Object.keys(widgetMetrics);
    for (const widgetMetricKey of widgetMetricKeys) {
      widgetMetricsSensorIds.push(widgetMetrics[widgetMetricKey].sensorId);
    }
    if (widgetMetricsSensorIds?.length > 1) {
      setAreMultipleSensorSelected(true);
    }
    console.log(widgetMetricsSensorIds);
  };

  // Function to handle sensor selection and adding to the array
  const handleAddsensor = (sensor: any) => {
    let newWidgetMetric: any = {
      checked: true,
      sensorId: sensor._id,
      dataType: sensor.dataType,
      nickName: sensor.sensorNickName,
    };
    if (sensor && sensor.dataInputType) {
      if (sensor.dataInputType === 'Change Of') {
        newWidgetMetric = {
          ...newWidgetMetric,
          dataInputType: sensor.dataInputType,
        };
      }
    }

    if (allowMultipleSensor) widgetMetrics[sensor.sensorTag] = newWidgetMetric;
    else {
      Object.keys(widgetMetrics).forEach(key => {
        delete widgetMetrics[key];
      });
      widgetMetrics[sensor.sensorTag] = newWidgetMetric;
    }

    updateWidgetmetricsSensorIds();
    setRefreshPage(!refreshPage);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  // Function to handle sensor removal
  // const handleRemovesensor = (sensor: any) => {
  //   const updatedsensors = selectedsensors.filter((item) => item !== sensor);
  //   setSelectedsensors(updatedsensors);
  // };

  // Function to handle search query changes
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sensors based on the search query
  const filteredsensors = keywordFilteredSensorList.filter(
    (sensor: any) =>
      sensor.sensorTag?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sensor.sensorNickName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box borderRadius={1} sx={{ overflow: 'hidden' }} ref={metricSearchBarResultsDivRef}>
      <TextField
        type="text"
        autoComplete="off"
        fullWidth
        value={searchQuery}
        variant="outlined"
        placeholder="Search and add sensors you want to track here"
        // label="filter"
        inputProps={{
          maxLength: 50,
          step: '0.01',
        }}
        sx={styles.searchTextField}
        onChange={handleSearchChange}
        onFocus={() => {
          setShowSensorList(true);
        }}
        onBlur={() => {}}
      />
      {sensorList?.length > 0 && showSensorlist && filteredsensors.length > 0 && (
        <Stack borderTop="0.5px solid #ddd" sx={styles.sensorListContainer}>
          {filteredsensors.map((sensor: any, index: number) => (
            <Box key={index} p={1} sx={styles.sensorItem}>
              <Stack direction="row" alignItems="center" gap={1}>
                <span style={{ overflowWrap: 'break-word', maxWidth: '300px' }}>
                  {sensor.sensorNickName && sensor.sensorNickName != ''
                    ? sensor.sensorNickName
                    : sensor.sensorTag}
                </span>
                {sensor.sensorNickName && (
                  <Tooltip title={sensor.sensorTag}>
                    <InfoOutlinedIcon sx={styles.infoIcon} />
                  </Tooltip>
                )}
                {sensor.storeId && <StoreIcon sx={styles.infoIcon} />}
              </Stack>

              {Object.keys(widgetMetrics).includes(sensor.sensorTag) ? (
                <Box p={0.5}>
                  <CheckIcon fontSize="small" sx={{ color: 'green' }} />
                </Box>
              ) : (
                <IconButton onClick={() => handleAddsensor(sensor)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          ))}
        </Stack>
      )}
      {filteredsensors?.length === 0 && <p>No sensorExists</p>}
    </Box>
  );
}

export default MetricSearchBar;
