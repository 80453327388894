import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ChatIcon from "@mui/icons-material/Chat";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import { FONT_NAME } from "../../../core-components/theme/theme";

export default function HistoryTiles(props: any) {
  const { sensorIndex, sensorHistory } = props;
  const { isEnabled, updatedBy, comment } = sensorHistory;
  const { name } = updatedBy[0];
  const mappedHistory = [
    {
      event: "update",
      message: `Sensor was turned ${isEnabled ? "ON" : "OFF"} by ${name}`,
    },
    {
      event: "comment",
      message: `${name} added a comment`,
      comment,
    },
  ];
  return (
    <Grid
      item
      style={{
        width: "100%",
        display: "inline-block",
        boxSizing: "border-box",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <Box my={1} style={{ width: "100%" }}>
        <Accordion
          elevation={0}
          defaultExpanded={sensorIndex === 0 ? true : false}
          style={{ width: "100%", fontFamily: `${FONT_NAME}` }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              fontFamily: `${FONT_NAME}`,
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {moment(sensorHistory?.updatedAt).format("MMM Do YYYY, h:mm a")}
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              background: "#F5F6F9",
            }}
          >
            <Timeline style={{ padding: "0" }}>
              {mappedHistory.map((item, index) => {
                return (
                  <TimelineItem style={{}}>
                    <TimelineOppositeContent
                      style={{ flex: "0", padding: 0 }}
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          backgroundColor: "black",
                          width: "16px",
                          height: "16px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {item.event === "update" && (
                          <ScheduleIcon
                            style={{ width: "inherit", height: "inherit" }}
                          />
                        )}
                        {item.event === "comment" && (
                          <ChatIcon
                            style={{ width: "inherit", height: "inherit" }}
                          />
                        )}
                      </TimelineDot>
                      {index === 0 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Stack
                        p={1}
                        spacing={0.5}
                        borderRadius={2}
                        style={{
                          border: "1px solid #D4DCE5",
                          background: "#fff",
                        }}
                      >
                        <div style={{ color: "#383D4A", fontSize: "12px" }}>
                          {item.message}
                        </div>
                        {item.event === "comment" ? (
                          <div style={{ textAlign: "center" }}>
                            <Box
                              m={1}
                              p={1}
                              // bgcolor="#D3D3D3"
                              bgcolor="#F5F6F9"
                              borderRadius={5}
                            >
                              <div
                                style={{
                                  fontSize: 12,
                                  fontWeight: "500",
                                  color: "#193458",
                                }}
                              >
                                {item.comment}
                              </div>
                            </Box>
                          </div>
                        ) : null}
                        <div
                          style={{
                            marginRight: "10px",
                            fontSize: "10px",
                            color: "#4D4D4D",
                          }}
                        ></div>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
            <Box
              style={{
                width: "100%",
                display: "flex",
                gap: "10px",
                fontSize: "12px",
              }}
            ></Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
}
