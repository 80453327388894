import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Paper, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LayoutComponent from '../components/LayoutComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/lab/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import { StyledActionableButton, PrimaryButton } from '../styles/global/components/dpButtons';
import { escalationLevelsData } from '../Interfaces/escalationInterfaces';
import { fetchPlantWorkForce } from '../services/users';
import { getEscalationLevels, updateEscalationLevel } from '../services/alertTaskConfig';
import { Stack } from '@mui/system';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';
import { FONT_NAME } from '../core-components/theme/theme';

const styles = {
  pageHeading: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 30,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  escalationGroupHeading: {
    font: `${FONT_NAME}`,
    fontSize: 24,
  },
};

interface workforce {
  id: string;
  name: string;
}

export default function EscalationLevel() {
  const { id } = useParams();
  const [escalations, setEscalations] = useState<escalationLevelsData | null>(null);
  const [groups, setGroups] = useState<string[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [workForce, setWorkForce] = useState<workforce[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchWorkForce();
    // getDpTeam();
    fetchEscalations();
  }, []);

  const fetchEscalations = () => {
    getEscalationLevels(id!).then(data => {
      setEscalations(data);
      setGroups(Object.keys(data));
      setLoading(false);
    });
  };
  const fetchWorkForce = () => {
    fetchPlantWorkForce(id!).then(data => {
      setWorkForce((prevWorkForce: workforce[]) => {
        return [...prevWorkForce, ...data];
      });
    });
  };


  // Todo: Remove this piece of code
  // const getDpTeam = () => {
  //   fetchDpTeam().then(data => {
  //     setWorkForce((prevWorkForce: workforce[]) => {
  //       const newData = data.filter(
  //         (item: any) => !prevWorkForce.some((prevItem: any) => prevItem.id === item._id)
  //       );
  //       return [...prevWorkForce, ...newData];
  //     });
  //     setRefresh(!refresh);
  //   });
  // };

  const changeWorkForceforLevel = (value: workforce[], group: string) => {
    let existingGroups = escalations;
    let escalationGroup = { ids: [] as string[], names: [] as string[] };
    for (let index = 0; index < value.length; index++) {
      escalationGroup.ids.push(value[index]['id']);
      escalationGroup.names.push(value[index]['name']);
    }
    if (existingGroups != null) {
      existingGroups[group] = escalationGroup;
      setEscalations(existingGroups);
      setRefresh(!refresh);
    }
  };

  const changeGroupName = (index: number, value: string) => {
    let existingGroups = groups;
    let lastName = existingGroups[index];
    let existingEscalation = escalations;
    if (existingEscalation != null) {
      existingEscalation[value] = existingEscalation[lastName];
      delete existingEscalation[lastName];
      setEscalations(existingEscalation);
      existingGroups[index] = value;
      setGroups(existingGroups);
      setRefresh(!refresh);
    }
  };

  const fetchSelectedOptions = (group: string) => {
    let existinggroup = escalations?.[group];
    let User: any = [];
    if (existinggroup) {
      for (let i = 0; i < existinggroup['ids'].length; i++) {
        for (let WFindex = 0; WFindex < workForce.length; WFindex++) {
          if (
            workForce[WFindex]['id'] === existinggroup['ids'][i] &&
            User.indexOf(workForce[WFindex]) === -1
          ) {
            User.push(workForce[WFindex]);
          }
        }
      }
    }
    return User;
  };

  const handleSubmit = () => {
    if (escalations != null) {
      if (
        escalations['Escalation Level 1']['ids'].length === 0 ||
        escalations['Escalation Level 1']['ids'].length === 0 ||
        escalations['Escalation Level 1']['ids'].length === 0 ||
        escalations['Emergency Group']['ids'].length === 0 ||
        escalations['Email Group']['ids'].length === 0
      ) {
        toast('Add People in mandatory Groups');
      } else {
        updateEscalationLevel(id!, escalations).then(() => {
          navigate(`/fixedTasksConfig/${id}`);
        });
      }
    }
  };

  const createNewGroup = () => {
    let data = escalations;
    let keys = groups;
    keys.push('New Group');
    setGroups(keys);
    if (data != null) {
      data['New Group'] = {
        ids: [],
        names: [],
      };
      setEscalations(data);
      setRefresh(!refresh);
    }
  };

  const DeleteGroup = (Name: string) => {
    let keys = groups;
    let data = escalations;
    if (data != null) {
      keys.splice(keys.indexOf(Name), 1);
      setGroups(keys);
      delete data[Name];
      setEscalations(data);
      setRefresh(!refresh);
    }
  };
  return (
    <LayoutComponent plantId={id}>
      <Box m={1} p={1}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          justifyContent="space-between"
          gap="10px"
        >
          <span style={styles.pageHeading}> Plant Escalation Configuration</span>
          <span>
            <PrimaryButton sx={{ minWidth: '200px' }} onClick={createNewGroup}>
              <AddIcon />
              Add Escalation Group
            </PrimaryButton>
          </span>
        </Stack>
      </Box>
      {loading && <div>loading ...</div>}
      {!loading && escalations && (
        <Box>
          {groups.map((group, index) => {
            let edit = true;
            if (
              group === 'Escalation Level 1' ||
              group === 'Escalation Level 2' ||
              group === 'Escalation Level 3' ||
              group === 'Emergency Group' ||
              group === 'Email Group'
            ) {
              edit = false;
            }
            return (
              <Box m={1}>
                <Paper elevation={10}>
                  <Box m={1} p={1}>
                    <div>
                      {edit ? (
                        <TextField
                          required
                          value={group}
                          label="Group Name"
                          onChange={e => {
                            changeGroupName(index, e.target.value);
                          }}
                        />
                      ) : (
                        <span style={styles.escalationGroupHeading}>{group}</span>
                      )}
                      <Button
                        onClick={() => {
                          DeleteGroup(group);
                        }}
                        disabled={!edit}
                        sx={{ color: 'black', float: 'right' }}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Box>
                  <Box m={1} p={1}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={workForce}
                      getOptionLabel={option => option.name}
                      defaultValue={fetchSelectedOptions(group)}
                      onChange={(event, newValue) => {
                        changeWorkForceforLevel(newValue, group);
                      }}
                      renderInput={params => (
                        <TextField key={params.id} {...params} variant="standard" label="Users" />
                      )}
                    />
                  </Box>
                </Paper>
              </Box>
            );
          })}
        </Box>
      )}

      <Box m={1} p={1}>
        <StyledActionableButton onClick={handleSubmit}>Next</StyledActionableButton>
      </Box>
      <Box
        m={4}
        p={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BottomNavigationComponent plantId={id!} currentPage="Escalation" />
      </Box>

      <ToastContainer />
    </LayoutComponent>
  );
}
