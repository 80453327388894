import jwtDecode, { JwtPayload } from 'jwt-decode';

interface TokenInterface extends JwtPayload {
  role: string;
  email: string;
  userId: string;
  userName: string;
  isStaff?: boolean;
}

const useAuth = () => {
  const token = localStorage?.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode<TokenInterface>(token);
      const { role, userId, email, userName, isStaff } = decodedToken;
      return { role, userId, email, userName, isStaff };
    } catch (err) {
      console.error('PROBLEM IN TOKEN', err);
      return { role: '', userId: '', email: '', isStaff: false };
    }
  }
  return { role: '', userId: '', email: '' };
};

export default useAuth;
