import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
//@ts-ignore
import Map from "mapmyindia-react";
import { Box, Paper } from "@mui/material";
import { Stack } from "@mui/system";
import LayoutComponent from "../components/LayoutComponent";
import lodash from "lodash";
import Skeleton from "@mui/material/Skeleton";
import greenManIcon from "../constants/Images/greenMan.png";
import orangeManIcon from "../constants/Images/orangeMan.png";
import redManIcon from "../constants/Images/redMan.png";
import redMapIcon from "../constants/Images/redMapIcon.png";
import greenMapIcon from "../constants/Images/greenMapIcon.png";
import {
  manpowerStatusOfPlant,
  plantCheckInHistoryEvent,
} from "../Interfaces/manpowerAvailability";
import { BlackButton } from "../styles/global/components/dpButtons";
import { getManpowerData } from "../services/manpowerAvailability";
import ManpowerDataOfPlantRowComponent from "../components/manpowerAvailabilityComponents/ManpowerDataOfPlantRowComponent";
import { FONT_NAME } from "../core-components/theme/theme";

const styles = {
  pageHeading: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 30,
    width: "95%",
    margin: "auto",
    marginTop: "20px",
  },
  loadingSkeleton: {
    height: "300px",
    margin: "auto",
    marginTop: "20px",
  },
};

//////////// database functions

function addCssToElement(
  element: HTMLElement,
  style: { [key: string]: string }
) {
  for (const property in style)
    element.style[property as any] = style[property];
}

const setMarkerColors = (markers: markerInterface[]) => {
  console.log("inside set marker color function");

  var markerElements = document.getElementsByClassName("leaflet-marker-icon");

  console.log("elm.length : ", markerElements.length);
  console.log("markerslength : ", markers.length);

  for (var i = 0; i < markers.length; i++) {
    let markerElm = markerElements[i] as HTMLImageElement;
    let marker = markers[i];

    if (markerElm) {
      addCssToElement(markerElm, {
        width: "30px",
        height: "35px",
        color: "red",
      });

      markerElm.src = marker.markerIcon;
      console.log("markerIcon  : ", marker.markerIcon);
    }
  }
};

interface markerInterface {
  position: [string, string];
  title: string;
  markerIcon: string;
}

const getMarkerIconForOperatorBasedOnAvailability = (
  currentOnDutyStatus: boolean,
  currentCheckInStatus: boolean
) => {
  if (currentOnDutyStatus == false)
    return redManIcon; // off duty               color code = red
  else if (currentCheckInStatus == true)
    return orangeManIcon; // on duty but  checkedIn  color code = orange
  else return greenManIcon; //  on duty but not checkedIn   color code = green
};

const getMarkerIconForPlant = (isPlantManned: boolean) => {
  if (isPlantManned) return greenMapIcon;
  else return redMapIcon;
};

///////////////////////////////////////

export default function ManpowerAvailability() {
  const [loading, setLoading] = useState<boolean>(false);
  const [markers, setMarkers] = useState<markerInterface[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [manpowerDataOfPlants, setManpowerDataOfPlants] = useState<
    manpowerStatusOfPlant[]
  >([]);

  useEffect(() => {
    console.log("running in use effect");
    getManpowerData()
      .then((data) => {
        console.log("data :", data);
        let plantInfo: manpowerStatusOfPlant[] = data;
        setManpowerDataOfPlants(plantInfo);

        let tempMarkers: markerInterface[] = [];

        for (var i = 0; i < plantInfo.length; i++) {
          tempMarkers.push({
            position: plantInfo[i].location,
            title: plantInfo[i].name,
            markerIcon: getMarkerIconForPlant(plantInfo[i].manningStatus),
          });
        }

        const allPlantsLatestCheckInEventOfEveryOperator: plantCheckInHistoryEvent[] =
          [];

        for (const plant of plantInfo) {
          if (plant.history) {
            const plantHistory = lodash.cloneDeep(plant.history);

            for (let i = plantHistory.length - 1; i >= 0; i--) {
              let plantCheckInEvent = plantHistory[i];

              /// check if current checkInEvent's operator has already been added in allPlantsLatestaCheckInEventOfEveryOperator,
              // if not add him
              if (
                !allPlantsLatestCheckInEventOfEveryOperator.find(
                  (checkInEvent) =>
                    checkInEvent.operatorId === plantCheckInEvent.operatorId
                )
              ) {
                allPlantsLatestCheckInEventOfEveryOperator.push(
                  plantCheckInEvent
                );
              }
            }
          }
        }

        for (const checkInEvent of allPlantsLatestCheckInEventOfEveryOperator) {
          tempMarkers.push({
            position: [
              checkInEvent.deviceInfo.location.latitude.toString(),
              checkInEvent.deviceInfo.location.longitude.toString(),
            ],
            title: `<div><p>${checkInEvent.operatorName}</p><p>${checkInEvent.operatorRole}</p>${checkInEvent.phoneNo}<p>${checkInEvent.lastTimeUpdate}</p></div>`,
            markerIcon: getMarkerIconForOperatorBasedOnAvailability(
              checkInEvent.currentOnDutyStatus,
              checkInEvent.currentCheckInStatus
            ),
          });
        }

        console.log("markers length before set markers : ", tempMarkers.length);
        setMarkers(tempMarkers);
        setLoading(true);
        // setMarkerColors(tempMarkers);
        setRefresh(!refresh);

        console.log("plantInfo 2", plantInfo);
      })
      .catch();
  }, []);

  return (
    <LayoutComponent>
      {loading ? (
        <>
          <div style={styles.pageHeading}>
            Manpower Availability and Allocation
          </div>
          <Stack direction="column" gap="20px">
            <TableContainer
              component={Paper}
              style={{ margin: "auto", marginTop: "20px" }}
            >
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Plant</TableCell>
                    <TableCell align="right">Open Alerts</TableCell>
                    <TableCell align="right">Manning Status</TableCell>
                    {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manpowerDataOfPlants.map((row) => (
                    <ManpowerDataOfPlantRowComponent key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <BlackButton
              sx={{ marginLeft: "auto" }}
              onClick={() => {
                setMarkerColors(markers);
              }}
            >
              Set Marker Colors
            </BlackButton>

            <Box>
              <div style={{ height: 700 }}>
                <Map
                  zoom={4}
                  hybrid={false}
                  search={false}
                  height={700}
                  markers={markers}
                  id="map"
                />
              </div>
            </Box>
          </Stack>
        </>
      ) : (
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={styles.loadingSkeleton}
        />
      )}
    </LayoutComponent>
  );
}
