import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import LayoutComponent from "../components/LayoutComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import InventoryTableRow from "../components/InventoryTableRow";
import { getInventory } from "../services/inventory";
import { InventoryQuestion, InventoryQuestionBank } from "../Interfaces/inventory";
import { FONT_NAME } from "../core-components/theme/theme";

export default function Inventory() {
  type pageParams = {
    id: string;
  };
  const { id } = useParams<pageParams>();
  const [InventoryData, setInventoryData] = useState<InventoryQuestionBank | null>(null);
  const [plantName, setplantName] = useState("");
  const [showOnlyLowInventory, setShowOnlyLowInventory] = useState(false);

  useEffect(() => {
    getInventory(id)
      .then((data) => {
        setInventoryData(data.inventoryData.questionBank);
        setplantName(data.name);
      });
  }, [id]);

  return (
    <LayoutComponent plantId={id}>
      <Box m={1} p={1}>
        <div
          style={{
            fontFamily: `${FONT_NAME}`,
            fontSize: 30,
            alignItems: "center",
            verticalAlign: "middle",
          }}
        >
          {plantName} Inventory
          {/* <span style={{float:"right"}}>
                    <GetAppIcon/>
                    </span> */}
        </div>
        <Button
          variant="contained"
          style={{ background: "rgb(0,0,0)", marginTop: "20px" }}
          onClick={() => {
            setShowOnlyLowInventory(!showOnlyLowInventory);
          }}
        >
          {showOnlyLowInventory ? "show all inventory" : "show low inventory"}
        </Button>
      </Box>
      <Box m={1} p={1} />
      {
        InventoryData && 
        Object.keys(InventoryData).map((section: string) => {
          return (
            <Box p={1} key={section}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <div
                    style={{
                      fontFamily: `${FONT_NAME}`,
                      fontSize: 22,
                      opacity: "56%",
                    }}
                  >
                    {section}
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    <Box m={1} p={1}>
                      <TableContainer>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Inventory Name</TableCell>
                              <TableCell align="right">
                                Yesterday's Count
                              </TableCell>
                              <TableCell align="right">Today's Count</TableCell>
                              <TableCell align="right">Inventory Used</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {InventoryData?.[section]?.["questions"].map(
                              (row: InventoryQuestion) => {
                                return (
                                  <InventoryTableRow
                                    key={row.title}
                                    showOnlyLowInventory={showOnlyLowInventory}
                                    row={row}
                                    plantId={id}
                                  />
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })
      }
    </LayoutComponent>
  );
}
