import axiosInstance from '../../constants/axiosInstance';
const getMasterCustomWidget = async (
  widgetType:
    | 'heading'
    | 'graph'
    | 'number'
    | 'table'
    | 'MapWidget'
    | 'SankeyWidget'
    | 'ManualEntryWidget',
  dataType = 'STP'
) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getMasterCustomWidget?widgetType=${widgetType}&dataType=${dataType}`
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const getAiWidgetData = async (query: string, plantId: string, timeFrame: any) => {
  try {
    const { data } = await axiosInstance().post(`/newDashboard/getAiWidgetData`, {
      query: query,
      plantId: plantId,
      timeFrame: timeFrame,
    });
    return data.data;
  } catch (error) {
    console.log('error');
    // console.log("error : ", error);
  }
};
const analyseWidgetData = async (widgetData: any, plantId: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/analyseWidgetData?plantId=${plantId}`,
      widgetData
    );
    return data;
  } catch (error) {
    console.log('error');
    // console.log("error : ", error);
  }
};

const getWidgetAnalysisHistoriesForWidgetId = async (widgetId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getWidgetAnalysisHistoriesForWidgetId?widgetId=${widgetId}`
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const updateWidgetAnalysisHistory = async (widgetAnalysisHistoryId: string, updateData: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/updateWidgetAnalysisHistory?widgetAnalysisHistoryId=${widgetAnalysisHistoryId}`,
      updateData
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const deleteWidgetAnalysisHistory = async (widgetAnalysisHistoryId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/deleteWidgetAnalysisHistory?widgetAnalysisHistoryId=${widgetAnalysisHistoryId}`
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const getMasterMapWidget = async () => {
  try {
    const { data } = await axiosInstance().post(`/newDashboard/getMasterMapWidget`);
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const getWidgetByTypeAndId = async (widgetType: string, widgetId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getWidgetByTypeAndId?widgetType=${widgetType}&widgetId=${widgetId}`
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const getManualEntryWidgetData = async (widgetId: string, widgetStructure: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getManualEntryWidgetData?widgetId=${widgetId}`,
      { widgetStructure }
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

export {
  getMasterCustomWidget,
  analyseWidgetData,
  getWidgetAnalysisHistoriesForWidgetId,
  updateWidgetAnalysisHistory,
  deleteWidgetAnalysisHistory,
  getAiWidgetData,
  getMasterMapWidget,
  getWidgetByTypeAndId,
  getManualEntryWidgetData,
};
