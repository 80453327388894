import { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ToastContainer, toast } from 'react-toastify';
import AddDpTeamPopup from '../components/AddDpTeamPopup/AddDpTeamPopup';
import { deleteUser, fetchDpTeam } from '../services/users';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import { FONT_NAME } from '../core-components/theme/theme';

const styles = {
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  heading: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 30,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
};

export default function DigitalPaaniTeam() {
  const [dpTeam, setdpTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUser, setAddUser] = useState(false);
  //@ts-ignore
  const [forWho, setForWho] = useState('');
  const [edit, setEdit] = useState(false);
  const [userId, setUserId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [deletionCount, setDeletionCount] = useState(0);

  const handleClose = () => {
    setAddUser(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const DeleteUser = (id: string, count: number) => {
    if (count > 0) {
      toast('Cannot Delete user with Plants Assigned');
    } else {
      deleteUser(id)
        .then(response => {
          refresh();
        })
        .catch();
    }
  };

  const refresh = async () => {
    setEdit(false);
    setAddUser(false);
    await getdpTeam();
    setLoading(false);
  };

  const getdpTeam = async () => {
    fetchDpTeam().then(data => {
      if (data != null) setdpTeam(data);
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 350,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 350,
    },
    {
      field: 'userStatus',
      headerName: 'User Status',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                setUserId(params.row.id);
                setEdit(true);
                setForWho('Client');
                setAddUser(true);
              }}
            >
              <EditIcon sx={{ color: '#000' }} />
            </Button>

            <Button
              onClick={() => {
                setShowDeleteModal(true);
                setUserId(params.row.id);
                setDeletionCount(params.row.count);
              }}
            >
              <DeleteIcon sx={{ color: '#000' }} />
            </Button>
          </>
        );
      },
      width: 300,
    },
  ];

  return (
    <LayoutComponent>
      {/* <ThemeProvider theme={dpTheme}> */}
      <Box m={1} p={2}>
        <div style={styles.header}>
          <span style={styles.heading}>Digital Paani Team</span>
          <span>
            <PrimaryButton
              sx={{ fontSize: '14px' }}
              onClick={() => {
                setForWho('Client');
                setEdit(false);
                setAddUser(true);
              }}
            >
              <AddIcon fontSize="small" />
              Add
            </PrimaryButton>
          </span>
        </div>
      </Box>
      <Box m={1} p={1}>
        <div style={{ height: 420 }}>
          <DataGrid rows={dpTeam} columns={columns} loading={loading} />
        </div>
      </Box>
      <ToastContainer />
      {addUser ? (
        <AddDpTeamPopup
          handleClose={handleClose}
          edit={edit}
          userId={userId}
          forWho={'Client'}
          refresh={refresh}
        />
      ) : null}
      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage={'Are you sure you want to delete this user?'}
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          DeleteUser(userId, deletionCount);
          setShowDeleteModal(false);
        }}
      />
      {/* </ThemeProvider> */}
    </LayoutComponent>
  );
}
