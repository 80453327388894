import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  TableRow,
  ListItemText,
  Divider,
  Chip,
  Typography,
  Paper,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { useEffect, useState } from 'react';
import dpTheme from '../styles/theme/dpTheme';
import LayoutComponent from '../components/LayoutComponent';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CallMadeIcon from '@mui/icons-material/CallMade';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { BlackButton } from '../styles/global/components/dpButtons';
import EditComponentDialog from '../globalComponents/dialogs/EditComponentDialog';
import { TIME_UNITS } from '../utils/time.utils';

import { ToastContainer, toast } from 'react-toastify';
import {
  clonePageConfig,
  deleteDashboardPage,
  getDashboardPageListData,
  setAutoEmailingDataV2,
  setDashboardConfigData,
  deleteCluster,
  editCluster,
} from '../services/DashboardPageList';
import { useDashboardStore } from '../store/DashboardStore';
import moment from 'moment';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import { FONT_NAME } from '../core-components/theme/theme';

const DashboardPageListStyles = {
  CheckboxColor: {
    '&.Mui-checked': {
      color: '#1976D2 !important',
    },
  },
  GoToIcon: {
    color: '#999999',
    cursor: 'pointer',
  },
};

function MoreDetails(props: any) {
  const { dashboardClusterDetails } = props;

  const MoreDetailsStyles = {
    MoreDetailsContainer: {
      position: 'absolute',
      transform: 'translate(30px,10px)',
      zIndex: 999,
      border: '1px solid #000 ',
      borderRadius: '3%',
    },
    ChipStyle: {
      minWidth: '100px',
    },
  };

  return (
    <Paper sx={MoreDetailsStyles.MoreDetailsContainer} elevation={3}>
      <Stack rowGap={3} direction="column" p={5}>
        {dashboardClusterDetails.defaultPage.isDefaultPageInCluster === true && (
          <Stack direction="row" alignItems="center" columnGap={3}>
            <Typography ml={2} variant="h6">
              Default
            </Typography>
            <InsertDriveFileIcon fontSize="small" sx={{ color: '#79B256' }} />
            <Typography variant="body1">
              {dashboardClusterDetails.defaultPage.defaultPageName}
            </Typography>
          </Stack>
        )}
        {(dashboardClusterDetails.dailyReport.clusterHasDailyReportPage === true ||
          dashboardClusterDetails.weeklyReport.clusterHasWeeklyReportPage === true ||
          dashboardClusterDetails.monthlyReport.clusterHasMonthlyReportPage === true) && (
          <Typography ml={2} variant="h6">
            Report
          </Typography>
        )}
        {dashboardClusterDetails.dailyReport.clusterHasDailyReportPage === true && (
          <Stack columnGap={3} alignItems="center" direction="row">
            <Chip
              sx={MoreDetailsStyles.ChipStyle}
              label={<Typography variant="body1">Daily</Typography>}
            />
            <InsertDriveFileIcon fontSize="small" sx={{ color: '#79B256' }} />

            <Typography variant="body1">
              {dashboardClusterDetails.dailyReport.dailyReportPageName}
            </Typography>
          </Stack>
        )}
        {dashboardClusterDetails.weeklyReport.clusterHasWeeklyReportPage === true && (
          <Stack columnGap={3} alignItems="center" direction="row">
            <Chip
              sx={MoreDetailsStyles.ChipStyle}
              label={<Typography variant="body1">Weekly</Typography>}
            />
            <InsertDriveFileIcon fontSize="small" sx={{ color: '#79B256' }} />

            <Typography variant="body1">
              {dashboardClusterDetails.weeklyReport.weeklyReportPageName}
            </Typography>
          </Stack>
        )}
        {dashboardClusterDetails.monthlyReport.clusterHasMonthlyReportPage === true && (
          <Stack columnGap={3} alignItems="center" direction="row">
            <Chip
              sx={MoreDetailsStyles.ChipStyle}
              label={<Typography variant="body1">Monthly</Typography>}
            />
            <InsertDriveFileIcon fontSize="small" sx={{ color: '#79B256' }} />

            <Typography variant="body1">
              {dashboardClusterDetails.monthlyReport.monthlyReportPageName}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}

function ClusterRow(props: any) {
  const {
    clonePage,
    changeDefaultAutoEmailing,
    dashboardCluster,
    // setDashboardPageList,
    editPage,
    setSelectedPageId,
    navigate,
    plantId,
    deleteClusterFunc,
    deletePageFunc,
    changeDefaultPage,
    // showEditComponentDialog,
    // setShowEditComponentDialog,
    setRefresh,
  } = props;

  const [showEditComponentDialog, setShowEditComponentDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [clusterAnchorEl, setClusterAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>(
    new Array(props.dashboardCluster.dashboardPages.length).fill(null)
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleClusterMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClusterAnchorEl(event.currentTarget);
  };
  const handleClusterMenuClose = () => {
    setClusterAnchorEl(null);
  };

  const handleMenuClose = (index: number) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  function changeDateMoment(date: string) {
    const originalDate = moment(date);
    const formattedDate = originalDate.format('DD-MM-YYYY [T] HH:mm:ss');
    return formattedDate;
  }

  const [isRowOpen, setIsRowOpen] = useState(false);

  const getDefaultTimeInDays = (defaultTimeRangeSettings: any) => {
    if (!defaultTimeRangeSettings) return 'NAN';
    return (
      `${Math.abs(defaultTimeRangeSettings?.unitMultiplier)} ${
        TIME_UNITS[defaultTimeRangeSettings?.unit]?.displayName || ''
      }` || 'NAN'
    );
  };

  const getDefaultGranInDays = (defaultGranularitySettings: any) => {
    if (!defaultGranularitySettings) return 'NAN';
    return (
      `${Math.abs(defaultGranularitySettings?.unitMultiplier)} ${
        TIME_UNITS[defaultGranularitySettings?.unit]?.displayName || ''
      }` || 'NAN'
    );
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onSubmitFunc = async (data: any) => {
    // setLoading(true);
    const newClusterName = data.input1;

    const resp = await editCluster(dashboardCluster._id, newClusterName);
    toast(resp.message);
    setRefresh((prevState: boolean) => !prevState);
  };

  return (
    <>
      <TableRow
        onClick={() => {
          setIsRowOpen(prevState => !prevState);
        }}
        sx={{
          '& > *': { borderTop: isRowOpen ? '2px solid #000000' : 'unset' },
          backgroundColor: isRowOpen ? '#F0F0F0' : 'transparent',
        }}
      >
        <TableCell component="td" scope="row" sx={{ minWidth: '180px' }}>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Stack
              direction="row"
              alignItems={'center'}
              justifyContent={'flex-start'}
              columnGap={2}
            >
              <FolderIcon sx={{ color: '#3EA4A8' }} />
              <Typography noWrap>{dashboardCluster.name}</Typography>
            </Stack>
            {dashboardCluster.moreDetails.showMoreDetails === true && (
              <Box sx={{ display: isHovered ? 'block' : 'none' }}>
                <MoreDetails dashboardClusterDetails={dashboardCluster.moreDetails} />
              </Box>
            )}
          </div>
        </TableCell>
        <TableCell></TableCell>
        <TableCell align="center">
          <Checkbox
            checked={dashboardCluster.moreDetails.defaultPage.isDefaultPageInCluster}
            sx={DashboardPageListStyles.CheckboxColor}
            // onChange={handleChange}
            icon={<RemoveIcon fontSize="small" />}
            checkedIcon={<ExpandCircleDownOutlinedIcon fontSize="small" />}
          />
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">{dashboardCluster.clusterCreatorName}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">
            {' '}
            <RemoveIcon sx={{ color: '#999999' }} />
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">
            <RemoveIcon sx={{ color: '#999999' }} />
          </Typography>
        </TableCell>
        <TableCell align="left">
          <RemoveIcon sx={{ color: '#999999' }} />
        </TableCell>
        <TableCell align="left">
          <RemoveIcon sx={{ color: '#999999' }} />
        </TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="center">
          <Checkbox
            checked={dashboardCluster.moreDetails.dailyReport.clusterHasDailyReportPage}
            sx={DashboardPageListStyles.CheckboxColor}
            icon={<RemoveIcon sx={{ color: '#999999' }} />}
            checkedIcon={<ExpandCircleDownOutlinedIcon />}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            checked={dashboardCluster.moreDetails.weeklyReport.clusterHasWeeklyReportPage}
            sx={DashboardPageListStyles.CheckboxColor}
            icon={<RemoveIcon sx={{ color: '#999999' }} />}
            checkedIcon={<ExpandCircleDownOutlinedIcon />}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            checked={dashboardCluster.moreDetails.monthlyReport.clusterHasMonthlyReportPage}
            sx={DashboardPageListStyles.CheckboxColor}
            icon={<RemoveIcon sx={{ color: '#999999' }} />}
            checkedIcon={<ExpandCircleDownOutlinedIcon />}
          />
        </TableCell>
        <TableCell align="center">
          <Button
            id="basic-button"
            aria-controls={Boolean(clusterAnchorEl) ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(clusterAnchorEl) ? 'true' : undefined}
            onClick={e => {
              console.log('cluster name', dashboardCluster.name);

              handleClusterMenuClick(e);
            }}
            sx={{ color: 'black' }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={clusterAnchorEl}
            open={Boolean(clusterAnchorEl)}
            onClose={() => {
              handleClusterMenuClose();
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                setShowEditComponentDialog(true);
                handleClusterMenuClose();
              }}
            >
              <ListItemIcon>
                <ModeEditOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1" color="#5C6066">
                  Edit
                </Typography>
              </ListItemText>
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={() => {
                setShowDeleteModal(true);
                handleClusterMenuClose();
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1" color="#5C6066">
                  Delete
                </Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>

      {isRowOpen &&
        dashboardCluster.dashboardPages.map((dashboardPage: any, index: number) => (
          <TableRow
            sx={{
              borderBottom:
                index === dashboardCluster.dashboardPages.length - 1 && isRowOpen
                  ? '2px solid #000000'
                  : 'unset',
              backgroundColor: isRowOpen ? '#F7F7F7' : 'transparent',
            }}
            key={dashboardPage.pageId}
          >
            <TableCell
              sx={{
                minWidth: '130px',
                maxWidth: '130px',
              }}
            >
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'flex-start'}
                columnGap={2}
              >
                <RemoveIcon sx={{ color: '#CECECE' }} />
                <InsertDriveFileIcon sx={{ color: '#79B256' }} />
                <Typography noWrap>{dashboardPage.name}</Typography>
              </Stack>
            </TableCell>
            <TableCell align="center">
              <CallMadeIcon
                sx={DashboardPageListStyles.GoToIcon}
                onClick={() => {
                  setSelectedPageId(dashboardPage.pageId);
                  navigate(`/dashboardPage/${plantId}?pageId=${dashboardPage.pageId}`);
                }}
              />
            </TableCell>

            <TableCell align="center">
              <Checkbox
                checked={dashboardPage.pageConfig.default}
                onChange={() => {
                  changeDefaultPage(dashboardCluster._id, dashboardPage.pageId);
                }}
                sx={DashboardPageListStyles.CheckboxColor}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
            </TableCell>

            <TableCell align="left">
              <Typography variant="body2">{dashboardPage.pageConfig.pageCreatorName}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2">
                {changeDateMoment(dashboardPage.pageConfig.lastUpdateInfo.lastUpdated)}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2">
                {getDefaultTimeInDays(dashboardPage?.pageConfig?.defaultTimeRangeSettings)}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2">
                {getDefaultGranInDays(dashboardPage?.pageConfig?.defaultGranularitySettings)}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2">
                {dashboardPage?.pageConfig?.public ? 'Public' : 'Private'}
              </Typography>
            </TableCell>
            <TableCell align="right">{''}</TableCell>
            <TableCell align="center">
              <Checkbox
                checked={dashboardPage.pageConfig.sendDailyReport}
                onChange={event => {
                  changeDefaultAutoEmailing(
                    dashboardCluster._id,
                    dashboardPage.pageId,
                    'daily',
                    event.target.checked
                  );
                }}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                sx={DashboardPageListStyles.CheckboxColor}
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                checked={dashboardPage.pageConfig.sendWeeklyReport}
                onChange={event => {
                  changeDefaultAutoEmailing(
                    dashboardCluster._id,
                    dashboardPage.pageId,
                    'weekly',
                    event.target.checked
                  );
                }}
                sx={DashboardPageListStyles.CheckboxColor}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                checked={dashboardPage.pageConfig.sendMonthlyReport}
                onChange={event => {
                  console.log('pageId', dashboardPage.pageId);
                  changeDefaultAutoEmailing(
                    dashboardCluster._id,
                    dashboardPage.pageId,
                    'monthly',
                    event.target.checked
                  );
                }}
                sx={DashboardPageListStyles.CheckboxColor}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
            </TableCell>
            <TableCell align="center">
              <Button
                id="basic-button"
                aria-controls={Boolean(anchorEls[index]) ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEls[index]) ? 'true' : undefined}
                onClick={e => {
                  handleMenuClick(e, index);
                }}
                sx={{ color: 'black' }}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEls[index]}
                open={Boolean(anchorEls[index])}
                onClose={() => {
                  handleMenuClose(index);
                }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    editPage(dashboardPage.pageId, dashboardPage.pageConfig.permissionToEdit);
                    handleMenuClose(index);
                  }}
                >
                  <ListItemIcon>
                    <ModeEditOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1" color="#5C6066">
                      Edit
                    </Typography>
                  </ListItemText>
                </MenuItem>

                <Divider />
                <MenuItem
                  onClick={() => {
                    clonePage(dashboardCluster._id, dashboardPage.pageId, dashboardPage.name);
                    handleMenuClose(index);
                  }}
                >
                  <ListItemIcon>
                    <FileCopyIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1" color="#5C6066">
                      Clone
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    deletePageFunc(
                      dashboardCluster._id,
                      dashboardPage.pageId,
                      dashboardPage.pageConfig.permissionToEdit
                    );
                    handleMenuClose(index);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1" color="#5C6066">
                      Delete
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        ))}

      <EditComponentDialog
        isEditDialogOpen={showEditComponentDialog}
        setIsEditDialogOpen={setShowEditComponentDialog}
        heading={'Edit Cluster'}
        title1={'Cluster'}
        input1={dashboardCluster.name}
        onSubmitFunc={onSubmitFunc}
      />

      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage={`Are you sure you want to delete all the pages in the cluster?`}
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          deleteClusterFunc(dashboardCluster._id, dashboardCluster.dashboardPages);
          setProcessing(false);
        }}
      />
    </>
  );
}

function SinglePageRow(props: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  // const [isHovered, setIsHovered] = useState(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const {
    clonePage,
    changeDefaultAutoEmailing,
    dashboardClusterId,
    dashboardPage,
    // setDashboardPageList,
    editPage,
    setSelectedPageId,
    navigate,
    plantId,
    deletePageFunc,
    changeDefaultPage,
  } = props;

  function changeDateMoment(dateString: string) {
    let [date, time] = dateString.split('T');

    let [hours, minutes, seconds] = time.split(':');

    if (parseInt(seconds) > 59) {
      let tempMinutes = parseInt(minutes) + Math.floor(parseInt(seconds) / 60);
      minutes = tempMinutes.toString();
      let tempSeconds = parseInt(seconds) % 60;
      seconds = tempSeconds.toString();
      minutes = ('0' + minutes).slice(-2);
      seconds = ('0' + seconds).slice(-2);
    }

    time = [hours, minutes, seconds].join(':');

    const correctedDateString = [date, time].join('T');

    return moment(correctedDateString, 'DD-MM-YYYYTHH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
  }

  // function changeDateMoment(date: string) {
  //   const originalDate = moment(date);
  //   const formattedDate = originalDate.format("DD-MM-YYYY [T] HH:mm:ss");
  //   if (formattedDate === "Invalid date") {
  //     console.log("Invalid date ---------------------- ", date);
  //   }
  //   return formattedDate;
  // }

  // const [isRowOpen, setIsRowOpen] = useState(false);

  const getDefaultTimeInDays = (defaultTimeRangeSettings: any) => {
    if (!defaultTimeRangeSettings) return 'NAN';
    return (
      `${Math.abs(defaultTimeRangeSettings?.unitMultiplier)} ${
        TIME_UNITS[defaultTimeRangeSettings?.unit]?.displayName || ''
      }` || 'NAN'
    );
  };

  const getDefaultGranInDays = (defaultGranularitySettings: any) => {
    if (!defaultGranularitySettings) return 'NAN';
    return (
      `${Math.abs(defaultGranularitySettings?.unitMultiplier)} ${
        TIME_UNITS[defaultGranularitySettings?.unit]?.displayName || ''
      }` || 'NAN'
    );
  };

  return (
    <TableRow key={dashboardPage.pageId}>
      <TableCell
        sx={{
          minWidth: '130px',
          maxWidth: '130px',
        }}
      >
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'flex-start'}
          sx={
            {
              // maxWidth: "50%",
              // whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
            }
          }
          columnGap={2}
        >
          <InsertDriveFileIcon sx={{ color: '#79B256' }} />
          <Typography noWrap>{dashboardPage?.name || ''}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <CallMadeIcon
          sx={DashboardPageListStyles.GoToIcon}
          onClick={() => {
            setSelectedPageId(dashboardPage.pageId);
            navigate(`/dashboardPage/${plantId}?pageId=${dashboardPage.pageId}`);
          }}
        />
      </TableCell>

      <TableCell align="center">
        <Checkbox
          checked={dashboardPage.pageConfig.default}
          onChange={() => {
            changeDefaultPage(dashboardClusterId, dashboardPage.pageId);
          }}
          sx={DashboardPageListStyles.CheckboxColor}
          icon={<RadioButtonUncheckedIcon fontSize="small" />}
          checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
        />
      </TableCell>

      <TableCell align="left">
        <Typography variant="body2">{dashboardPage.pageConfig.pageCreatorName}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body2">
          {changeDateMoment(dashboardPage.pageConfig.lastUpdateInfo.lastUpdated)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body2">
          {getDefaultTimeInDays(dashboardPage?.pageConfig?.defaultTimeRangeSettings)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body2">
          {getDefaultGranInDays(dashboardPage?.pageConfig?.defaultGranularitySettings)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body2">
          {dashboardPage?.pageConfig?.public ? 'Public' : 'Private'}
        </Typography>
      </TableCell>
      <TableCell align="right">{''}</TableCell>
      <TableCell align="center">
        <Checkbox
          checked={dashboardPage.pageConfig.sendDailyReport}
          onChange={event => {
            changeDefaultAutoEmailing(
              dashboardClusterId,
              dashboardPage.pageId,
              'daily',
              event.target.checked
            );
          }}
          sx={DashboardPageListStyles.CheckboxColor}
          icon={<RadioButtonUncheckedIcon fontSize="small" />}
          checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={dashboardPage.pageConfig.sendWeeklyReport}
          onChange={event => {
            changeDefaultAutoEmailing(
              dashboardClusterId,
              dashboardPage.pageId,
              'weekly',
              event.target.checked
            );
          }}
          sx={DashboardPageListStyles.CheckboxColor}
          icon={<RadioButtonUncheckedIcon fontSize="small" />}
          checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={dashboardPage.pageConfig.sendMonthlyReport}
          onChange={event => {
            changeDefaultAutoEmailing(
              dashboardClusterId,
              dashboardPage.pageId,
              'monthly',
              event.target.checked
            );
          }}
          sx={DashboardPageListStyles.CheckboxColor}
          icon={<RadioButtonUncheckedIcon fontSize="small" />}
          checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
        />
      </TableCell>
      <TableCell align="center">
        <Button
          id="basic-button"
          aria-controls={isMenuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleMenuClick}
          sx={{ color: 'black' }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() =>
              editPage(dashboardPage.pageId, dashboardPage.pageConfig.permissionToEdit)
            }
          >
            <ListItemIcon>
              <ModeEditOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" color="#5C6066">
                Edit
              </Typography>
            </ListItemText>
          </MenuItem>

          <Divider />
          <MenuItem
            onClick={() => {
              clonePage(dashboardClusterId, dashboardPage.pageId, dashboardPage.name);
            }}
          >
            <ListItemIcon>
              <FileCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" color="#5C6066">
                Clone
              </Typography>
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              deletePageFunc(
                dashboardClusterId,
                dashboardPage.pageId,
                dashboardPage.pageConfig.permissionToEdit
              );
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" color="#5C6066">
                Delete
              </Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

function DashboardPageList() {
  const { plantId } = useParams();
  const [dashboardPageList, setDashboardPageList] = useState<any>([]);
  const { setSelectedPageId } = useDashboardStore();
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    fetchDashboardPageList();

    return () => {};
  }, [refresh]);

  const changeDefaultPage = async (clusterId: string, pageId: string) => {
    let newDashboardPageList = dashboardPageList;
    newDashboardPageList.forEach((dashboardCluster: any) => {
      if (dashboardCluster._id === clusterId) {
        dashboardCluster.isDefaultPageInCluster = true;
      } else {
        dashboardCluster.isDefaultPageInCluster = false;
      }

      dashboardCluster.dashboardPages.forEach((dashboardPage: any) => {
        if (dashboardPage.pageId === pageId) {
          dashboardPage.pageConfig.default = true;
        } else {
          dashboardPage.pageConfig.default = false;
        }
      });
    });
    setDashboardPageList(newDashboardPageList);

    setDashboardConfigData(pageId, plantId!)
      .then(data => {
        toast('Default Page Changed');
        setRefresh(prevState => !prevState);
      })
      .catch();
  };

  const addNewpage = () => {
    // reroute it to page configuration page
    navigate(`/pageConfigV2/${plantId}/11223344`);
  };

  const deletePageFunc = async (clusterId: string, pageId: string, permissionToEdit: boolean) => {
    if (permissionToEdit) {
      deleteDashboardPage(clusterId, pageId)
        .then(data => {
          setRefresh(prevState => !prevState);
        })
        .catch();
    } else toast("You are not the creator of this page hence you don't have permission to delete");
  };

  const deleteClusterFunc = async (clusterId: string, dashboardPages: any[]) => {
    const pageIds: string[] = dashboardPages.map(page => page.pageId);
    deleteCluster(clusterId, pageIds)
      .then(data => {
        setRefresh(prevState => !prevState);
      })
      .catch();
  };

  const editPage = async (pageId: string, permissionToEdit: boolean) => {
    if (permissionToEdit) navigate(`/pageConfigV2/${plantId}/${pageId}`);
    else toast('You are not the creator of this page hence you dont have permission to edit');
  };

  const clonePage = async (clusterId: string, pageId: string, pageName: string) => {
    clonePageConfig(clusterId, pageId)
      .then(data => {
        setRefresh(prevState => !prevState);
        toast(`${pageName} cloned`);
      })
      .catch(err => {
        toast(`${pageName} cloning failed`);
      });
  };

  const changeDefaultAutoEmailing = async (
    clusterId: string,
    pageId: string,
    timeRange: string,
    isChecked: boolean
  ) => {
    let newDashboardPageList = dashboardPageList;
    let subField = '';

    if (timeRange == 'daily') {
      subField = 'dailyReportPageId';
      newDashboardPageList.forEach((dashboardCluster: any) => {
        dashboardCluster._id === clusterId
          ? (dashboardCluster.moreDetails.dailyReport.clusterHasDailyReportPage = true)
          : (dashboardCluster.moreDetails.dailyReport.clusterHasDailyReportPage = false);
        dashboardCluster.dashboardPages.forEach((dashboardPage: any) => {
          dashboardPage.pageId === pageId
            ? (dashboardPage.pageConfig.sendDailyReport = true)
            : (dashboardPage.pageConfig.sendDailyReport = false);
        });
      });
    }
    if (timeRange == 'weekly') {
      subField = 'weeklyReportPageId';
      newDashboardPageList.forEach((dashboardCluster: any) => {
        dashboardCluster._id === clusterId
          ? (dashboardCluster.moreDetails.weeklyReport.clusterHasWeeklyReportPage = true)
          : (dashboardCluster.moreDetails.weeklyReport.clusterHasWeeklyReportPage = false);
        dashboardCluster.dashboardPages.forEach((dashboardPage: any) => {
          dashboardPage.pageId === pageId
            ? (dashboardPage.pageConfig.sendWeeklyReport = true)
            : (dashboardPage.pageConfig.sendWeeklyReport = false);
        });
      });
    }
    if (timeRange == 'monthly') {
      subField = 'monthlyReportPageId';
      newDashboardPageList.forEach((dashboardCluster: any) => {
        dashboardCluster._id === clusterId
          ? (dashboardCluster.moreDetails.monthlyReport.clusterHasMonthlyReportPage = true)
          : (dashboardCluster.moreDetails.monthlyReport.clusterHasMonthlyReportPage = false);
        dashboardCluster.dashboardPages.forEach((dashboardPage: any) => {
          dashboardPage.pageId === pageId
            ? (dashboardPage.pageConfig.sendMonthlyReport = true)
            : (dashboardPage.pageConfig.sendMonthlyReport = false);
        });
      });
    }

    setDashboardPageList(newDashboardPageList);

    setAutoEmailingDataV2(pageId, plantId!, subField, isChecked).then(data => {
      setRefresh(prevState => !prevState);
    });
  };

  const fetchDashboardPageList = async () => {
    let dashboardPageListData: any = await getDashboardPageListData(plantId!);
    setDashboardPageList(dashboardPageListData);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer plantId={plantId}>
        <Box m={4}>
          <Stack mb={4} direction="row" justifyContent={'space-between'}>
            <Typography variant="h1" color={'secondary.dark'}>
              Dashboard Page List
            </Typography>
            <BlackButton onClick={addNewpage}>
              <AddIcon fontSize="small" />
              <Typography variant="h6">ADD PAGE</Typography>
            </BlackButton>
          </Stack>
          <TableContainer
            sx={{ borderRadius: '10px', border: '1px solid #E0E0E0' }}
            component={Paper}
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Page</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">Default</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">Creator</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">Last Updated</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">Default Time</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">Granularity</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">Privacy</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={
                        <Typography
                          sx={{
                            fontFamily: `${FONT_NAME}`,
                            letterSpacing: '0.1599999964237213px',
                            fontSize: '13px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            textAlign: 'left',
                          }}
                        >
                          Report
                        </Typography>
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        fontFamily: `${FONT_NAME}`,
                        letterSpacing: '0.17000000178813934px',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        textAlign: 'center',
                      }}
                    >
                      Daily
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        fontFamily: `${FONT_NAME}`,
                        letterSpacing: '0.17000000178813934px',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        textAlign: 'center',
                      }}
                    >
                      Weekly
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        fontFamily: `${FONT_NAME}`,
                        letterSpacing: '0.17000000178813934px',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        textAlign: 'center',
                      }}
                    >
                      Monthly
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <MenuIcon />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardPageList.map((dashboardCluster: any, index: any) => {
                  if (dashboardCluster?.dashboardPages?.length > 1) {
                    return (
                      <ClusterRow
                        key={dashboardCluster._id}
                        dashboardCluster={dashboardCluster}
                        // setShowEditComponentDialog={setShowEditComponentDialog}
                        // showEditComponentDialog={showEditComponentDialog}
                        setRefresh={setRefresh}
                        setDashboardPageList={setDashboardPageList}
                        clonePage={clonePage}
                        editPage={editPage}
                        deleteClusterFunc={deleteClusterFunc}
                        deletePageFunc={deletePageFunc}
                        changeDefaultAutoEmailing={changeDefaultAutoEmailing}
                        changeDefaultPage={changeDefaultPage}
                        setSelectedPageId={setSelectedPageId}
                        navigate={navigate}
                        plantId={plantId}
                      />
                    );
                  } else {
                    return (
                      <SinglePageRow
                        key={dashboardCluster._id}
                        dashboardClusterId={dashboardCluster._id}
                        dashboardPage={dashboardCluster.dashboardPages[0]}
                        setDashboardPageList={setDashboardPageList}
                        clonePage={clonePage}
                        editPage={editPage}
                        deletePageFunc={deletePageFunc}
                        changeDefaultAutoEmailing={changeDefaultAutoEmailing}
                        changeDefaultPage={changeDefaultPage}
                        setSelectedPageId={setSelectedPageId}
                        navigate={navigate}
                        plantId={plantId}
                      />
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default DashboardPageList;
