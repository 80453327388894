import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChatIcon from "@mui/icons-material/Chat";
import BuildIcon from "@mui/icons-material/Build";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
// @ts-ignore
import ModalImage from "react-modal-image";

import { timeLineEvent } from "../Interfaces/maintenaceInterfaces";
import { FONT_NAME } from "../core-components/theme/theme";

const styles = {
  msgComponent: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 12,
  },
  commentComponent: {
    background: "#D3D3D3",
    fontFamily: `${FONT_NAME}`,
    fontSize: 10,
    textAlign: "center",
  },
};

interface timeLineComponentInterface {
  timeLine: timeLineEvent[];
}
export default function maintenanceTimeLineComponent(
  props: timeLineComponentInterface
) {
  const { timeLine } = props;

  return (
    <Box m={1} display="inline-block">
      <Timeline>
        {timeLine.map((item, index) => {
          return (
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {item.time}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: "#519BD0" }}>
                  {item.event === "created" && <ScheduleIcon />}
                  {item.event === "comment" && <ChatIcon />}
                  {item.event === "attachment" && <AttachFileIcon />}
                  {item.event === "closed" && <CheckIcon />}
                  {item.event === "inProgress" && <BuildIcon />}
                  {item.event === "escalated" && <PriorityHighIcon />}
                </TimelineDot>
                {index === timeLine.length - 1 ? null : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3}>
                  <Box m={1} p={1}>
                    <div style={styles.msgComponent}>{item.message}</div>
                    {item.event === "comment" ? (
                      <Box
                        m={1}
                        p={1}
                        borderRadius={5}
                        sx={styles.commentComponent}
                      >
                        {item.comment}
                      </Box>
                    ) : null}
                    {item.event === "attachment" ? (
                      <div style={{ textAlign: "center" }}>
                        <Box m={1} p={1} bgcolor="#D3D3D3" borderRadius={5}>
                          <ModalImage
                            small={item.src}
                            large={item.src}
                            alt={item.message}
                          />
                        </Box>
                      </div>
                    ) : null}
                  </Box>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
}
