//@ts-nocheck
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  TextField,
} from "@mui/material";
import AlertConditionContainer from "../AlertComponents/AlertConditionContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FONT_NAME } from "../../core-components/theme/theme";

function TriggerResolvation({
  task,
  indexTask,
  sensorList,
  configVarList,
  boolSensorTagList,
  updateDirectValue,
  updateCondition,
  deleteAlertStatement,
  deleteConditionSet,
  addAlertStatement,
  addConditionSet,
}) {
  return (
    <>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              font: `${FONT_NAME}`,
              fontSize: 20,
            }}
          >
            Trigger and Resolvation Logic
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box m={1} style={{ width: "100%" }}>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              style={{ marginTop: "30px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div
                  style={{
                    font: `${FONT_NAME}`,
                    fontSize: 20,
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  Trigger Conditions
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Paper>
                  <Box m={1} p={1}>
                    <AlertConditionContainer
                      conditionSet={task.observationConditions}
                      plantSensorList={sensorList}
                      configVarList={configVarList}
                      alertIndex={indexTask}
                      updateCondition={updateCondition}
                      deleteAlertStatement={deleteAlertStatement}
                      deleteConditionSet={deleteConditionSet}
                      addAlertStatement={addAlertStatement}
                      addConditionSet={addConditionSet}
                      boolSensorTagList={boolSensorTagList}
                      conditionType={"observationConditions"}
                    />
                  </Box>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            style={{ marginTop: "30px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  font: `${FONT_NAME}`,
                  fontSize: 20,
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Resolution Conditions
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Paper>
                <Box m={1} p={1}>
                  <AlertConditionContainer
                    conditionSet={task.resolutionConditions}
                    plantSensorList={sensorList}
                    configVarList={configVarList}
                    alertIndex={indexTask}
                    updateCondition={updateCondition}
                    deleteAlertStatement={deleteAlertStatement}
                    deleteConditionSet={deleteConditionSet}
                    addAlertStatement={addAlertStatement}
                    addConditionSet={addConditionSet}
                    boolSensorTagList={boolSensorTagList}
                    conditionType={"resolutionConditions"}
                  />
                </Box>
              </Paper>
            </AccordionDetails>
          </Accordion>

          <Paper style={{ marginTop: "50px" }}>
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={task["dueTimeFromPosting"]}
                label="OverDue Time"
                onChange={(e) => {
                  updateDirectValue("dueTimeFromPosting", e.target.value);
                }}
              />
            </Box>
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={task["observationFreq"]}
                label="Observation Frequency"
                type="number"
                onChange={(e) => {
                  updateDirectValue("observationFreq", e.target.value);
                }}
              />
            </Box>
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={task["resolutionFreq"]}
                label="Resolution Frequency"
                type="number"
                onChange={(e) => {
                  updateDirectValue("resolutionFreq", e.target.value);
                }}
              />
            </Box>
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={task["group"]}
                label="Alert Group"
                onChange={(e) => {
                  updateDirectValue("group", e.target.value);
                }}
              />
            </Box>
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={task["priority"]}
                label="Priority"
                type="number"
                onChange={(e) => {
                  updateDirectValue("priority", e.target.value);
                }}
              />
            </Box>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default TriggerResolvation;
