import { useState } from "react";
import {
  TextField,
  CircularProgress,
  ThemeProvider,
  Button,
  Box,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router";
import dpTheme from "../../styles/theme/dpTheme";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { changePasswordToken } from "../../services/Login";
import { FONT_NAME } from "../../core-components/theme/theme";

const styles = {
  container: {
    minWidth: "300px",
  },
  formHeading: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#183650",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    fontSize: "14px",
    color: "#999999",
  },
  gotoLink: {
    fontSize: "14px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

// interface LoginForm {
//   email: string;
//   password: string;
// }

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

interface ResetComponentProps {
  resetStateToError: () => void;
}

export default function PasswordAndConfirmPassword({
  resetStateToError,
}: ResetComponentProps) {
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formSchema = yup.object().shape({
    newPassword: yup.string().required("New Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), ""], "Passwords must match"),
  });

  const form = useForm<ResetPasswordForm>({
    resolver: yupResolver(formSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (formData: ResetPasswordForm) => {
    setLoading(true);
    if (token) {
      changePasswordToken(
        token,
        formData.newPassword,
        formData.confirmPassword
      ).then((data: any) => {
        console.log(data);
        if (data.data.success) {
          setLoading(false);
          navigate("/Login");
        } else {
          resetStateToError();
        }
      });
    } else {
      resetStateToError();
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <Box sx={styles.container}>
        {/* <FormControl fullWidth={true}> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" alignItems="flex-start" gap={3}>
            <Box sx={styles.formHeading}>Password Reset</Box>
            <TextField
              required
              fullWidth
              id="newPassword"
              label="New Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                style: { fontFamily: `${FONT_NAME}` },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword((prevSatate) => !prevSatate);
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors.newPassword ? true : false}
              helperText={errors.newPassword?.message}
              {...register("newPassword")}
            />
            <TextField
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                style: { fontFamily:  `${FONT_NAME}`},
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword((prevSatate) => !prevSatate);
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors.confirmPassword ? true : false}
              helperText={errors.confirmPassword?.message}
              {...register("confirmPassword")}
            />
            <Button fullWidth variant="contained" type="submit">
              Reset
            </Button>
            <Box sx={styles.gotoLink}>
              <Link to="/Login">Log In</Link>
            </Box>
          </Stack>
        </form>
        {/* </FormControl> */}
        <ToastContainer />
        {loading ? (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </ThemeProvider>
  );
}
