import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RedAlertIcon from "../../assets/icons/RedAlertIcon.svg";
import GreenBellIcon from "../../assets/icons/GreenBellIcon.svg";
import { FONT_NAME } from "../../core-components/theme/theme";

interface NotificationCardProps {
  read?: boolean;
  title?: string;
  message?: string;
  to?: string;
  timestamp?: string;
  isCritical?: boolean;
  plantId?: string;
  notificationId: string;
  markNoticatifactionasRead: any;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  read = false,
  title = "",
  message = "",
  to = "",
  timestamp = "",
  isCritical = false,
  plantId = "",
  notificationId = "",
  markNoticatifactionasRead,
}) => {
  const [expanded, setExpanded] = useState(false);
  const backgroundColor = isCritical ? "#FBE0CA" : "#C1E561";
  const cardBackgroundColor = read ? "#fff" : "#F0F8FE";
  const styles: { [key: string]: React.CSSProperties } = {
    avatar: {
      background: backgroundColor,
      width: 36,
      height: 36,
      borderRadius: 3,
    },
    card: {
      background: cardBackgroundColor,
      width: "100%",
      borderRadius: 0,
      borderBottom: "2px solid #F2F2F2",
      boxShadow: "none",
      padding: "0px",
    },
    title: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#000",
      fontFamily: `${FONT_NAME}`,
      letterSpacing: "0px",
      display: expanded ? "block" : "-webkit-box",
      WebkitLineClamp: expanded ? "unset" : 1,
      WebkitBoxOrient: "vertical",
      marginBottom: "4px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    message: {
      fontSize: "11px",
      fontFamily: `${FONT_NAME}`,
      letterSpacing: "0px",
      overflow: "hidden",
      color: "#000",
      textOverflow: "ellipsis",
      display: expanded ? "block" : "-webkit-box",
      WebkitLineClamp: expanded ? "unset" : 2,
      WebkitBoxOrient: "vertical",
      marginBottom: "4px",
    },
    timestamp: {
      display: "flex",
      letterSpacing: "0px",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "10px",
      fontFamily: `${FONT_NAME}`,
      opacity: "0.69",
      marginBottom: "4px",
      textAlign: "left",
    },
  };

  const icon = isCritical ? (
    <img src={RedAlertIcon} alt="Critical Alert" />
  ) : (
    <img src={GreenBellIcon} alt="Normal Alert" />
  );
  const avatar = <Avatar sx={styles.avatar}>{icon}</Avatar>;
  return (
    <Card
      style={styles.card}
      onClick={async () => {
        setExpanded(!expanded);
      }}
      onDoubleClick={async () => {
        if (!read) {
          await markNoticatifactionasRead(
            plantId,
            notificationId,
            "stay on same page"
          );
        }
      }}
    >
      <CardHeader
        avatar={avatar}
        title={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={styles.title}>{title}</span>
            <span style={styles.message}>{message}</span>
            <div style={styles.timestamp}>
              <span>{timestamp}</span>
              <ExpandMoreIcon
                style={{
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </div>
          </div>
        }
        titleTypographyProps={{
          variant: "body2",
          color: read ? "textSecondary" : "textPrimary",
        }}
        subheaderTypographyProps={{
          variant: "body2",
          color: read ? "textSecondary" : "textPrimary",
        }}
        sx={{
          alignItems: "flex-start",
          //make padding top 16px and bottom 0 and left and right 16px
          padding: "16px 16px 0px 16px",
        }}
      />
    </Card>
  );
};
export default NotificationCard;
