export default function ShareDashboardIcon({
  fillColor = '#183650',
  width = '9',
  height = '17',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8V5L24 12L17 19V16L21 12L17 8ZM11 9V5L18 12L11 19V14.9C6 14.9 2.5 16.5 0 20C1 15 4 10 11 9Z"
        fill={fillColor}
      />
    </svg>
  );
}
