import { useEffect } from 'react';
import LayoutComponent from '../components/LayoutComponent';
import { Box, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import FormulaViewComponent from '../components/FormulaViewComponent';
import {
  globalVariableInterface,
  globalFormulaInterface,
  customFormulaInterface,
  formulaDataForPlant,
} from '../Interfaces/formulaConfigInterfaces';
import { checkAndSetFormulas, getCustomFormulasList } from '../services/formulaConfig';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import { Stack } from '@mui/system';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';
import { FONT_NAME } from '../core-components/theme/theme';

const styles = {
  pageHeading: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 30,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  formulaTypeHeading: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 24,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
};

export default function FormulaConfiguration() {
  type pageParams = {
    id: string;
  };

  const { id } = useParams<pageParams>();

  const [globalFormulaList, setGlobalFormulaList] = useState<globalFormulaInterface[]>([]);
  const [globalVariableList, setGlobalVariableList] = useState<globalVariableInterface[]>([]);
  const [customFormulaList, setCustomFormulaList] = useState<customFormulaInterface[]>([]);
  const [formulaDataForPlant, setFormulaDataForPlant] = useState<formulaDataForPlant | null>(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCustomFormulasList(id!).then((data: formulaDataForPlant) => {
      console.log('data : ', data);
      if (data) {
        console.log('formulaData : ', data);
        //if global formula list is not empty then set the state else set it to empty array
        if (data.globalFormulas && data.globalFormulas.length > 0) {
          setGlobalFormulaList(data.globalFormulas);
        } else {
          setGlobalFormulaList([]);
        }

        if (data.globalVariables && data.globalVariables.length > 0) {
          setGlobalVariableList(data.globalVariables);
        } else {
          setGlobalVariableList([]);
        }

        if (data.customFormulas && data.customFormulas.length > 0) {
          setCustomFormulaList(data?.customFormulas);
        } else {
          setCustomFormulaList([]);
        }

        setFormulaDataForPlant(data);
      } else {
        setGlobalFormulaList([]);
        setGlobalVariableList([]);
        setCustomFormulaList([]);
      }
      setLoading(false);
    });
  }, [id]);

  const handleSubmit = async () => {
    console.log('handle submit button pressed');
    if (formulaDataForPlant != null) {
      let dataForPlant = formulaDataForPlant;
      dataForPlant['customFormulas'] = customFormulaList;
      dataForPlant['globalVariables'] = globalVariableList;
      console.log(dataForPlant);
      checkAndSetFormulas(id!, dataForPlant)
        .then(data => {
          console.log(data);
          if (data.res === 'OK') {
            toast.success('Formulas successfully saved !');
          } else {
            data.res.forEach((element: string) => {
              toast.error(element);
            });
          }
        })
        .catch();
    }
  };
  const handleNavigate = () => {
    navigate(`/DosingConfiguration/${id}`);
  };
  const addNewFormula = () => {
    let formulaList = customFormulaList;
    let count;
    if (formulaList.length === 0) {
      count = 1000;
    } else {
      formulaList.sort(function (a, b) {
        if (a.priorityNum - b.priorityNum >= 0) return 1;
        else return -1;
      });
      let index = formulaList.length - 1;
      count = formulaList[index]['priorityNum'] + 1;
    }

    formulaList.push({
      lhs: '',
      rhs: '',
      variableList: [],
      type: 'Custom Formula',
      tag: '',
      priorityNum: count,
      isVirtualSensor: false,
    });
    formulaList.sort(function (a, b) {
      if (a.priorityNum - b.priorityNum >= 0) return 1;
      else return -1;
    });
    setCustomFormulaList(formulaList);
    setRefresh(!refresh);
  };

  const updateFormulaValue = (
    formulaType: string,
    index: number,
    part: 'lhs' | 'rhs' | 'tag',
    value: any
  ) => {
    let formulaList: globalVariableInterface[] | customFormulaInterface[] = [];
    if (formulaType === 'Global Variable') {
      formulaList = globalVariableList;
      formulaList[index][part] = value;
      setGlobalVariableList(formulaList);
    } else if (formulaType === 'Custom Formula') {
      formulaList = customFormulaList;
      if (part === 'tag') {
        let underScoreIndex = formulaList[index]['lhs'].indexOf('_') + 1;
        let plainFormula = formulaList[index]['lhs'].slice(underScoreIndex);
        formulaList[index]['lhs'] = value + '_' + plainFormula;
        formulaList[index][part] = value;
      } else {
        formulaList[index][part] = value;
      }
      let rhs = formulaList[index]['rhs'];
      if (rhs.toString().includes('~')) {
        formulaList[index]['isVirtualSensor'] = false;
        formulaList[index]['sensorType'] = 'Change Of';
      } else {
        if (formulaList[index]['isVirtualSensor']) {
          formulaList[index]['sensorType'] = 'Virtual Sensor';
        } else {
          formulaList[index]['sensorType'] = 'Intermediary';
        }
      }
      setCustomFormulaList(formulaList);
    }

    setRefresh(!refresh);
  };

  const removeFormula = (formulaType: string, index: number) => {
    let formulaList: customFormulaInterface[] | globalVariableInterface[] = [];
    if (formulaType === 'Custom Formula') {
      formulaList = customFormulaList;
      formulaList.splice(index, 1);
      setCustomFormulaList(formulaList);
    } else if (formulaType === 'Global Variable') {
      formulaList = globalVariableList;
      formulaList.splice(index, 1);
      setGlobalVariableList(formulaList);
    }

    setRefresh(!refresh);
  };

  if (loading) return <div>loading .....</div>;
  else if (!loading && formulaDataForPlant)
    return (
      <LayoutComponent plantId={id} factoryResetContainer={true}>
        <Box m={1} p={1}>
          <div style={styles.pageHeading}>Custom Formula Configuration</div>

          <Box m={1} p={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <span style={styles.formulaTypeHeading}> Custom Formulas</span>
              <span>
                <PrimaryButton onClick={addNewFormula}>
                  <AddIcon />
                  Add Formula
                </PrimaryButton>
              </span>
            </Stack>
            {customFormulaList.map((formula, index) => {
              return (
                <FormulaViewComponent
                  formula={formula}
                  index={index}
                  updateFormulaValue={updateFormulaValue}
                  removeFormula={removeFormula}
                />
              );
            })}
          </Box>

          <Box m={1} p={1}>
            <div style={styles.formulaTypeHeading}>
              Global Variables
              {/* <span style={{ float: "right" }}>
              <Button onClick={addNewFormula} style={{ background: "#519BD0" }}>
                <AddIcon />
                Add Formula
              </Button>
            </span> */}
            </div>
            {globalVariableList.map((formula, index) => {
              return (
                <FormulaViewComponent
                  formula={formula}
                  index={index}
                  updateFormulaValue={updateFormulaValue}
                  removeFormula={removeFormula}
                />
              );
            })}
          </Box>

          <Box m={1} p={1}>
            <div style={styles.formulaTypeHeading}>
              Global Formulas
              {/* <span style={{ float: "right" }}>
              <Button onClick={addNewFormula} style={{ background: "#519BD0" }}>
                <AddIcon />
                Add Formula
              </Button>
            </span> */}
            </div>
            {globalFormulaList.map((formula, index) => {
              return (
                <FormulaViewComponent
                  formula={formula}
                  index={index}
                  updateFormulaValue={updateFormulaValue}
                  removeFormula={removeFormula}
                />
              );
            })}
          </Box>

          <Box m={1} p={1}>
            <Tooltip title={'Save Configured Formulas'}>
              <PrimaryButton
                style={{ minWidth: '100px', marginRight: '10px' }}
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </Tooltip>
            <Tooltip title={'Navigate to Dosing Configuration'}>
              <PrimaryButton style={{ minWidth: '100px' }} onClick={handleNavigate}>
                Next
              </PrimaryButton>
            </Tooltip>
          </Box>
          <Box
            m={4}
            p={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BottomNavigationComponent plantId={id!} currentPage="Formula" />
          </Box>
        </Box>
        <ToastContainer />
      </LayoutComponent>
    );
  else return null;
}
