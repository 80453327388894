// Create theme using Material UI's createTheme function

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  PrimaryColor,
  SecondaryColor,
  SemanticErrorColor,
  SemanticSuccessColor,
  SemanticWarningColor,
  SemanticInfoColor,
  GreenHighlightColor,
  PremiumColor,
  SludgeColor,
  ButterCupColor,
  ChristineColor,
  GreenyColor,
  HibiscusColor,
  PremiumAddUpColor,
  SmithAppleColor,
  NeutralsColor,
} from './color-palette/color-palette.theme';

import Typography from './typography/typography.theme';
import { Breakpoints, Spacing } from './theme-mislaneous/mislaneous.theme';

declare module '@mui/material/styles' {
  interface Palette {
    Primary: Palette['primary'];
    Secondary: Palette['secondary'];
    SemanticError: Palette['error'];
    SemanticSuccess: Palette['success'];
    SemanticWarning: Palette['warning'];
    SemanticInfo: Palette['info'];
    Neutrals: Palette['grey'];
    GreenHighlight: Palette['primary'];
    Premium: Palette['primary'];
    Sludge: Palette['primary'];
    Christine: Palette['primary'];
    Greeny: Palette['primary'];
    Hibiscus: Palette['primary'];
    PremiumAddUp: Palette['primary'];
    SmithApple: Palette['primary'];
  }

  interface PaletteOptions {
    Primary: PaletteOptions['primary'];
    Secondary: PaletteOptions['secondary'];
    SemanticError: PaletteOptions['error'];
    SemanticSuccess: PaletteOptions['success'];
    SemanticWarning: PaletteOptions['warning'];
    SemanticInfo: PaletteOptions['info'];
    Neutrals: PaletteOptions['grey'];
    GreenHighlight: PaletteOptions['primary'];
    Premium: PaletteOptions['primary'];
    Sludge: PaletteOptions['primary'];
    ButterCup: PaletteOptions['primary'];
    Christine: PaletteOptions['primary'];
    Greeny: PaletteOptions['primary'];
    Hibiscus: PaletteOptions['primary'];
    PremiumAddUp: PaletteOptions['primary'];
    SmithApple: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    Primary: true;
    Secondary: true;
    SemanticError: true;
    SemanticSuccess: true;
    SemanticWarning: true;
    SemanticInfo: true;
    Neutrals: true;
    GreenHighlight: true;
    Premium: true;
    Sludge: true;
    ButterCup: true;
    Christine: true;
    Greeny: true;
    Hibiscus: true;
    PremiumAddUp: true;
    SmithApple: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    Primary: true;
    Secondary: true;
    SemanticError: true;
    SemanticSuccess: true;
    SemanticWarning: true;
    SemanticInfo: true;
    Neutrals: true;
    GreenHighlight: true;
    Premium: true;
    Sludge: true;
    ButterCup: true;
    Christine: true;
    Greeny: true;
    Hibiscus: true;
    PremiumAddUp: true;
    SmithApple: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    Primary: true;
    Secondary: true;
    SemanticError: true;
    SemanticSuccess: true;
    SemanticWarning: true;
    SemanticInfo: true;
    Neutrals: true;
    GreenHighlight: true;
    Premium: true;
    Sludge: true;
    ButterCup: true;
    Christine: true;
    Greeny: true;
    Hibiscus: true;
    PremiumAddUp: true;
    SmithApple: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    highlight: true;
    caption1: true;
    caption2: true;
    label: true;
    body: true;
    header: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    Primary: true;
    Secondary: true;
    SemanticError: true;
    SemanticSuccess: true;
    SemanticWarning: true;
    SemanticInfo: true;
    Neutrals: true;
    GreenHighlight: true;
    Premium: true;
    Sludge: true;
    ButterCup: true;
    Christine: true;
    Greeny: true;
    Hibiscus: true;
    PremiumAddUp: true;
    SmithApple: true;
  }
}

const Theme = responsiveFontSizes(
  createTheme({
    palette: {
      Primary: PrimaryColor,
      Secondary: SecondaryColor,
      SemanticError: SemanticErrorColor,
      SemanticSuccess: SemanticSuccessColor,
      SemanticWarning: SemanticWarningColor,
      SemanticInfo: SemanticInfoColor,
      Neutrals: NeutralsColor,
      GreenHighlight: GreenHighlightColor,
      Premium: PremiumColor,
      Sludge: SludgeColor,
      ButterCup: ButterCupColor,
      Christine: ChristineColor,
      Greeny: GreenyColor,
      Hibiscus: HibiscusColor,
      PremiumAddUp: PremiumAddUpColor,
      SmithApple: SmithAppleColor,
    },
    typography: Typography,
    breakpoints: Breakpoints,
    spacing: Spacing,
  })
);


export default Theme;
export const FONT_NAME = '"Roboto", "Inter", sans-serif';