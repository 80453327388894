import { Typography } from './typogrphy.interface';
import { Neutrals } from '../color-palette/colors';
import { FONT_NAME } from '../theme';

const typography: Typography = {
  fontFamily: '"Roboto", "Inter", sans-serif',
  title: {
    fontWeight: 700,
    fontSize: '30px',
    color: Neutrals[900],
  },
  h1: {
    fontWeight: 500,
    fontSize: '24px',
    color: Neutrals[900],
  },
  h2: {
    fontWeight: 500,
    fontSize: '20px',
    color: Neutrals[900],
  },
  h3: {
    fontWeight: 600,
    fontSize: '16px',
    color: Neutrals[900],
  },
  h4: {
    fontWeight: 400,
    fontSize: '14px',
    color: Neutrals[900],
  },
  body: {
    fontSize: '16px',
    fontWeight: 400,
    color: Neutrals[900],
  },
  highlight: {
    fontSize: '14px',
    fontWeight: 600,
    color: Neutrals[900],
  },
  info: {
    fontSize: '14px',
    fontWeight: 300,
    color: Neutrals[900],
  },
  caption1: {
    fontWeight: 400,
    fontSize: '10px',
    color: Neutrals[700],
  },
  caption2: {
    fontWeight: 400,
    fontSize: '12px',
    color: Neutrals[700],
  },
  label: {
    fontWeight: 400,
    fontSize: '13px',
    color: Neutrals[700],
  },
  header: {
    fontWeight: 500,
    fontSize: '14px',
    color: Neutrals[900],
  },
};

export default typography as Typography;
