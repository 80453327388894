//@ts-nocheck
import { Box, TextField, Button, Menu } from "@mui/material";
import { useEffect, useState } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select from "@mui/material/Select";
import axiosInstance from "../../constants/axiosInstance";
// import { Heading } from "@chakra-ui/theme/dist/declarations/src/components";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { FONT_NAME } from "../../core-components/theme/theme";
export default function CreateIssuePopup(props: any) {
  const {
    plants,
    setOpenCreateNewIssuePopup,
    refreshStateOfParent,
    setRefreshStateOfParent,
  } = props;
  const [problemHeading, setProblemHeading] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [equipments, setEquipments] = useState<any>([]);
  const [selectedPlant, setSelectedPlant] = useState(plants[0]);
  const [selectedIssueType, setSelectedIssueType] = useState([]);
  const [loadingCreateTicketResponse, setLoadingCreateTicketResponse] =
    useState(false);
  const issueType = [
    "Admin",
    "Electrical",
    "Lab",
    "Process",
    "Dp System",
    "Mechanical",
  ];

  useEffect(() => {
    axiosInstance()
      .get(`/tasks/getEquipments?id=${selectedPlant.plantId}`)
      .then((data: any) => {
        console.log("equiments:", data);
        setEquipments(data.data.equipments);
        console.log("equiments:", data.data.equipments);
      });
  }, [selectedPlant]);

  function handleSubmit() {
    // create a manual alert
    setLoadingCreateTicketResponse(true);
    axiosInstance()
      .post(`/newAlerts/createManualAlert?plantId=${selectedPlant.plantId}`, {
        heading: problemHeading,
        description: problemDescription,
        equipments: selectedEquipment,
        issueType: selectedIssueType,
      })
      .then((data) => {
        console.log("data ticket: ", data);
        setLoadingCreateTicketResponse(true);
        toast(data.data.msg);
        setOpenCreateNewIssuePopup(false);
        setRefreshStateOfParent(!refreshStateOfParent);
      })
      .catch(() => {
        setLoadingCreateTicketResponse(true);
      });
  }

  const createIssueBoxStyle = {
    position: "fixed",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccccff",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    background: "white",
    fontFamily: `${FONT_NAME}`,
  };
  return (
    <Box p={2} style={createIssueBoxStyle}>
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>
        <span>Create New Ticket</span>
        <span style={{ float: "right" }}>
          <CloseIcon
            fontSize="medium"
            onClick={() => {
              setOpenCreateNewIssuePopup(false);
            }}
          />
        </span>
      </div>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          fullWidth
          required
          id="outlines-basic"
          label="Problem Statement"
          variant="outlined"
          placeholder="Your problem statement here!"
          value={problemHeading}
          margin="none"
          size="small"
          inputProps={{
            disableUnderline: true,
            style: { fontSize: 12 },
          }}
          InputLabelProps={{
            style: { fontSize: 12 },
          }}
          onChange={(event) => {
            setProblemHeading(event.target.value);
          }}
        />
        <TextField
          fullWidth
          multiline
          minRows={3}
          id="outlines-basic"
          label="Problem Description (Optional)"
          variant="outlined"
          placeholder="Your problem description here!"
          value={problemDescription}
          margin="none"
          size="small"
          inputProps={{
            disableUnderline: true,
            style: { fontSize: 12 },
          }}
          InputLabelProps={{
            style: { fontSize: 12 },
          }}
          onChange={(event) => {
            setProblemDescription(event.target.value);
          }}
        />
        {plants.length > 1 && (
          <FormControl>
            <InputLabel
              id="demo-simple-select-label-0"
              style={{ fontSize: 12 }}
            >
              Plant
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-0"
              id="demo-simple-select-0"
              label="Plant"
              value={selectedPlant}
              renderValue={(value) =>
                value.plantName !== "" ? value.plantName : value.plantId
              }
              onChange={(e) => setSelectedPlant(e.target.value)}
            >
              {plants?.map((plant) => (
                <MenuItem value={plant}>{plant?.plantName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth style={{ fontSize: 12 }}>
          <InputLabel id="demo-simple-select-label" style={{ fontSize: 12 }}>
            Equipment
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedEquipment}
            label="Equipment"
            inputProps={{
              disableUnderline: true,
              style: { fontSize: 12 },
            }}
            renderValue={(value) =>
              value.nickName !== "" ? value.nickName : value.id
            }
            onChange={(e) => setSelectedEquipment(e.target.value)}
          >
            {equipments.map((equipment: any) => {
              return (
                <MenuItem value={equipment}>
                  {equipment.nickName !== ""
                    ? equipment.nickName
                    : equipment.id}
                </MenuItem>
              );
              // return ( <MenuItem value={equipment}>{equipment.name}</MenuItem>)
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ fontSize: 12 }}>
          <InputLabel id="demo-simple-select-label" style={{ fontSize: 12 }}>
            Issue Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={selectedIssueType}
            label="Equipment"
            inputProps={{
              disableUnderline: true,
              style: { fontSize: 12 },
            }}
            onChange={(e) => setSelectedIssueType(e.target.value)}
          >
            {issueType.map((issue: any) => {
              return <MenuItem value={issue}>{issue}</MenuItem>;
              // return ( <MenuItem value={equipment}>{equipment.name}</MenuItem>)
            })}
          </Select>
        </FormControl>
        <Button
          type="submit"
          disabled={loadingCreateTicketResponse}
          style={{
            textTransform: "none",
            background: "#C1E561",
            fontSize: "14px",
            color: "#053F5C",
            fontFamily: `${FONT_NAME}`,
          }}
          variant="contained"
          onClick={() => {}}
        >
          {loadingCreateTicketResponse ? (
            <CircularProgress
              style={{ color: "#053F5C", width: "22px", height: "22px" }}
            />
          ) : (
            "Create"
          )}
        </Button>
      </form>
    </Box>
  );
}
