import { memo, useEffect, useState } from "react";
import TaskCardLightVersion from "../TaskCardLightVersion/TaskCardLightVersion";
import { Box, Grid } from "@mui/material";
import imageForTaskList from "../../constants/Images/imageForTaskList.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router";
import { taskListSplitIntoCategories } from "../../Interfaces/dashboardPageInterfaces";
import { FONT_NAME } from "../../core-components/theme/theme";

const styles = {
  taskViewContainer: {
    height: "100%",
    width: "inherit",
    display: "flex",
    flexDirection: "column",
  },
  taskViewHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "35px",
  },
  taskViewHeading: {
    fontWeight: "bolder",
    fontFamily: `${FONT_NAME}`,
    color: "#333539",
    fontSize: "clamp(18px, 1.2vw  ,27px)",
  },
  viewAllButton: {
    display: "flex",
    alignItems: "center",
    color: "#4D4D4D",
    background: "#EFF3FB",
    fontSize: "clamp(14px, 0.9vw  ,21px)",
  },
  noPendingTaskView: {
    color: "#555",
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
};

interface propsInterface {
  tasks: taskListSplitIntoCategories;
}

const TaskViewForDp = memo((props: propsInterface) => {
  const navigate = useNavigate();
  //@ts-ignore
  const [tasks, setTasks] = useState<taskListSplitIntoCategories>(props.tasks);
  const [taskCount, setTaskCount] = useState(0);

  useEffect(() => {
    let totalCount: number = 0;
    totalCount = totalCount + tasks.overDueTask.length;
    totalCount = totalCount + tasks.pendingTask.length;
    // totalCount = totalCount + tasks.completedTask.length;
    // totalCount = totalCount + tasks.completedAfterOverDueTask.length

    setTaskCount(totalCount);
  }, []);

  return (
    <>
      <Box sx={styles.taskViewContainer}>
        <Box sx={styles.taskViewHeader}>
          <p style={styles.taskViewHeading}>Upcoming Tasks</p>
          <Box
            p={1}
            borderRadius={3}
            style={styles.viewAllButton}
            onClick={() => {
              navigate("/task-list");
            }}
          >
            View All{" "}
            <ChevronRightIcon style={{ color: "#4D4D4D" }}></ChevronRightIcon>
          </Box>
        </Box>
        {taskCount ? (
          <Grid item xs={12} sx={{ marginBottom: "30px" }}>
            {tasks?.overDueTask?.map((task) => (
              <TaskCardLightVersion
                task={task}
                language="English"
                taskStatus="overDue"
              />
            ))}
            {tasks?.pendingTask?.map((task) => (
              <TaskCardLightVersion
                task={task}
                language="English"
                taskStatus="pending"
              />
            ))}
          </Grid>
        ) : (
          <Box style={styles.noPendingTaskView}>
            <p> No pending tasks for you!</p>
          </Box>
        )}
        <img
          src={imageForTaskList}
          style={{ width: "90%", marginTop: "auto" }}
        ></img>
      </Box>
    </>
  );
})

export default TaskViewForDp;
