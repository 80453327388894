// hooks/usePermissionCheck.js
import { useState, useEffect } from 'react';
import { checkGlobalPermission } from '../../services/checkGlobalPermission';

const usePermissionCheck = (permissionTag: string, assetId?: string) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermission = async () => {
      setLoading(true);
      const result = await checkGlobalPermission({ assetId, permissionTag });
      setHasPermission(result.data);
      setLoading(false);
    };

    fetchPermission();
  }, [assetId, permissionTag]);

  return { hasPermission, loading };
};

export default usePermissionCheck;
