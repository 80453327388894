// flocData[currentShiftIndex].samples[currentSampleIndex]?.metrics?.metricData
const flocVisualizationData = {
  _id: '66e03405c91add12d2dddc49',
  widgetId: 'Floc Detector',
  widgetName: 'Floc Detector',
  widgetNickName: 'BioHealthTrack',
  widgetType: 'flocDetector',
  graphType: 'flocDetector',
  widgetCategory: 'Water Quantity and Availability',
  widgetDescription: '',
  moreInfo: 'link',
  checked: true,
  keyWords: ['FD', 'FDST'],
  widgetOrigin: 'flocDetector',
  widgetOrder: 1,
  metrics: {
    'SVOL60 Sensor': {
      checked: true,
      sensorId: '66e02783575ed85954f49992',
      dataType: 'number',
      sensorTag: 'SVOL60 Sensor',
    },
    'Sludge Volume Sensor': {
      checked: true,
      sensorId: '66e02783575ed85954f49996',
      dataType: 'number',
      sensorTag: 'Sludge Volume Sensor',
    },
    'SVOL30 Sensor': {
      checked: true,
      sensorId: '66e02783575ed85954f49995',
      dataType: 'number',
      sensorTag: 'SVOL30 Sensor',
    },
    'SVOL90 Sensor': {
      checked: true,
      sensorId: '66e02783575ed85954f49997',
      dataType: 'number',
      sensorTag: 'SVOL90 Sensor',
    },
    'SVOL Custom Sensor': {
      checked: true,
      sensorId: '66e02783575ed85954f4999a',
      dataType: 'number',
      sensorTag: 'SVOL Custom Sensor',
    },
    'SVOL Issue Detection Sensor': {
      checked: true,
      sensorId: '66e02783575ed85954f4999c',
      dataType: 'number',
      sensorTag: 'SVOL Issue Detection Sensor',
    },
  },
  widgetOriginId: '65d7116e367c3c118b3bcae3',
  dashboardPageId: '6605afba3ea145ec9b2b96e1',
  specialTag: 'FDST_1:DBPL_WSW',
  shiftDetails: [
    {
      serialNumber: '1',
      shiftName: 'test12',
      startTime: '2024-04-22T03:00:13.569Z',
      endTime: '2024-04-22T09:00:13.569Z',
      _id: '6707cc13c7142c6f6ea76137',
    },
    {
      serialNumber: '2',
      shiftName: 'test21',
      startTime: '2024-04-22T09:00:13.569Z',
      endTime: '2024-04-22T15:00:13.569Z',
      _id: '6707cc13c7142c6f6ea76138',
    },
    {
      serialNumber: '3',
      shiftName: 'test34',
      startTime: '2024-04-22T15:00:13.569Z',
      endTime: '2024-04-22T21:00:13.569Z',
      _id: '6707cc13c7142c6f6ea76139',
    },
    {
      serialNumber: '4',
      shiftName: 'test43',
      startTime: '2024-04-22T21:00:13.569Z',
      endTime: '2024-04-22T03:00:13.569Z',
      _id: '6707cc13c7142c6f6ea7613a',
    },
  ],
  timeRange: ['30Mark', '60Mark', '90Mark'],
  advancedWidgetSettings: {
    applyCustomSettings: true,
  },
  customTimeRangeSettings: {
    unit: 'days',
    unitMultiplier: 0,
    timeRangeInMins: 1440,
    timeRangeType: 'absolute',
    startTime: '2024-04-21T18:30:00.000Z',
    endTime: '2024-04-22T18:30:00.000Z',
  },
  showAllSample: true,
  __v: 0,
  metricsData: {
    labels: ['23Apr 12:00am'],
    datasets: [
      {
        data: [],
        pointBackgroundColor: [],
        label: 'SVOL60 Sensor',
        id: '66e02783575ed85954f49992',
        borderColor: '#36A2C7',
        backgroundColor: '#36A2C7',
        pointStyle: 'circle',
        pointRadius: 3.5,
        fill: false,
        lastTimeLabel: '23Apr 12:00am',
      },
      {
        data: [],
        pointBackgroundColor: [],
        label: 'Sludge Volume Sensor',
        id: '66e02783575ed85954f49996',
        borderColor: '#C1E561',
        backgroundColor: '#C1E561',
        pointStyle: 'circle',
        pointRadius: 3.5,
        fill: false,
        lastTimeLabel: '23Apr 12:00am',
      },
      {
        data: [],
        pointBackgroundColor: [],
        label: 'SVOL30 Sensor',
        id: '66e02783575ed85954f49995',
        borderColor: '#053F5C',
        backgroundColor: '#053F5C',
        pointStyle: 'circle',
        pointRadius: 3.5,
        fill: false,
        lastTimeLabel: '23Apr 12:00am',
      },
      {
        data: [],
        pointBackgroundColor: [],
        label: 'SVOL90 Sensor',
        id: '66e02783575ed85954f49997',
        borderColor: '#98C1AF',
        backgroundColor: '#98C1AF',
        pointStyle: 'circle',
        pointRadius: 3.5,
        fill: false,
        lastTimeLabel: '23Apr 12:00am',
      },
      {
        data: [],
        pointBackgroundColor: [],
        label: 'SVOL Custom Sensor',
        id: '66e02783575ed85954f4999a',
        borderColor: '#E96C7C',
        backgroundColor: '#E96C7C',
        pointStyle: 'circle',
        pointRadius: 3.5,
        fill: false,
        lastTimeLabel: '23Apr 12:00am',
      },
      {
        data: [],
        pointBackgroundColor: [],
        label: 'SVOL Issue Detection Sensor',
        id: '66e02783575ed85954f4999c',
        borderColor: '#458B74',
        backgroundColor: '#458B74',
        pointStyle: 'circle',
        pointRadius: 3.5,
        fill: false,
        lastTimeLabel: '23Apr 12:00am',
      },
    ],
    labelsWithYear: [
      '23Apr 2024 12:00am',
      '23Apr 2024 12:00am',
      '23Apr 2024 12:00am',
      '23Apr 2024 12:00am',
      '23Apr 2024 12:00am',
      '23Apr 2024 12:00am',
    ],
  },
  flocData: [
    [
      {
        serialNumber: '1',
        shiftName: 'test12',
        startTime: '2024-04-22T03:00:13.569Z',
        endTime: '2024-04-22T09:00:13.569Z',
        _id: '6707cc13c7142c6f6ea76137',
        totalSamples: 3,
        samples: [
          {
            eventId: '6628f4b3e1bd77ea84dfb04b',
            startTime: '2024-04-22T03:32:45.830Z',
            endTime: '2024-04-22T06:00:00.000Z',
            default: true,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {
                '0': {
                  value: 100,
                },
                '1': {
                  value: 99.55,
                },
                '2': {
                  value: 99.1,
                },
                '3': {
                  value: 98.65,
                },
                '4': {
                  value: 98.2,
                },
                '5': {
                  value: 97.75,
                },
                '6': {
                  value: 97.3,
                },
                '7': {
                  value: 96.85,
                },
                '8': {
                  value: 96.4,
                },
                '9': {
                  value: 95.95,
                },
                '10': {
                  value: 95.5,
                },
                '11': {
                  value: 95.05,
                },
                '12': {
                  value: 94.6,
                },
                '13': {
                  value: 94.15,
                },
                '14': {
                  value: 93.7,
                },
                '15': {
                  value: 93.25,
                },
                '16': {
                  value: 92.8,
                },
                '17': {
                  value: 92.35,
                },
                '18': {
                  value: 91.9,
                },
                '19': {
                  value: 91.45,
                },
                '20': {
                  value: 91,
                },
                '21': {
                  value: 90.55,
                },
                '22': {
                  value: 90.1,
                },
                '23': {
                  value: 89.65,
                },
                '24': {
                  value: 89.2,
                },
                '25': {
                  value: 88.75,
                },
                '26': {
                  value: 88.3,
                },
                '27': {
                  value: 87.85,
                },
                '28': {
                  value: 87.4,
                },
                '29': {
                  value: 86.95,
                },
                '30': {
                  value: 86.5,
                  imageId: '6631049c4398b43e0f2ef608',
                  thumbnail:
                    'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
                },
                '31': {
                  value: 86.05,
                },
                '32': {
                  value: 85.6,
                },
                '33': {
                  value: 85.15,
                },
                '34': {
                  value: 84.7,
                },
                '35': {
                  value: 84.25,
                },
                '36': {
                  value: 83.8,
                },
                '37': {
                  value: 83.35,
                },
                '38': {
                  value: 82.9,
                },
                '39': {
                  value: 82.45,
                },
                '40': {
                  value: 82,
                },
                '41': {
                  value: 81.55,
                },
                '42': {
                  value: 81.1,
                },
                '43': {
                  value: 80.65,
                },
                '44': {
                  value: 80.2,
                },
                '45': {
                  value: 79.75,
                  imageId: '666c2a92b507000266f3ad75',
                  issueIcon:
                    'https://digitalpaani-floc-detector-images.s3.ap-south-1.amazonaws.com/icons/floatingSludge.svg',
                },
                '46': {
                  value: 79.3,
                },
                '47': {
                  value: 78.85,
                },
                '48': {
                  value: 78.4,
                },
                '49': {
                  value: 77.95,
                },
                '50': {
                  value: 77.5,
                },
                '51': {
                  value: 77.05,
                },
                '52': {
                  value: 76.6,
                },
                '53': {
                  value: 76.15,
                },
                '54': {
                  value: 75.7,
                },
                '55': {
                  value: 75.25,
                },
                '56': {
                  value: 74.8,
                },
                '57': {
                  value: 74.35,
                },
                '58': {
                  value: 73.9,
                },
                '59': {
                  value: 73.45,
                },
                '60': {
                  value: 73,
                  imageId: '6638dbae8fbf85d9f768dd36',
                  thumbnail:
                    'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
                },
                '61': {
                  value: 72.55,
                },
                '62': {
                  value: 72.1,
                },
                '63': {
                  value: 71.65,
                },
                '64': {
                  value: 71.2,
                },
                '65': {
                  value: 70.75,
                },
                '66': {
                  value: 70.3,
                },
                '67': {
                  value: 69.85,
                },
                '68': {
                  value: 69.4,
                },
                '69': {
                  value: 68.95,
                },
                '70': {
                  value: 68.5,
                },
                '71': {
                  value: 68.05,
                },
                '72': {
                  value: 67.6,
                },
                '73': {
                  value: 67.15,
                },
                '74': {
                  value: 66.7,
                },
                '75': {
                  value: 66.25,
                },
                '76': {
                  value: 65.8,
                },
                '77': {
                  value: 65.35,
                },
                '78': {
                  value: 64.9,
                },
                '79': {
                  value: 64.45,
                },
                '80': {
                  value: 64,
                },
                '81': {
                  value: 63.55,
                },
                '82': {
                  value: 63.1,
                },
                '83': {
                  value: 62.65,
                },
                '84': {
                  value: 62.2,
                },
                '85': {
                  value: 61.75,
                },
                '86': {
                  value: 61.3,
                },
                '87': {
                  value: 60.85,
                },
                '88': {
                  value: 60.4,
                },
                '89': {
                  value: 59.95,
                },
                '90': {
                  value: 59.5,
                  imageId: '66ffd8a830f44af5324ce5f2',
                  thumbnail:
                    'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
                },
                '91': {
                  value: 59.05,
                },
                '92': {
                  value: 58.6,
                },
                '93': {
                  value: 58.15,
                },
                '94': {
                  value: 57.7,
                },
                '95': {
                  value: 57.25,
                },
                '96': {
                  value: 56.8,
                },
                '97': {
                  value: 56.35,
                },
                '98': {
                  value: 55.9,
                },
                '99': {
                  value: 55.45,
                },
                '100': {
                  value: 55,
                },
                '101': {
                  value: 54.55,
                },
                '102': {
                  value: 54.1,
                },
                '103': {
                  value: 53.65,
                },
                '104': {
                  value: 53.2,
                },
                '105': {
                  value: 52.75,
                },
                '106': {
                  value: 52.3,
                },
                '107': {
                  value: 51.85,
                },
                '108': {
                  value: 51.4,
                },
                '109': {
                  value: 50.95,
                },
                '110': {
                  value: 50.5,
                },
                '111': {
                  value: 50.05,
                },
                '112': {
                  value: 49.6,
                },
                '113': {
                  value: 49.15,
                },
                '114': {
                  value: 48.7,
                },
                '115': {
                  value: 48.25,
                },
                '116': {
                  value: 47.8,
                },
                '117': {
                  value: 47.35,
                },
                '118': {
                  value: 46.9,
                },
                '119': {
                  value: 46.45,
                },
                '120': {
                  value: 46,
                },
                '121': {
                  value: 45.55,
                },
                '122': {
                  value: 45.1,
                },
                '123': {
                  value: 44.65,
                },
                '124': {
                  value: 44.2,
                },
                '125': {
                  value: 43.75,
                },
                '126': {
                  value: 43.3,
                },
                '127': {
                  value: 42.85,
                },
                '128': {
                  value: 42.4,
                },
                '129': {
                  value: 41.95,
                },
                '130': {
                  value: 41.5,
                },
                '131': {
                  value: 41.05,
                },
                '132': {
                  value: 40.6,
                },
                '133': {
                  value: 40.15,
                },
                '134': {
                  value: 39.7,
                },
                '135': {
                  value: 39.25,
                },
                '136': {
                  value: 38.8,
                },
                '137': {
                  value: 38.35,
                },
                '138': {
                  value: 37.9,
                },
                '139': {
                  value: 37.45,
                },
                '140': {
                  value: 37,
                },
                '141': {
                  value: 36.55,
                },
                '142': {
                  value: 36.1,
                },
                '143': {
                  value: 35.65,
                },
                '144': {
                  value: 35.2,
                },
                '145': {
                  value: 34.75,
                },
                '146': {
                  value: 34.3,
                },
                '147': {
                  value: 33.85,
                },
                '148': {
                  value: 33.4,
                },
                '149': {
                  value: 32.95,
                },
                '150': {
                  value: 32.5,
                },
                '151': {
                  value: 32.05,
                },
                '152': {
                  value: 31.6,
                },
                '153': {
                  value: 31.15,
                },
                '154': {
                  value: 30.7,
                },
                '155': {
                  value: 30.25,
                },
                '156': {
                  value: 29.8,
                },
                '157': {
                  value: 29.35,
                },
                '158': {
                  value: 28.9,
                },
                '159': {
                  value: 28.45,
                },
                '160': {
                  value: 28,
                },
                '161': {
                  value: 27.55,
                },
                '162': {
                  value: 27.1,
                },
                '163': {
                  value: 26.65,
                },
                '164': {
                  value: 26.2,
                },
                '165': {
                  value: 25.75,
                },
                '166': {
                  value: 25.3,
                },
                '167': {
                  value: 24.85,
                },
                '168': {
                  value: 24.4,
                },
                '169': {
                  value: 23.95,
                },
                '170': {
                  value: 23.5,
                },
                '171': {
                  value: 23.05,
                },
                '172': {
                  value: 22.6,
                },
                '173': {
                  value: 22.15,
                },
                '174': {
                  value: 21.7,
                },
                '175': {
                  value: 21.25,
                },
                '176': {
                  value: 20.8,
                },
                '177': {
                  value: 20.35,
                },
                '178': {
                  value: 19.9,
                },
                '179': {
                  value: 19.45,
                },
                '180': {
                  value: 19,
                },
                '181': {
                  value: 18.55,
                },
                '182': {
                  value: 18.1,
                },
                '183': {
                  value: 17.65,
                },
                '184': {
                  value: 17.2,
                },
                '185': {
                  value: 16.75,
                },
                '186': {
                  value: 16.3,
                },
                '187': {
                  value: 15.85,
                },
                '188': {
                  value: 15.4,
                },
                '189': {
                  value: 14.95,
                },
                '190': {
                  value: 14.5,
                },
                '191': {
                  value: 14.05,
                },
                '192': {
                  value: 13.6,
                },
                '193': {
                  value: 13.15,
                },
                '194': {
                  value: 12.7,
                },
                '195': {
                  value: 12.25,
                },
                '196': {
                  value: 11.8,
                },
                '197': {
                  value: 11.35,
                },
                '198': {
                  value: 10.9,
                },
                '199': {
                  value: 10.45,
                },
              },
            },
          },
          {
            eventId: '6638d6b38fbf85d9f768dd1d',
            startTime: '2024-04-22T06:00:00.000Z',
            endTime: '2024-04-22T09:00:00.000Z',
            default: false,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
          {
            eventId: '66d0a1f97531a640bb0cd914',
            startTime: '2024-04-22T06:00:41.830Z',
            endTime: '2024-04-22T07:30:00.000Z',
            default: false,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
        ],
      },
      {
        serialNumber: '2',
        shiftName: 'test21',
        startTime: '2024-04-22T09:00:13.569Z',
        endTime: '2024-04-22T15:00:13.569Z',
        _id: '6707cc13c7142c6f6ea76138',
        totalSamples: 2,
        samples: [
          {
            eventId: '6638d7e5bd1482ccdbf59181',
            startTime: '2024-04-22T09:01:00.000Z',
            endTime: '2024-04-22T12:00:00.000Z',
            default: false,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
          {
            eventId: '6638d96e8fbf85d9f768dd24',
            startTime: '2024-04-22T12:01:00.000Z',
            endTime: '2024-04-22T15:00:00.000Z',
            default: true,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
        ],
      },
      {
        serialNumber: '3',
        shiftName: 'test34',
        startTime: '2024-04-22T15:00:13.569Z',
        endTime: '2024-04-22T21:00:13.569Z',
        _id: '6707cc13c7142c6f6ea76139',
        totalSamples: 2,
        samples: [
          {
            eventId: '6638d993bd1482ccdbf59187',
            startTime: '2024-04-22T15:01:41.830Z',
            endTime: '2024-04-22T18:00:00.000Z',
            default: false,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
          {
            eventId: '6638d9d68fbf85d9f768dd30',
            startTime: '2024-04-22T18:01:41.830Z',
            endTime: '2024-04-22T21:00:00.000Z',
            default: true,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
        ],
      },
      {
        serialNumber: '4',
        shiftName: 'test43',
        startTime: '2024-04-22T21:00:13.569Z',
        endTime: '2024-04-22T03:00:13.569Z',
        _id: '6707cc13c7142c6f6ea7613a',
        totalSamples: 2,
        samples: [
          {
            eventId: '66c4967a29bfca1ff9608b1f',
            startTime: '2024-04-22T21:01:41.830Z',
            endTime: '2024-04-23T00:00:00.000Z',
            default: false,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
          {
            eventId: '66d081686f20da31853b4310',
            startTime: '2024-04-23T00:01:41.830Z',
            endTime: '2024-04-23T03:00:00.000Z',
            default: false,
            metrics: {
              timeUnit: 'minutes',
              sampleUnit: 'ml',
              metricData: {},
            },
          },
        ],
      },
    ],
    [
      {
        serialNumber: '1',
        shiftName: 'test12',
        startTime: '2024-04-22T03:00:13.569Z',
        endTime: '2024-04-22T09:00:13.569Z',
        _id: '6707cc13c7142c6f6ea76137',
      },
      {
        serialNumber: '2',
        shiftName: 'test21',
        startTime: '2024-04-22T09:00:13.569Z',
        endTime: '2024-04-22T15:00:13.569Z',
        _id: '6707cc13c7142c6f6ea76138',
      },
      {
        serialNumber: '3',
        shiftName: 'test34',
        startTime: '2024-04-22T15:00:13.569Z',
        endTime: '2024-04-22T21:00:13.569Z',
        _id: '6707cc13c7142c6f6ea76139',
      },
      {
        serialNumber: '4',
        shiftName: 'test43',
        startTime: '2024-04-22T21:00:13.569Z',
        endTime: '2024-04-22T03:00:13.569Z',
        _id: '6707cc13c7142c6f6ea7613a',
      },
    ],
  ],
  barGraphData: [
    {
      '22 Apr 2024': {
        test12: {
          'Total Sample': 3,
          'Default Sample': 1,
          '30 minutes': {
            'Detected Time': '07:00 AM',
            value: '86.5 ml',
            thumbnail: {
              url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
              imageId: '6631049c4398b43e0f2ef608',
            },
            floatingSludgeIcon: {
              url: 'https://digitalpaani-floc-detector-images.s3.ap-south-1.amazonaws.com/icons/floatingSludge.svg',
              imageId: '6631049c4398b43e0f2ef608',
            },
          },
          '60 minutes': {
            'Detected Time': '07:30 AM',
            value: '73 ml',
            thumbnail: {
              url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
              imageId: '6631049c4398b43e0f2ef608',
            },
            floatingSludgeIcon: {
              url: 'https://digitalpaani-floc-detector-images.s3.ap-south-1.amazonaws.com/icons/floatingSludge.svg',
              imageId: '6631049c4398b43e0f2ef608',
            },
          },
          '90 minutes': {
            'Detected Time': '08:00 AM',
            value: '59.5 ml',
            thumbnail: {
              url: 'https://digitalpaani-floc-detector-images.s3.amazonaws.com/flocDetectorImage/thumbnail',
              imageId: '66ffd8a830f44af5324ce5f2',
            },
            falseDetectionIcon: {
              url: null,
              imageId: '66ffd8a830f44af5324ce5f2',
            },
          },
        },
        test21: {
          'Total Sample': 2,
          'Default Sample': 1,
        },
        test34: {
          'Total Sample': 2,
          'Default Sample': 1,
        },
      },
      '23 Apr 2024': {
        test43: {
          'Total Sample': 2,
          'Default Sample': 0,
        },
      },
    },
  ],
};

export default flocVisualizationData;
