//@ts-nocheck
import LayoutComponent from "../components/LayoutComponent";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Paper, TextField, Checkbox } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import Autocomplete from "@mui/lab/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getEquipments } from "../services/fixedTaskConfig";
import {
  fetchOneTimeTaskService,
  fetchRolesService,
  updateOneTimeTasks,
} from "../services/oneTimeTask";
import { oneTimeTasks } from "../Interfaces/oneTimeTask";
import { Equipment } from "../Interfaces/fixedTask";
import BottomNavigationComponent from "../components/BottomNavigationComponent/BottomNavigationComponent";
import { FONT_NAME } from "../core-components/theme/theme";

export default function OneTimeTask() {
  const { id } = useParams<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [oneTimeTasks, setOneTimeTasks] = useState<oneTimeTasks[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [assignRole, setAssignRole] = useState<string[]>([]);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles();
    fetchOneTimeTask();
    getEquipments(id!).then((data) => {
      console.log("equipments:", data);
      setEquipments(data);
    });
  }, []);

  const fetchOneTimeTask = () => {
    console.log("fetching Tasks");
    fetchOneTimeTaskService(id).then((data) => {
      console.log("one time tasks:", data);
      setOneTimeTasks(data);
      setLoading(false);
    });
  };

  const fetchRoles = () => {
    fetchRolesService(id).then((data) => {
      console.log("fetchRolesService", data);
      setAssignRole(data);
    });
  };

  const addNewTask = () => {
    console.log("adding New Task");
    let newTask = {
      toUser: "Operator",
      dueTimeFromPosting: "02:00:00",
      checked: false,
      ticket: false,
      createdAt: moment().format("DD:MM:yyyy HH:mm:ss"),
      status: "Pending",
      heading: {
        eng: "Enter Heading",
        hindi: "शीर्षक दर्ज करें",
      },
      description: {
        eng: "Enter Description",
        hind: "विवरण दर्ज करें",
      },
      subTasks: [
        {
          name: "Enter Subtask",
          namehindi: "सबटास्क दर्ज करें",
        },
      ],
    };

    let oldTasks = oneTimeTasks;
    oldTasks.push(newTask);
    setRefresh(!refresh);
    setOneTimeTasks(oldTasks);
  };

  const addnewSubtask = (indexofTask: number) => {
    let oldTasks = oneTimeTasks;
    oldTasks[indexofTask]["subTasks"].push({
      name: "Enter Subtask",
      namehindi: "सबटास्क दर्ज करें",
    });
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleChecked = (indexofTask: number, value: boolean) => {
    console.log(value);
    let oldTasks = oneTimeTasks;
    oldTasks[indexofTask]["checked"] = value;
    console.log(oldTasks);
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  const delteSubTask = (indexofTask: number, indexofSubTask: number) => {
    let oldTasks = oneTimeTasks;
    oldTasks[indexofTask]["subTasks"].splice(indexofSubTask, 1);
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  const UpdateTask = (
    index: number,
    section: string,
    valuefor: string,
    value: string
  ) => {
    let oldTasks = oneTimeTasks;
    oldTasks[index][section][valuefor] = value;
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleSubmit = () => {
    updateOneTimeTasks(id, oneTimeTasks).then(() => {
      navigate(`/plants`);
    });
  };

  const UpdateSubTask = (
    taskindex: number,
    subTaskIndex: number,
    key: string,
    value: string
  ) => {
    let oldTasks = oneTimeTasks;
    oldTasks[taskindex]["subTasks"][subTaskIndex][key] = value;
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  const updateDirectValue = (taskindex: number, key: string, value: any) => {
    let oldTasks = oneTimeTasks;
    oldTasks[taskindex][key] = value;
    console.log("oldTasks[taskindex][key]  : ", oldTasks[taskindex][key]);
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleCheckedCritical = (indexofTask: number, value: boolean) => {
    console.log(value);
    let oldTasks = oneTimeTasks;
    oldTasks[indexofTask]["critical"] = value;
    console.log(oldTasks);
    setOneTimeTasks(oldTasks);
    setRefresh(!refresh);
  };

  return (
    <LayoutComponent plantId={id}>
      <Box m={1} p={1}>
        <div
          style={{
            fontFamily: `${FONT_NAME}`,
            fontSize: 30,
            alignItems: "center",
            verticalAlign: "middle",
          }}
        >
          One Time Tasks Configuration
          <span style={{ float: "right" }}>
            <Button onClick={addNewTask} style={{ background: "#519BD0" }}>
              <AddIcon />
              Add New One Time Task
            </Button>
          </span>
        </div>
      </Box>

      {loading ? null : (
        <>
          {oneTimeTasks.map((task, indexTask) => {
            return (
              <Box m={1}>
                <Paper elevation={10}>
                  <Box m={1} p={1} style={{ display: "flex" }}>
                    <div style={{ font: `${FONT_NAME}`, fontSize: 26 }}>
                      <Checkbox
                        checked={task.checked}
                        onChange={(e) => {
                          handleChecked(indexTask, e.target.checked);
                        }}
                      />
                      Task
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <Checkbox
                        checked={task.critical != null ? task.critical : false}
                        onChange={(e) => {
                          handleCheckedCritical(indexTask, e.target.checked);
                        }}
                      />
                      critical
                    </div>
                  </Box>
                  <Box m={1} p={1}>
                    <Checkbox
                      checked={task.ticket}
                      onChange={(e) => {
                        updateDirectValue(
                          indexTask,
                          "ticket",
                          e.target.checked
                        );
                      }}
                    />
                    Visible in Tickets
                    <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                      <div>Heading</div>
                      <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                        <TextField
                          fullWidth
                          required
                          value={task.heading.eng}
                          label="Heading English"
                          onChange={(e) => {
                            UpdateTask(
                              indexTask,
                              "heading",
                              "eng",
                              e.target.value
                            );
                          }}
                        />
                      </div>

                      <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                        <TextField
                          fullWidth
                          required
                          value={task.heading.hindi}
                          label="Heading Hindi"
                          onChange={(e) => {
                            UpdateTask(
                              indexTask,
                              "heading",
                              "hindi",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box m={1} p={1}>
                    <>
                      {task.equipments
                        ? console.log(
                            "task.equipments",
                            task.equipments,
                            "equipments",
                            equipments
                          )
                        : console.log("none")}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Equipment
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={task.equipments}
                          label="Equipment"
                          renderValue={(value) =>
                            value.nickName != "" ? value.nickName : value.id
                          }
                          onChange={(e) =>
                            updateDirectValue(
                              indexTask,
                              "equipments",
                              e.target.value
                            )
                          }
                        >
                          {equipments.map((equipment) => {
                            return (
                              <MenuItem value={equipment}>
                                {equipment.nickName != ""
                                  ? equipment.nickName
                                  : equipment.id}
                              </MenuItem>
                            );
                            // return ( <MenuItem value={equipment}>{equipment.id}</MenuItem>)
                          })}
                        </Select>
                      </FormControl>
                    </>
                  </Box>
                  <Box m={1} p={1}>
                    <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                      <div>Description</div>
                      <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                        <TextField
                          fullWidth
                          required
                          value={task.description.eng}
                          label="Description English"
                          onChange={(e) => {
                            UpdateTask(
                              indexTask,
                              "description",
                              "eng",
                              e.target.value
                            );
                          }}
                        />
                      </div>

                      <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                        <TextField
                          fullWidth
                          required
                          value={task.description.hind}
                          label="Description Hindi"
                          onChange={(e) => {
                            UpdateTask(
                              indexTask,
                              "description",
                              "hind",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box m={1} p={1}>
                    <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                      SubTasks
                      <span style={{ float: "right" }}>
                        <Button
                          onClick={() => addnewSubtask(indexTask)}
                          style={{ background: "#519BD0" }}
                        >
                          <AddIcon />
                          Add New SubTask
                        </Button>
                      </span>
                    </div>
                  </Box>
                  {task["subTasks"].map((subTask, subTaskIndex) => {
                    return (
                      <Box m={1} p={1}>
                        <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                          SubTask {subTaskIndex + 1}
                          <span style={{ float: "right" }}>
                            <Button
                              onClick={() =>
                                delteSubTask(indexTask, subTaskIndex)
                              }
                              style={{ background: "#519BD0" }}
                            >
                              Delete SubTask
                            </Button>
                          </span>
                        </div>
                        <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                          <TextField
                            fullWidth
                            required
                            value={subTask.name}
                            label="Description English"
                            onChange={(e) => {
                              UpdateSubTask(
                                indexTask,
                                subTaskIndex,
                                "name",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                          <TextField
                            fullWidth
                            required
                            value={subTask.namehindi}
                            label="Description Hindi"
                            onChange={(e) => {
                              UpdateSubTask(
                                indexTask,
                                subTaskIndex,
                                "namehindi",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </Box>
                    );
                  })}
                  <Box m={1} p={1}>
                    <Box m={1} p={1}>
                      <TextField
                        fullWidth
                        required
                        value={task["dueTimeFromPosting"]}
                        label="OverDue Time"
                        onChange={(e) => {
                          updateDirectValue(
                            indexTask,
                            "dueTimeFromPosting",
                            e.target.value
                          );
                        }}
                      />
                    </Box>

                    <Box m={1} p={1}>
                      <TextField
                        fullWidth
                        required
                        value={task["createdAt"]}
                        label="Created At"
                        onChange={(e) => {
                          updateDirectValue(
                            indexTask,
                            "createdAt",
                            e.target.value
                          );
                        }}
                      />
                    </Box>
                    <Box m={1} p={1}>
                      <Autocomplete
                        id="tags-standard"
                        options={["Pending", "Completed", "Overdue"]}
                        value={task["status"]}
                        onChange={(event, newValue) => {
                          updateDirectValue(indexTask, "status", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="Status"
                          />
                        )}
                      />
                    </Box>
                    <Box m={1} p={1}>
                      <Autocomplete
                        id="tags-standard"
                        options={assignRole}
                        value={task["toUser"]}
                        onChange={(event, newValue) => {
                          updateDirectValue(indexTask, "toUser", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="For Role"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            );
          })}

          <Box m={1} p={1}>
            <Button onClick={handleSubmit} style={{ background: "#519BD0" }}>
              Next
            </Button>
          </Box>
          <Box
            m={4}
            p={3}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BottomNavigationComponent
              plantId={id!}
              currentPage="One Time Task"
            />
          </Box>
        </>
      )}
    </LayoutComponent>
  );
}
