import React, { useEffect, useState } from 'react';
import LayoutComponent from '../../../components/LayoutComponent';
import ModuleIcon from '../../../core-components/assets/icons/ModuleIcon';
import FeatureIcon from '../../../core-components/assets/icons/FeatureIcon';
import SubfeatureIcon from '../../../core-components/assets/icons/SubfeatureIcon';
import PermissionIcon from '../../../core-components/assets/icons/PermissionIcon';
import { filterItemsByCriteria } from './utility/helper';
import { getAllPermissionList } from '../../../services/permissions';
import { useQuery } from '@tanstack/react-query';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import PermissionListSearch from './component/PermissionSearch';
import PermissionListTabs from './component/PermissionListTree';
import { PermissionItem } from './interfaces/ItemInterface';

const PermissionList = () => {
  const { data, status } = useQuery({
    queryKey: ['permissionList'],
    queryFn: getAllPermissionList,
    staleTime: Infinity,
  });

  const [filterQuery, setFilterQuery] = useState('');
  const { assetPermissionsTreeData, administrativePermissionsTreeData } = data || {
    assetPermissionsTreeData: [],
    administrativePermissionsTreeData: [],
  };
  console.log('assetPermissionsTreeData', assetPermissionsTreeData);
  const [filterAssets, setFilterAssets] = useState<PermissionItem[]>([]);
  const [filterAdministrative, setFilterAdministrative] = useState<PermissionItem[]>([]);
  const [filterType, setFilterType] = useState<String[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (status === 'success') {
      setFilterAssets(assetPermissionsTreeData);
      setFilterAdministrative(administrativePermissionsTreeData);
    }
  }, [status, data]);
  const menuItems = [
    {
      type: 'Module',
      icon: <ModuleIcon />,
    },
    {
      type: 'Features',
      icon: <FeatureIcon />,
    },
    {
      type: 'Permission',
      icon: <PermissionIcon />,
    },
  ];
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };
  const handleSearch = (query: string) => {
    setFilterQuery(query);
    const filteredAssets = filterItemsByCriteria(assetPermissionsTreeData, query, filterType);
    setFilterAssets(filteredAssets);
    const filteredAdministrative = filterItemsByCriteria(
      administrativePermissionsTreeData,
      query,
      filterType
    );
    setFilterAdministrative(filteredAdministrative);
  };

  const handleClear = () => {
    setFilterQuery('');
    setFilterAssets(assetPermissionsTreeData);
    setFilterAdministrative(administrativePermissionsTreeData);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    handleSearch(filterQuery);
  }, [filterType]);
  useEffect(() => {
    handleFilter();
  }, [selectedIndex]);
  const handleFilter = () => {
    const newFilterTypes: string[] = selectedIndex
      .map((index: number) => {
        const item = menuItems[index].type;
        switch (item) {
          case 'Module':
            return 'module';
          case 'Features':
            return 'feature';
          case 'Permission':
            return 'permission';
          default:
            return '';
        }
      })
      .filter(type => type !== '');

    setFilterType(newFilterTypes);
  };
  const handleMenuItemClick = (index: number) => {
    setSelectedIndex((prevSelectedIndexes: number[]) => {
      if (prevSelectedIndexes.includes(index)) {
        return prevSelectedIndexes.filter(idx => idx !== index);
      } else {
        return [...prevSelectedIndexes, index];
      }
    });
  };

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{
          pl: 4,
          pr: 4,
        }}
        gap={2}
        child1={
          <PermissionListSearch
            onSearch={handleSearch}
            onClear={handleClear}
            handleClick={handleClick}
            anchorEl={anchorEl}
            menuItems={menuItems}
            handleClose={handleClose}
            selectedIndex={selectedIndex}
            handleMenuItemClick={handleMenuItemClick}
          />
        }
        child2={
          <PermissionListTabs
            value={value}
            handleChange={handleChange}
            filterQuery={filterQuery}
            filterItems={[filterAssets, filterAdministrative]}
          />
        }
      />
    </LayoutComponent>
  );
};
export default PermissionList;
