import { useState } from "react";
import axiosInstance from "../../constants/axiosInstance";
import { Box, Button, Paper, TextField, Checkbox } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/lab/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/FiberManualRecord";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalBox from "./ModalBox";
import { FONT_NAME } from "../../core-components/theme/theme";

function TaskCardView(props: any) {
  let {
    task,
    indexTask,
    alertTypes,
    equipments,
    issueTypes,
    userList,
    assignRole,
    escaltionOptions,
    handleChecked,
    handleCheckedCritical,
    updateDirectValue,
    UpdateTask,
    updatePrimaryUser,
    updateCCUserArrayVals,
    UpdateEscaltionLevel,
    addnewSubtask,
    delteSubTask,
    UpdateSubTask,
    refreshStateOfParent,
    setRefreshStateOfParent,
  } = props;

  const [openModalBox, setOpenModalBox] = useState(false);
  const [modalBoxPurpose, setModalBoxPurpose] = useState("resolveConfirmation");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const markIssueResolved = () => {
    let alert = task;
    axiosInstance()
      .post(`/alerts/markTicketResolved`, {
        alertId: alert._id.toString(),
        closedBy: "user",
      })
      .then(({ data }) => {
        toast(data.msg);
        setRefreshStateOfParent(!refreshStateOfParent);
      });
  };

  const deleteAlert = () => {
    let alert = task;
    axiosInstance()
      .get(`/alerts/deleteAlert?alertId=${alert._id.toString()}`)
      .then(({ data }) => {
        toast(data.msg);
        setRefreshStateOfParent(!refreshStateOfParent);
      });
  };

  const reOpenIssue = () => {
    console.log("inside reOpen issue");
    let alert = task;
    axiosInstance()
      .post(`/alerts/openTheAlert?alertId=${alert._id.toString()}`, {})
      .then(({ data }) => {
        toast(data.msg);
        setRefreshStateOfParent(!refreshStateOfParent);
      });
  };

  return (
    <Box>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        style={{ marginTop: "30px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
              {task.isOpen === true ? (
                <EditIcon
                  style={{
                    color: "#65a765",
                    paddingTop: "4px",
                    paddingLeft: "2px",
                    paddingRight: "10px",
                    width: "20px",
                  }}
                ></EditIcon>
              ) : (
                <EditIcon
                  style={{
                    color: "#FF7F7F",
                    paddingTop: "4px",
                    paddingLeft: "2px",
                    paddingRight: "10px",
                    width: "20px",
                  }}
                ></EditIcon>
              )}
              <Checkbox
                checked={task.checked}
                onChange={(e) => {
                  handleChecked(indexTask, e.target.checked);
                }}
              />
              {task.heading.eng}
            </div>
            <Box style={{ marginLeft: "auto", display: "flex", gap: "10px" }}>
              <Button
                style={{
                  background: "black",
                  color: "#ddd",
                  fontSize: "12px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalBoxPurpose("reOpenConfirmation");
                  setOpenModalBox(true);
                }}
              >
                Reopen
              </Button>
              <Button
                style={{
                  background: "black",
                  color: "#ddd",
                  fontSize: "12px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalBoxPurpose("resolveConfirmation");
                  setOpenModalBox(true);
                }}
              >
                Mark Resolved
              </Button>

              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setModalBoxPurpose("deleteAlertConfirmation");
                  setOpenModalBox(true);
                }}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box m={1} style={{ width: "100%" }}>
            <Paper>
              <div>
                <Checkbox
                  checked={task.critical != null ? task.critical : false}
                  onChange={(e) => {
                    handleCheckedCritical(indexTask, e.target.checked);
                  }}
                />
                critical
              </div>
              <Box m={1} p={1}>
                <div>
                  <Autocomplete
                    id="tags-standard"
                    options={alertTypes}
                    value={task["type"]}
                    onChange={(event, newValue) => {
                      updateDirectValue(indexTask, "type", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Alert Type"
                      />
                    )}
                  />
                </div>
              </Box>
              <Box m={1} p={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Equipment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={task.equipments}
                    label="Equipment"
                    renderValue={(value) =>
                      value.nickName !== "" ? value.nickName : value.id
                    }
                    onChange={(e) =>
                      updateDirectValue(indexTask, "equipments", e.target.value)
                    }
                  >
                    {equipments.map((equipment: any) => {
                      return (
                        <MenuItem value={equipment}>
                          {equipment.nickName !== ""
                            ? equipment.nickName
                            : equipment.id}
                        </MenuItem>
                      );
                      // return ( <MenuItem value={equipment}>{equipment.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box m={1} p={1}>
                <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                  <div>Problem Statement</div>
                  <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                    <TextField
                      fullWidth
                      required
                      value={task.heading.eng}
                      label="Problem Statement in English"
                      onChange={(e) => {
                        UpdateTask(indexTask, "heading", "eng", e.target.value);
                      }}
                    />
                  </div>

                  <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                    <TextField
                      fullWidth
                      required
                      value={task.heading.hindi}
                      label="Problem Statement in Hindi"
                      onChange={(e) => {
                        UpdateTask(
                          indexTask,
                          "heading",
                          "hindi",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </Box>

              <Box m={1} p={1}>
                <div style={{ font: `${FONT_NAME}`, fontSize: 20 }}>
                  <div>Problem Description</div>
                  <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                    <TextField
                      fullWidth
                      required
                      value={task.description.eng}
                      label="Description English"
                      onChange={(e) => {
                        UpdateTask(
                          indexTask,
                          "description",
                          "eng",
                          e.target.value
                        );
                      }}
                    />
                  </div>

                  <div style={{ font: `${FONT_NAME}`, fontSize: 16 }}>
                    <TextField
                      fullWidth
                      required
                      value={task.description.hindi}
                      label="Problem Descriptiion in Hindi"
                      onChange={(e) => {
                        UpdateTask(
                          indexTask,
                          "description",
                          "hindi",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </Box>
              <Box m={1} p={1}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={issueTypes}
                  value={task["issueType"]}
                  onChange={(event, newValue) => {
                    updateDirectValue(indexTask, "issueType", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      label="Issue Type"
                    />
                  )}
                />
              </Box>

              <Box m={1} p={1}>
                <>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        style={{
                          font: `${FONT_NAME}`,
                          fontSize: 20,
                        }}
                      >
                        Trigger and Resolvation Logic
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box m={1} style={{ width: "100%" }}>
                        <Paper>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["dueTimeFromPosting"]}
                              label="OverDue Time"
                              onChange={(e) => {
                                updateDirectValue(
                                  indexTask,
                                  "dueTimeFromPosting",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>

                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["group"]}
                              label="Alert Group"
                              onChange={(e) => {
                                updateDirectValue(
                                  indexTask,
                                  "group",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["priority"]}
                              label="Priority"
                              type="number"
                              onChange={(e) => {
                                updateDirectValue(
                                  indexTask,
                                  "priority",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>
                        </Paper>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </>

                <>
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    style={{ marginTop: "20px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        style={{
                          font: `${FONT_NAME}`,
                          fontSize: 20,
                        }}
                      >
                        Receiver Config
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box m={1} style={{ width: "100%" }}>
                        <Paper>
                          <Box m={1} p={1}>
                            <div
                              style={{
                                font: `${FONT_NAME}`,
                              }}
                            >
                              <Checkbox
                                checked={task["autoCalling"]}
                                onChange={(e) => {
                                  updateDirectValue(
                                    indexTask,
                                    "autoCalling",
                                    e.target.checked
                                  );
                                }}
                              />
                              Auto Calling
                            </div>
                            <InputLabel
                              id={"primaryUser" + indexTask}
                              style={{ marginTop: "15px" }}
                            >
                              Primary User
                            </InputLabel>
                            <Select
                              id={"Primary User"}
                              key={"primary user" + indexTask}
                              value={
                                task["primaryUser"] ? task["primaryUser"] : []
                              }
                              onChange={(e) =>
                                updatePrimaryUser(indexTask, e.target.value)
                              }
                              renderValue={(User) => (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {
                                    <InputLabel id={"primaryUser" + indexTask}>
                                      {User["name"]}
                                    </InputLabel>
                                  }
                                </Box>
                              )}
                              fullWidth
                            >
                              {userList.map((user: any) => {
                                return (
                                  <MenuItem value={user["_id"]}>
                                    {user["name"]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <InputLabel
                              id={"ccUsers" + indexTask}
                              style={{ marginTop: "15px" }}
                            >
                              CC Users
                            </InputLabel>

                            <Select
                              id="cc-multiple-chip"
                              multiple
                              value={task["ccUsers"] ? task["ccUsers"] : []}
                              fullWidth
                              onChange={(e) => {
                                updateCCUserArrayVals(
                                  indexTask,
                                  e.target.value
                                );
                              }}
                              input={
                                <OutlinedInput
                                  id="cc-select-multiple-chip"
                                  label="User"
                                />
                              }
                              renderValue={(ccUsers) => (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {ccUsers.map((user: any) => (
                                    <Chip
                                      key={user["_id"] ?? ""}
                                      label={user["name"] ?? ""}
                                    />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {userList?.map((user: any) => {
                                return (
                                  <MenuItem value={user["_id"]}>
                                    {user["name"]}
                                  </MenuItem>
                                );
                              })}
                              {/* {t.map((name) => (
                                   
                                  ))} */}
                            </Select>
                            {/* <Select
                                  id={"cc User"}
                                  key={"cc user" + indexTask}
                                  // onChange={(e) =>
                                  //   updateFormulaValue(formula.type, index, "tag", e.target.value)
                                  // }
                                  fullWidth
                                >
                                  {userList.map((user: any) => {
                                    return (
                                      <MenuItem value={user["_id"]}>
                                        {user["name"]}
                                      </MenuItem>
                                    );
                                  })}
                                </Select> */}
                            <Autocomplete
                              id="tags-standard"
                              options={assignRole}
                              style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                              value={task["toUser"]}
                              onChange={(event, newValue) => {
                                updateDirectValue(
                                  indexTask,
                                  "toUser",
                                  newValue
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  variant="standard"
                                  label="For Role"
                                />
                              )}
                            />
                            <Accordion
                              TransitionProps={{
                                unmountOnExit: true,
                              }}
                              style={{ marginTop: "15px" }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div
                                  style={{
                                    font: `${FONT_NAME}`,
                                    fontSize: 20,
                                  }}
                                >
                                  Escalation Levels
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box m={1} style={{ width: "100%" }}>
                                  <Paper>
                                    <div
                                      style={{
                                        font: `${FONT_NAME}`,
                                        fontSize: 20,
                                      }}
                                    >
                                      {task?.hasOwnProperty("escalations") &&
                                        Object?.keys(task["escalations"])?.map(
                                          (escalationLevel) => {
                                            const escalationData =
                                              task["escalations"][
                                                escalationLevel
                                              ];
                                            if (!escalationData) {
                                              // Handle the case where escalationData is undefined or null
                                              return null;
                                            }
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    font: `${FONT_NAME}`,
                                                    fontSize: 16,
                                                  }}
                                                >
                                                  {escalationLevel}
                                                  <Autocomplete
                                                    id="tags-standard"
                                                    options={escaltionOptions}
                                                    value={
                                                      task["escalations"][
                                                        escalationLevel
                                                      ]["group"]
                                                    }
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      UpdateEscaltionLevel(
                                                        indexTask,
                                                        escalationLevel,
                                                        "group",
                                                        newValue
                                                      );
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        required
                                                        {...params}
                                                        variant="standard"
                                                        label="Escalation Group"
                                                      />
                                                    )}
                                                  />

                                                  <TextField
                                                    fullWidth
                                                    required
                                                    value={
                                                      task["escalations"][
                                                        escalationLevel
                                                      ]["time"]
                                                    }
                                                    label="Escalation Time"
                                                    onChange={(e) => {
                                                      UpdateEscaltionLevel(
                                                        indexTask,
                                                        escalationLevel,
                                                        "time",
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                  </Paper>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </Paper>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </>

                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  style={{ marginTop: "15px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        font: `${FONT_NAME}`,
                        fontSize: 20,
                      }}
                    >
                      Workflow Configuration
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box m={1} style={{ width: "100%" }}>
                      <Paper>
                        <Box m={1} p={1}>
                          <div
                            style={{
                              font: `${FONT_NAME}`,
                              fontSize: 20,
                            }}
                          >
                            <div>Solution</div>
                            <div
                              style={{
                                font: `${FONT_NAME}`,
                                fontSize: 16,
                              }}
                            >
                              <TextField
                                fullWidth
                                required
                                value={task.solution?.eng}
                                label="Solution in English"
                                onChange={(e) => {
                                  UpdateTask(
                                    indexTask,
                                    "solution",
                                    "eng",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>

                            <div
                              style={{
                                font: `${FONT_NAME}`,
                                fontSize: 16,
                              }}
                            >
                              <TextField
                                fullWidth
                                required
                                value={task.solution?.hindi}
                                label="Solution in Hindi"
                                onChange={(e) => {
                                  UpdateTask(
                                    indexTask,
                                    "solution",
                                    "hindi",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Box>

                        <Box m={1} p={1}>
                          <div
                            style={{
                              font: `${FONT_NAME}`,
                              fontSize: 20,
                            }}
                          >
                            <div>Solution Description</div>
                            <div
                              style={{
                                font: `${FONT_NAME}`,
                                fontSize: 16,
                              }}
                            >
                              <TextField
                                fullWidth
                                required
                                value={task.solutionDescription?.eng}
                                label="Solution Description in English"
                                onChange={(e) => {
                                  UpdateTask(
                                    indexTask,
                                    "solutionDescription",
                                    "eng",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>

                            <div
                              style={{
                                font: `${FONT_NAME}`,
                                fontSize: 16,
                              }}
                            >
                              <TextField
                                fullWidth
                                required
                                value={task.solutionDescription?.hindi}
                                label="Solution Description in Hindi"
                                onChange={(e) => {
                                  UpdateTask(
                                    indexTask,
                                    "solutionDescription",
                                    "hindi",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Box>

                        <Box m={1} p={1}>
                          <div
                            style={{
                              font: `${FONT_NAME}`,
                              fontSize: 20,
                            }}
                          >
                            SubTasks
                            <span style={{ float: "right" }}>
                              <Button
                                onClick={() => addnewSubtask(indexTask)}
                                style={{ background: "#519BD0" }}
                              >
                                <AddIcon />
                                Add New SubTask
                              </Button>
                            </span>
                          </div>
                        </Box>
                        {task["subTasks"].map(
                          (subTask: any, subTaskIndex: any) => {
                            return (
                              <Box m={1} p={1}>
                                <div
                                  style={{
                                    font: `${FONT_NAME}`,
                                    fontSize: 16,
                                  }}
                                >
                                  SubTask {subTaskIndex + 1}
                                  <span style={{ float: "right" }}>
                                    <Button
                                      onClick={() =>
                                        delteSubTask(indexTask, subTaskIndex)
                                      }
                                      style={{
                                        background: "#519BD0",
                                      }}
                                    >
                                      Delete SubTask
                                    </Button>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    font: `${FONT_NAME}`,
                                    fontSize: 16,
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    required
                                    value={subTask.name}
                                    label="Description English"
                                    onChange={(e) => {
                                      UpdateSubTask(
                                        indexTask,
                                        subTaskIndex,
                                        "name",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    font: `${FONT_NAME}`,
                                    fontSize: 16,
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    required
                                    value={subTask.namehindi}
                                    label="Description Hindi"
                                    onChange={(e) => {
                                      UpdateSubTask(
                                        indexTask,
                                        subTaskIndex,
                                        "namehindi",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </Box>
                            );
                          }
                        )}
                        <Box m={1} p={1}>
                          <TextField
                            fullWidth
                            required
                            value={task["videoUrl"]}
                            label="Video Url"
                            onChange={(e) => {
                              updateDirectValue(
                                indexTask,
                                "videoUrl",
                                e.target.value
                              );
                            }}
                          />
                        </Box>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Paper>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ModalBox
        open={openModalBox}
        handleClose={() => {
          setOpenModalBox(false);
        }}
        modalBoxPurpose={modalBoxPurpose}
        markIssueResolved={markIssueResolved}
        reOpenIssue={reOpenIssue}
        deleteAlert={deleteAlert}
      />
    </Box>
  );
}

export default TaskCardView;
