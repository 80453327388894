import React from 'react';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Checkbox as MuiCheckbox,
  ListItemText as MuiListItemText,
  Typography as MuiTypography,
} from '@mui/material';
import { Neutrals } from '../../color-palette/colors';

interface MenuItem {
  type?: string;
  icon?: React.ReactNode;
}

interface FilterMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  menuItems: MenuItem[];
  selectedIndex: number[];
  handleMenuItemClick: (index: number) => void;
}

const Menu: React.FC<FilterMenuProps> = ({
  anchorEl,
  open,
  onClose,
  menuItems,
  selectedIndex,
  handleMenuItemClick,
}) => {
  return (
    <MuiMenu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          style: {
            boxShadow: '0px 2px 8px 0px #1A335626',
          },
        },
      }}
      MenuListProps={{
        sx: {
          border: `1px solid ${Neutrals[300]}`,
          py: 0,
          backgroundColor: '#ffffff',
          borderRadius: '4px',
        },
      }}
    >
      {menuItems.map((item, index) => (
        <MuiMenuItem
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            padding: '8px 24px 8px 12px',
            borderBottom: `1px solid ${Neutrals[100]}`,
            '&:last-child': {
              borderBottom: 'none',
            },
          }}
          key={index}
          onClick={() => handleMenuItemClick(index)}
        >
          <MuiCheckbox checked={selectedIndex.includes(index)} sx={{ padding: '9px' }} />
          {item.icon ? item.icon : null}
          <MuiListItemText>
            <MuiTypography color={Neutrals[600]} variant="h4">
              {item.type}
            </MuiTypography>
          </MuiListItemText>
        </MuiMenuItem>
      ))}
    </MuiMenu>
  );
};

export default Menu;
