//@ts-nocheck
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { PrimaryButton } from "../../styles/global/components/dpButtons";
import { FONT_NAME } from "../../core-components/theme/theme";

const styles = {
  accordion: {
    marginTop: "15px",
  },
  box: { width: "100%" },
  sectionTitle: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 20,
  },
  subSectionTitle: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 16,
  },
  subSectionTitleWithButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    fontFamily: `${FONT_NAME}`,
    fontSize: 16,
  },
  addButton: {
    background: "#519BD0",
  },
  deleteButton: {
    background: "#519BD0",
  },
};

const WorkflowConfiguration = ({
  addNewSubTask,
  deleteSubTask,
  updateSubTask,
  updateDirectValue,
  section,
  indexTask,
  task,
}) => (
  <Accordion TransitionProps={{ unmountOnExit: true }} style={styles.accordion}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <div style={styles.sectionTitle}>Workflow Configuration</div>
    </AccordionSummary>
    <AccordionDetails>
      <Box m={1} style={styles.box}>
        <Paper>
          <Box m={1} p={1}>
            <div style={styles.subSectionTitle}>
              <div>Solution</div>
              {["eng", "hindi"].map((lang) => (
                <div key={lang} style={styles.label}>
                  <TextField fullWidth required label={`Solution in ${lang}`} />
                </div>
              ))}
            </div>
          </Box>

          <Box m={1} p={1}>
            <div style={styles.subSectionTitle}>
              <div>Solution Description</div>
              {["eng", "hindi"].map((lang) => (
                <div key={lang} style={styles.label}>
                  <TextField
                    fullWidth
                    required
                    label={`Solution Description in ${lang}`}
                  />
                </div>
              ))}
            </div>
          </Box>

          <Box m={1} p={1}>
            <div style={styles.sectionTitle}>
              SubTasks
              <span style={{ float: "right" }}>
                <PrimaryButton
                  onClick={() => addNewSubTask(section, "", indexTask)}
                  style={styles.addButton}
                >
                  <AddIcon />
                  Add New SubTask
                </PrimaryButton>
              </span>
            </div>
          </Box>

          {task["subTasks"].map((subTask: any, subTaskIndex: number) => (
            <Box key={subTaskIndex} m={1} p={1}>
              <div style={styles.subSectionTitle}>
                SubTask {subTaskIndex + 1}
                <span style={{ float: "right" }}>
                  <PrimaryButton
                    onClick={() =>
                      deleteSubTask(section, "", indexTask, subTaskIndex)
                    }
                    style={styles.deleteButton}
                  >
                    Delete SubTask
                  </PrimaryButton>
                </span>
              </div>
              {["eng", "hindi"].map((lang) => (
                <div key={lang} style={styles.label}>
                  <TextField
                    fullWidth
                    required
                    value={
                      lang == "hindi" ? subTask[`name${lang}`] : subTask[`name`]
                    }
                    label={`Description ${lang}`}
                    onChange={(e) => {
                      updateSubTask(
                        section,
                        "",
                        indexTask,
                        subTaskIndex,
                        `${lang == "hindi" ? `name${lang}` : `name`}`,
                        e.target.value
                      );
                    }}
                  />
                </div>
              ))}
            </Box>
          ))}

          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={task["videoUrl"]}
              label="Video Url"
              onChange={(e) => {
                updateDirectValue(
                  section,
                  "",
                  indexTask,
                  "videoUrl",
                  e.target.value
                );
              }}
            />
          </Box>
        </Paper>
      </Box>
    </AccordionDetails>
  </Accordion>
);

export default WorkflowConfiguration;
