import { makeStyles } from '@mui/styles';
import { FONT_NAME } from '../core-components/theme/theme';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: `${FONT_NAME}`,
  },
  taskHeader: {
    display: 'flex',
    letterSpacing: '1.2px',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `${FONT_NAME}`,
  },
  paper: {
    height: 50,
    width: 300,
    fontWeight: '400',
    '&:hover': {
      boxShadow:
        '0 8px 10px 1px rgba(0, 0, 0, 0.14),                   0 3px 14px 2px rgba(0, 0, 0, 0.12),                   0 5px 5px -3px rgba(0, 0, 0, 0.4)',
    },
  },
  taskCard: {
    '&:hover': {
      boxShadow:
        '0 8px 10px 1px rgba(0, 0, 0, 0.14),                   0 3px 14px 2px rgba(0, 0, 0, 0.12),                   0 5px 5px -3px rgba(0, 0, 0, 0.4)',
    },
  },
  overdueTasks: {
    backgroundColor: '#EFDADA !important',
  },
  pendingTasks: {
    backgroundColor: '#F4ECDD !important',
  },
  completedTasks: {
    backgroundColor: '#DDEFE0 !important',
  },
  control: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: 18,
    fontFamily: `${FONT_NAME}`,
    marginTop: '5px',
    fontWeight: 'bold',
  },
  dueDate: {
    fontSize: 12,
    color: '#808080',
    fontFamily: `${FONT_NAME}`,
  },
  dueDateLabel: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

export default useStyles;
