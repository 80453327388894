import {
  ThemeProvider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import {
  otherSensorNickNameGenerator,
  SensorTagGenerator,
} from '../../../utils/SensorTagGenerator';

import Tank from './SubTank';
import CoreEquipment from './SubCoreEquipment';
import PumpConfig from './SubPump';
import FlowMeterConfig from './SubFlowmeter';
import ValveConfig from './SubValve';
import HeaderConfig from './SubHeader';
import BlowerConfig from './SubBlower';
import OtherSensorsConfig from './SubOtherSensorsConfig';
import dpTheme from '../../../styles/theme/dpTheme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { toast, ToastContainer } from 'react-toastify';
import { FONT_NAME } from '../../../core-components/theme/theme';

interface IProps {
  parentEquipmentType: string;
  parentEquipmentIndex: number;
  subEquipments: any;
  plantConfig: any;
  setplantConfig: Function;
  tankChoice: any;
  valveChoice: any;
  equipChoice: any;
  headerChoice: any;
  sensorPostFix: string;
}
const PlantConfigurationStyle = {
  PageTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `${FONT_NAME}`,
    fontSize: '2.5rem',
    margin: '2rem 0',
  },
  AccordianSummary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.6rem',
  },
};

export default function SubEquipmentViewAndControl({
  parentEquipmentType,
  parentEquipmentIndex,
  subEquipments,
  plantConfig,
  setplantConfig,
  tankChoice,
  valveChoice,
  equipChoice,
  headerChoice,
  sensorPostFix,
}: IProps) {
  // for add equipment popup

  // input and output config of equipment
  const handleMultiSelect = (category: string, index: number, type: string, value: any) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ][type] = value;
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData: { sensorTag: string; sensorNickName: string } = SensorTagGenerator(
        category,
        tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
          index
        ],
        sensorIndex,
        sensor['sensorName'],
        sensorPostFix
      );
      sensor['dateCreated'] = new Date();
      sensor['sensorTag'] = generatedSensorData['sensorTag'];
      let followUpVal = sensor['sensorTag'].split('_').slice(1).join('_');
      if (sensor['sensorName'] === 'ON/OFF Status') {
        tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
          index
        ]['hmiButtons'] = [
          {
            hmiTag: `BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_MCONOFF_${followUpVal}`,
          },
          {
            hmiTag: `HMI_BYP_${followUpVal}`,
          },
          {
            hmiTag: `PLC_BYP_${followUpVal}`,
          },
        ];
      }
    });
    setplantConfig(tempPlantConfigData);
  };

  interface INicknameChange {
    category: string;
    index: number;
    value: any;
  }

  const handleNickNameChange = ({ category, index, value }: INicknameChange) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['nickName'] = value;
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData: { sensorTag: string; sensorNickName: string } = SensorTagGenerator(
        category,
        tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
          index
        ],
        sensorIndex,
        sensor['sensorName'],
        sensorPostFix
      );
      sensor['sensorNickName'] = generatedSensorData['sensorNickName'];
    });
    setplantConfig(tempPlantConfigData);
  };

  const updateTagName = (category: string, index: number, value: string) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['tagName'] = value;
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData: {
        sensorTag: string;
        sensorNickName: string;
      } = SensorTagGenerator(
        category,
        tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
          index
        ],
        sensorIndex,
        sensor['sensorName'],
        sensorPostFix
      );
      sensor['sensorTag'] = generatedSensorData['sensorTag'];
      let followUpVal = sensor['sensorTag'].split('_').slice(1).join('_');
      if (sensor['sensorName'] === 'ON/OFF Status') {
        tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
          index
        ]['hmiButtons'] = [
          {
            hmiTag: `BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_MCONOFF_${followUpVal}`,
          },
          {
            hmiTag: `HMI_BYP_${followUpVal}`,
          },
          {
            hmiTag: `PLC_BYP_${followUpVal}`,
          },
        ];
      }
    });
    setplantConfig(tempPlantConfigData);
  };

  function updateOtherSensorNickname(index: any, value: string): void {
    let tempPlantConfigData = { ...plantConfig };
    let equipmentConfig =
      tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][
        'Other Sensors'
      ][index];
    equipmentConfig['nickName'] = value;
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][
      'Other Sensors'
    ][index]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][
        'Other Sensors'
      ][index]['sensors'][sensorIndex]['sensorNickName'] = otherSensorNickNameGenerator(
        equipmentConfig,
        sensorIndex
      );
    });
    setplantConfig(tempPlantConfigData);
  }

  function updateOtherSensorTag(
    index: any,
    value: string,
    type: string,
    sensorIndex: number
  ): void {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][
      'Other Sensors'
    ][index]['sensors'][sensorIndex][type] = value;
    setplantConfig(tempPlantConfigData);
  }

  const RemoveElement = (category: string, index: number) => {
    let tempPlantConfigData = { ...plantConfig };
    let categoryData =
      tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category];
    categoryData.splice(index, 1);
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category] =
      categoryData;
    setplantConfig(tempPlantConfigData);
    toast.success('Sub-equipment Removed Successfully');
  };

  interface IUpdateValue {
    category: string;
    index: number;
    valuefor: string;
    value: any;
  }

  //update numeric values
  const updateValue = ({ category, index, valuefor, value }: IUpdateValue) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ][valuefor] = value;
    setplantConfig(tempPlantConfigData);
  };

  const handleShowOnDiagramCheckForSubequipment = (category: string, index: number, value: any) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['displayNode'] = value;
    setplantConfig(tempPlantConfigData);
  };

  const handleBiDirectionalCheckForSubequipment = (category: string, index: number, value: any) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['bidDirectionalControl'] = value;
    setplantConfig(tempPlantConfigData);
  };

  const updateFlowmeterType = (category: string, index: number, valuefor: string, value: any) => {
    let tempPlantConfigData = { ...plantConfig };
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ][valuefor] = value;
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
      index
    ]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData = SensorTagGenerator(
        category,
        tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category][
          index
        ],
        sensorIndex,
        sensor['sensorName'],
        sensorPostFix
      );
      sensor['dateCreated'] = new Date();
      sensor['sensorNickName'] = generatedSensorData['sensorNickName'];
      sensor['sensorTag'] = generatedSensorData['sensorTag'];
    });
    setplantConfig(tempPlantConfigData);
  };

  function updateSensorList(category: string, index: number, sensor: string) {
    let tempPlantConfigData = { ...plantConfig };
    let categoryData =
      tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category];
    let sensorData = categoryData[index]['sensors'].find(
      (availableSensor: any) => availableSensor['sensorName'] === sensor
    );
    if (sensorData) {
      let indexOfSplice = categoryData[index]['sensors'].findIndex(
        (availableSensor: any) => availableSensor['sensorName'] === sensor
      );
      categoryData[index]['sensors'].splice(indexOfSplice, 1);
    } else {
      let indexOfSensor = categoryData[index]['sensors'].findIndex(
        (availableSensor: any) => availableSensor['sensorName'] === sensor
      );
      let generatedSensorData = SensorTagGenerator(
        category,
        categoryData[index],
        indexOfSensor,
        sensor,
        sensorPostFix
      );
      let newSensor = {
        sensorName: sensor,
        sensorTag: generatedSensorData['sensorTag'],
        sensorNickName: generatedSensorData['sensorNickName'],
        dateCreated: new Date(),
      };
      categoryData[index]['sensors'].push(newSensor);
      if (sensor === 'ON/OFF Status') {
        console.log(sensor, newSensor, 'newSensor');
        let followUpVal = newSensor.sensorTag.split('_').slice(1).join('_');
        categoryData[index]['hmiButtons'] = [
          {
            hmiTag: `BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_MCONOFF_${followUpVal}`,
          },
          {
            hmiTag: `HMI_BYP_${followUpVal}`,
          },
          {
            hmiTag: `PLC_BYP_${followUpVal}`,
          },
        ];
      }
    }
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category] =
      categoryData;
    setplantConfig(tempPlantConfigData);
  }

  //update tag name for the sensor needs to be updated for disable on timestamp
  function updateSensorTagValue(
    category: string,
    index: number,
    type: string,
    sensorIndex: number,
    value: string
  ) {
    let tempPlantConfigData = { ...plantConfig };
    let categoryData =
      tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category];
    categoryData[index]['sensors'][sensorIndex][type] = value;
    if (categoryData[index]['sensors'][sensorIndex]['sensorName'] === 'ON/OFF Status') {
      let followUpVal = value.split('_').slice(1).join('_');
      categoryData[index]['hmiButtons'] = [
        {
          hmiTag: `BYP_${followUpVal}`,
        },
        {
          hmiTag: `SWE_BYP_${followUpVal}`,
        },
        {
          hmiTag: `SWE_MCONOFF_${followUpVal}`,
        },
        {
          hmiTag: `HMI_BYP_${followUpVal}`,
        },
        {
          hmiTag: `PLC_BYP_${followUpVal}`,
        },
      ];
    }
    tempPlantConfigData[parentEquipmentType][parentEquipmentIndex]['subEquipments'][category] =
      categoryData;
    setplantConfig(tempPlantConfigData);
  }

  return (
    <ThemeProvider theme={dpTheme}>
      {
        <>
          {subEquipments['Tanks'] && subEquipments['Tanks'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={e => e.stopPropagation()}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Tanks</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Tank
                  tankData={subEquipments['Tanks']}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  updateSensorTagValue={updateSensorTagValue}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Core Equipments'] && subEquipments['Core Equipments'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Core Equipments</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <CoreEquipment
                  coreEquipmentData={subEquipments['Core Equipments']}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  updateSensorTagValue={updateSensorTagValue}
                  handleNickNameChange={handleNickNameChange}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                  handleBiDirectionalCheckForSubequipment={handleBiDirectionalCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Pumps'] && subEquipments['Pumps'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Pumps</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <PumpConfig
                  pumpData={subEquipments['Pumps']}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  updateTagName={updateTagName}
                  handleMultiSelect={handleMultiSelect}
                  tankChoice={tankChoice}
                  updateSensorTagValue={updateSensorTagValue}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                  handleBiDirectionalCheckForSubequipment={handleBiDirectionalCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Flowmeter'] && subEquipments['Flowmeter'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Flow Meters</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <FlowMeterConfig
                  flowMeterData={subEquipments['Flowmeter']}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  updateSensorTagValue={updateSensorTagValue}
                  handleNickNameChange={handleNickNameChange}
                  handleMultiSelect={handleMultiSelect}
                  equipChoice={equipChoice}
                  updateFlowmeterType={updateFlowmeterType}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Valves'] && subEquipments['Valves'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Valves</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ValveConfig
                  valveData={subEquipments['Valves']}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  updateSensorList={updateSensorList}
                  updateSensorTagValue={updateSensorTagValue}
                  handleNickNameChange={handleNickNameChange}
                  handleMultiSelect={handleMultiSelect}
                  valveChoice={valveChoice}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                  handleBiDirectionalCheckForSubequipment={handleBiDirectionalCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Headers'] && subEquipments['Headers'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Headers</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <HeaderConfig
                  headerData={subEquipments['Headers']}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  updateSensorTagValue={updateSensorTagValue}
                  handleMultiSelect={handleMultiSelect}
                  tankChoice={tankChoice}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Blowers'] && subEquipments['Blowers'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Blowers</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <BlowerConfig
                  blowerData={subEquipments['Blowers']}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  headerChoice={headerChoice}
                  handleMultiSelect={handleMultiSelect}
                  updateSensorList={updateSensorList}
                  updateSensorTagValue={updateSensorTagValue}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                  handleBiDirectionalCheckForSubequipment={handleBiDirectionalCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {subEquipments['Other Sensors'] && subEquipments['Other Sensors'].length > 0 && (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Sub - Other Sensors</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <OtherSensorsConfig
                  otherSensorData={subEquipments['Other Sensors']}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  updateOtherSensorTag={updateOtherSensorTag}
                  updateOtherSensorNickname={updateOtherSensorNickname}
                  handleShowOnDiagramCheckForSubequipment={handleShowOnDiagramCheckForSubequipment}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      }
      <ToastContainer />
    </ThemeProvider>
  );
}
