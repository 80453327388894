import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box } from '@mui/material';
import { Neutrals } from '../../../../core-components/theme/color-palette/colors';
import SearchBox from '../../../../core-components/theme/components/Searchbox/Searchbox';
import CustomIconButton from '../../../../core-components/theme/components/Button/Iconbutton';
import Button from '../../../../core-components/theme/components/Button/Button';
import Menu from '../../../../core-components/theme/components/Menu/Menu';

interface PermissionListSearchProps {
  onSearch: (query: string) => void;
  onClear: () => void;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorEl: HTMLElement | null;
  menuItems: { type: string; icon: JSX.Element }[];
  selectedIndex: number[];
  handleMenuItemClick: (index: number) => void;
  handleClose: () => void;
}

const PermissionListSearch: React.FC<PermissionListSearchProps> = ({
  onSearch,
  onClear,
  handleClick,
  anchorEl,
  menuItems,
  selectedIndex,
  handleClose,
  handleMenuItemClick,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <SearchBox
        placeholder="Search for Modules / Features / Permissions"
        sx={{
          flexGrow: 1,
          marginRight: 6,
          borderColor: `${Neutrals[500]}`,
          borderRadius: '4px',
        }}
        onClear={onClear}
        onSearch={onSearch}
      />
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        color="primary"
        width="52px"
        height="52px"
        sx={{
          padding: '16px',
          border: `1px solid ${Neutrals[500]}`,
        }}
      >
        <CustomIconButton icon={FilterListIcon} sx={{ padding: 0 }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        menuItems={menuItems}
        selectedIndex={selectedIndex}
        handleMenuItemClick={handleMenuItemClick}
      />
    </Box>
  );
};

export default PermissionListSearch;
